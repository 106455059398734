import React, { useCallback, useState } from 'react';
import { Typography, Box, Alert } from '@mui/material';
import { PodcastSearch } from '@components/index';
import { STRINGS } from 'constants/strings';
import { useAddYourPodcast } from 'contexts/AddYourPodcastContext';
import { toast } from 'react-toastify';
import { pageNames } from 'constants/ipsso';
import useLoadModalSubpage from 'hooks/useLoadModalSubpage';
import { PodcastSearchResultItem } from '@components/podcastSearchResults';
import { SendCode } from '../index';

const FeedConfirmation = ({ renderBackBtn, onNextClick, height }) => {
  const {
    podcastState: [podcast, setPodcast],
    checkRSSExists,
  } = useAddYourPodcast();

  const [searchHeight, setSearchHeight] = useState(0);

  const headerRef = useCallback(
    (ref) => {
      if (!ref) return;

      setSearchHeight(height - ref.clientHeight - 80);
    },
    [height]
  );

  const handleRSSLoad = (data, err) => {
    if (err) {
      toast.error(err);

      return;
    }

    setPodcast(data);
  };

  const renderLanguageWarning = useCallback(() => {
    if (!podcast) return null;

    const { language = 'en' } = podcast;

    return (
      !language.includes('en') && (
        <Alert variant="filled" color="main" severity="info" sx={{ mt: 3 }}>
          <i>
            {
              STRINGS.THERE_MAY_BE_A_DELAY_UNTIL_WE_SUPPORT_YOUR_LANGUAGE_WE_CURRENTLY_ONLY_SUPPORT_PODCASTS_IN_ENGLISH_BUT_PLAN_TO_SUPPORT_MORE_LANGUAGES_IN_THE_FUTURE
            }
          </i>
        </Alert>
      )
    );
  }, [podcast]);

  const handleRSSValidation = useCallback(
    async (item) => {
      toast.dismiss();
      const { rssUrl } = item;

      try {
        const rssFeedExists = await checkRSSExists(rssUrl);

        if (rssFeedExists) {
          toast.error(
            STRINGS.THIS_PODCAST_HAS_ALREADY_BEEN_CLAIMED_PLEASE_CONTACT_OWNER_OF_PODCAST_FOR_DETAILS
          );
          return false;
        }

        return true;
      } catch (error) {
        toast.error(STRINGS.AN_ERROR_OCCURED_ON_SERVER__TRY_AGAIN);
        return false;
      }
    },
    [checkRSSExists]
  );

  useLoadModalSubpage(pageNames.FEED_CONFIRMATION);

  return (
    <>
      <Box ref={headerRef} sx={{ maxWidth: '100%' }}>
        <Typography sx={{ mb: 3 }}>{STRINGS.ADD_PODCAST_TO_BEGIN}</Typography>
        <Typography sx={{ mb: 1 }} variant="h6">
          {STRINGS.RSS_FEED}
        </Typography>
        <PodcastSearch
          submit
          onRSSLoad={handleRSSLoad}
          onBeforeRSSLoad={handleRSSValidation}
          searchHeight={searchHeight}
        />
      </Box>

      {podcast && (
        <PodcastSearchResultItem item={podcast} index={0} isSelected style={{ mt: 3 }} />
      )}

      {renderLanguageWarning()}

      {podcast && <SendCode renderBackBtn={renderBackBtn} onNextClick={onNextClick} />}
    </>
  );
};

export default FeedConfirmation;
