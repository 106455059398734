import React from 'react';
import { Box, Typography } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { props } from './props';
import { STRINGS } from 'constants/strings';

const { ADD_NEW_PODCAST, ONBOARDING } = STRINGS;

const Stepper = ({ steps, activeStep }) => {
  return (
    <Box>
      <Typography variant="h5" sx={{ mb: 1 }}>
        {activeStep === 0 ? ADD_NEW_PODCAST : ONBOARDING}
      </Typography>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', mb: 3 }}>
        {steps.map((step, index) => (
          <Box
            key={index}
            sx={{
              display: 'flex',
            }}
          >
            <Typography
              sx={{
                ...(activeStep === index && {
                  fontWeight: 600,
                  color: 'main.mainPurple',
                  textDecorationLine: 'underline',
                  textUnderlineOffset: 5,
                  textDecorationColor: 'main.mainPurple',
                  textDecorationThickness: 1,
                }),
              }}
            >
              {step}
            </Typography>
            {index < steps.length - 1 && (
              <ChevronRightIcon
                sx={{
                  color: 'gray',
                }}
              />
            )}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

Stepper.propTypes = props;

export default Stepper;
