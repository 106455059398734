import React, { useCallback, useEffect, useState } from 'react';
import { SkeletonLoading } from '@components/index';
import { requestAPIGraphQL } from '@services/appSyncAPI';
import { getMarbylById } from '@graphql/queries/marbyls';
import { MARBYL_PREFIX } from 'constants/idPrefixes';
import { toggleMarbylStatus } from '@graphql/mutations/marbyls';
import { toast } from 'react-toastify';
import TOAST_OPTIONS from 'constants/toastOptions';
import { STRINGS } from 'constants/strings';
import { errorLog } from '@utils/logs';
import MarbylDetail from './detail';
// import EditMarbyl from './edit';

const Marbyl = ({ id }) => {
  const [marbyl, setMarbyl] = useState(null);
  // const [editMode, setEditMode] = useState(false);

  // const toggleEditMode = () => setEditMode(!editMode);
  const toggleActivation = ({ PK }) => {
    requestAPIGraphQL(toggleMarbylStatus, { marbylId: PK })
      .then(() => {
        toast.success(STRINGS.MARBYL_STATUS_UPDATED, TOAST_OPTIONS);
        loadMarbyl();
      })
      .catch((err) => {
        errorLog(err);
        toast.error(STRINGS.AN_ERROR_OCCURED_ON_SERVER__TRY_AGAIN, TOAST_OPTIONS);
      });
  };

  const loadMarbyl = useCallback(
    () =>
      requestAPIGraphQL(getMarbylById, {
        marbylId: `${MARBYL_PREFIX}${id}`,
      }).then(({ data: { MPCGetMarbylById } }) => {
        if (!MPCGetMarbylById) {
          return;
        }

        setMarbyl(MPCGetMarbylById);
      }),
    [id]
  );

  // const handleSubmit = ({ PK }) => {
  //   requestAPIGraphQL(toggleMarbylStatus, { marbylId: PK })
  //     .then(() => {
  //       toast.success(STRINGS.MARBYL_STATUS_UPDATED, TOAST_OPTIONS);
  //       loadMarbyl();
  //       toggleEditMode();
  //     })
  //     .catch((err) => {
  //       errorLog(err);
  //       toast.error(STRINGS.AN_ERROR_OCCURED_ON_SERVER__TRY_AGAIN, TOAST_OPTIONS);
  //     });
  // };

  useEffect(() => {
    loadMarbyl();
  }, [loadMarbyl]);

  if (!marbyl) {
    return <SkeletonLoading />;
  }

  // if (editMode) {
  //   return (
  //     <EditMarbyl marbyl={marbyl} onCancel={toggleEditMode} onSubmit={handleSubmit} />
  //   );
  // } else {
  return <MarbylDetail marbyl={marbyl} onToggleActivation={toggleActivation} />;
  // }
};

export default Marbyl;
