import styled from '@mui/styled-engine';
import { Box, Grid, Select, Typography, Chip } from '@mui/material';
import { FONT_FAMILIES } from 'constants/stylesVariables';

export const DrawerHeader = styled('div')(
  ({ theme, isDesktopOrLaptop }) => `
  display: 'flex',
  alignItems: 'start',
  padding: ${theme.spacing(0, 1)},
  justifyContent: ${isDesktopOrLaptop ? 'flex-start' : 'flex-end'},
  `
);

export const StyledMainContainer = styled(Box)(
  ({ theme }) => `
    display: flex;
    flex-direction: column;
    padding-left: ${theme.spacing(2)};

    @media (max-width: ${theme.breakpoints.values.sm}px) {
      padding: ${theme.spacing(2.5)};
    }
  `
);

export const StyledTitle = styled(Typography)(
  () => `
    font-family: ${FONT_FAMILIES.spaceGroteskMedium};
    font-size: 30px;
    font-weight: 500;
`
);

export const StyledMarbylText = styled(Typography)(
  () => `
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height: 1.3;
    font-family: ${FONT_FAMILIES.spaceGroteskMedium};
    font-size: 15px;
    line-height: 21px;
    color: #1C1D1C;
  `
);

export const StyledFiltersContainer = styled(Box)(
  ({ theme }) => `
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    min-height: ${theme.spacing(5.625)};

    div:not(:last-child) {
      margin-right: ${theme.spacing(1)};
    }

    & > * {
      margin-bottom: ${theme.spacing(1)};
      margin-top: ${theme.spacing(1)};
    }

    @media (max-width: ${theme.breakpoints.values.sm}px) {
      margin: 0;

      & > * {
        margin-bottom: ${theme.spacing(0.5)};
        margin-top: ${theme.spacing(0.5)};
      }
    }
  `
);

export const StyledFilterSelect = styled(Select)(
  () => `
    height: 35px;
    width: 200px;
  `
);

export const ClearFiltersContainer = styled(Grid)(
  ({ theme }) => `
    display: flex;
    align-items: flex-end;
    height: auto;
    justify-content: flex-start;
    padding-top: ${theme.spacing(2)};
  `
);

export const StyledScrollContainer = styled(Box)(
  ({ theme, isSidebarOpen }) => `
    max-height: 615px;
    overflow-y: auto;
    padding-top: 5px;

    &::-webkit-scrollbar {
        width: 7px;
    }

    &::-webkit-scrollbar-thumb {
        background: #bfbfbf;
        border-radius: 10px;
        height: 42px;
    }

    .List {
      display: grid;
      grid-gap: 22px;
      grid-template-columns: auto;
      overflow: hidden !important;

      @media (min-width: ${theme.breakpoints.values.sm}px) {
        grid-template-columns: ${isSidebarOpen ? 'auto' : 'auto auto'};
      }

      @media (min-width: ${theme.breakpoints.values.lg}px) {
        grid-template-columns: ${isSidebarOpen ? 'auto auto' : 'auto auto auto'};
      }
    }
    .List-View {
      display: grid;
    }
  `
);

export const StyledAllItemsText = styled('p')(
  () => `
    text-align: center;
    margin-top: 5px;
  `
);

export const StyledSubtitle = styled(Typography)(
  () => `
    font-family: ${FONT_FAMILIES.spaceGroteskMedium};
    font-size: 20px;
  `
);

export const StyledCounter = styled(Typography)(
  ({ theme }) => `
    font-family: ${FONT_FAMILIES.spaceGroteskRegular};
    font-size: 18px;
    margin-right: ${theme.spacing(1)};
  `
);

export const StyledEpisodeDetails = styled(Typography)(
  () => `
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    line-clamp: 2; 
    overflow: hidden;
    font-family: ${FONT_FAMILIES.spaceGroteskRegular};
    font-size: 14px;
    color: #1C1D1C;
    line-height: 18px;
  `
);

export const StyledEMarbylDetails = styled(Typography)(
  () => `
    font-family: ${FONT_FAMILIES.spaceGroteskMedium};
    font-size: 16px;
    color: #222222;
    line-height: 19px;
  `
);

export const StyledChip = styled(Chip)(
  () => `
    height: 25px;
    background-color: #F0EDFF;
    font-family: ${FONT_FAMILIES.spaceGroteskMedium};
    font-size: 13px;
    color: #3D3D3D;
  `
);

export const StyledGridList = styled(Grid)(
  ({ theme, isHeader }) => `
    padding-bottom: ${theme.spacing(isHeader ? 2 : 5)};
    margin-top: ${theme.spacing(isHeader ? 1 : 0)};
    margin-bottom: ${theme.spacing(isHeader ? 0 : 2)};
    border-bottom: ${isHeader ? '1px solid #B8B8B8' : 'none'};
    background-color: ${isHeader ? 'transparent' : 'white'};
    box-shadow: ${isHeader ? 'none' : 'inset 0px -1px 0px #D7D7D7'};
  `
);

export const StyledMarbylTextList = styled(Typography)(
  ({ marbylText }) => `
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    textOverflow: ellipsis;
    font-family: ${FONT_FAMILIES.spaceGroteskRegular};
    font-size: 14px;
    line-height: ${marbylText ? '21px' : '24px'};
    color: ${marbylText ? '#000' : '#333333'};
  `
);
