import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { isEmpty } from 'lodash';
import { Box, CircularProgress } from '@mui/material';

import { Route, useRouteMatch, Switch, useHistory, useLocation } from 'react-router-dom';
import { StyledBreadcrums, StyledOl } from '@pages/MPCPage/MPCPage.styled';
import { HierarchyLink } from '@components/index';
import EntitiesNamesProvider from 'contexts/EntitiesNamesContext';
import NetworkRoutes from '@routes/NetworkRoutes';
import PodcasterRoutes from '@routes/PodcasterRoutes';
import { useMPC } from 'contexts/MPCContext';
import { getRouteToRedirect } from '@utils/getRouteToRedirect';
import { NETWORK_VIEW_GROUPS } from 'constants/userGroups';
import { MPC_ROUTES } from 'constants/routing';

const MPCPage = () => {
  const { selectedAccount } = useMPC();

  const { url } = useRouteMatch();
  const { pathname } = useLocation();
  const history = useHistory();
  const [initialRoute, setInitialRoute] = useState({ path: '', title: '' });

  const hasNetworkAccess = useMemo(() => {
    if (!selectedAccount) return false;

    return Object.prototype.hasOwnProperty.call(
      NETWORK_VIEW_GROUPS,
      selectedAccount?.accountRole
    );
  }, [selectedAccount]);

  const renderNetworkRoute = useCallback(() => {
    return hasNetworkAccess ? (
      <Route path={`${url}${MPC_ROUTES.NETWORKS}${MPC_ROUTES.ID}`}>
        <NetworkRoutes />
      </Route>
    ) : null;
  }, [hasNetworkAccess, url]);

  const renderBreadcrumbs = useCallback(() => {
    const { path, title } = initialRoute;

    if (!path || !title) {
      return null;
    }

    return (
      <div role="presentation">
        <StyledBreadcrums aria-label="breadcrumb">
          <StyledOl>
            <Route
              path={path}
              component={() => <HierarchyLink title={title} renderSlash={false} />}
            />
          </StyledOl>
        </StyledBreadcrums>
      </div>
    );
  }, [initialRoute]);

  useEffect(() => {
    if (!isEmpty(selectedAccount)) {
      const { to, name } = getRouteToRedirect(url, selectedAccount);

      if (pathname === url) history.push(to);

      setInitialRoute({ path: to, title: name });
    }
  }, [history, url, pathname, selectedAccount]);

  return (
    <EntitiesNamesProvider>
      <Box
        sx={{
          width: '100%',
          mb: 2,
        }}
      >
        {renderBreadcrumbs()}
      </Box>

      <Box>
        <Switch>
          <Route exact path={`${url}`}>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress color="secondary" />
            </Box>
          </Route>
          {renderNetworkRoute()}
          <Route path={`${url}${MPC_ROUTES.PODCASTER}${MPC_ROUTES.ID}`}>
            <PodcasterRoutes />
          </Route>
        </Switch>
      </Box>
    </EntitiesNamesProvider>
  );
};

export default MPCPage;
