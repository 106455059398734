import { STRINGS } from './strings';

export const STATUS_ACTIVE = 'active';
export const STATUS_INACTIVE = 'inactive';

export const STATUS_LABELS = {
  [STATUS_ACTIVE]: STRINGS.ACTIVE,
  [STATUS_INACTIVE]: STRINGS.INACTIVE,
};

export const NEW_STATUS_ACTIVE = 'Active';
export const NEW_STATUS_DISABLED = 'Disabled';

export const NEW_STATUS_LABELS = {
  [NEW_STATUS_ACTIVE]: STRINGS.ACTIVE,
  [NEW_STATUS_DISABLED]: STRINGS.DISABLED,
};
