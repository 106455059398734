import React, { useCallback, useMemo } from 'react';

import { Route, useRouteMatch, useParams, Switch, useLocation } from 'react-router-dom';
import { StyledLi, StyledLink, StyledChevron } from './styled';
import { STRINGS } from 'constants/strings';
import { useEntityNames } from 'contexts/EntitiesNamesContext';
import { MPC_ROUTES } from 'constants/routing';
import { MPC_ENTITIES } from 'constants/mpc';
import { defaultProps, props } from './props';

const HierarchyLink = ({ title, renderSlash }) => {
  const [entityName] = useEntityNames();
  const { url } = useRouteMatch();
  const { pathname } = useLocation();
  let { id: idParam } = useParams();

  const hashEntitiesNotToRender = useMemo(
    () => ({
      [MPC_ENTITIES.NETWORK]: true,
      [MPC_ENTITIES.PODCASTER]: true,
      [MPC_ENTITIES.PODCAST]: true,
      [MPC_ENTITIES.EPISODES]: true,
      [MPC_ENTITIES.MARBYLS]: true,
    }),
    []
  );

  const removeSlash = (route) => route.replace('/', '');

  const titleByRoutes = useMemo(
    () => [
      {
        id: MPC_ENTITIES.USERS,
        title: STRINGS.USER_MANAGEMENT,
      },
      {
        id: removeSlash(MPC_ROUTES.CONTENT_REPORTS),
        title: STRINGS.CONTENT_REPORTS,
      },
      {
        id: removeSlash(MPC_ROUTES.ADD),
        title: STRINGS.NEW,
      },
      {
        id: removeSlash(MPC_ROUTES.EDIT),
        title: STRINGS.EDIT,
      },
      {
        id: removeSlash(MPC_ROUTES.PROFILE),
        title: STRINGS.USER_PROFILE,
      },
      {
        id: removeSlash(MPC_ROUTES.TRANSCRIPT_VIEW),
        title: STRINGS.TRANSCRIPT,
      },
    ],
    []
  );

  const actual = useMemo(() => {
    const routeIndex = titleByRoutes.findIndex(
      (titleByRoute) => titleByRoute.id === idParam
    );

    if (title) return { title };

    if (hashEntitiesNotToRender[idParam]) return { title: null };

    if (routeIndex > -1) return { title: titleByRoutes[routeIndex].title };

    if (!entityName[idParam]) return { title: 'N/A' };

    return {
      title: entityName[idParam],
    };
  }, [idParam, title, entityName, hashEntitiesNotToRender]);

  const activePath = useMemo(() => url === pathname, [pathname, url]);

  const renderBreadcrumb = useCallback(() => {
    if (!actual.title) return null;

    return (
      <>
        {renderSlash && (
          <StyledLi>
            <StyledChevron />
          </StyledLi>
        )}
        <StyledLi active={activePath}>
          {!activePath ? <StyledLink to={url}>{actual.title}</StyledLink> : actual.title}
        </StyledLi>
      </>
    );
  }, [actual, renderSlash, activePath, url]);

  return (
    <>
      {renderBreadcrumb()}
      <Switch>
        <Route path={`${url}/:id`} component={HierarchyLink} />
      </Switch>
    </>
  );
};

HierarchyLink.propTypes = props;
HierarchyLink.defaultProps = defaultProps;

export default HierarchyLink;
