export const listMarbylsByEntityId = /* GraphQL */ `
  query listMarbylsByEntityId(
    $entityId: String!
    $limit: Int!
    $dateFilterPreference: FilterMarbylListDashboard!
    $sortPreference: SortMarbylListDashboard!
    $madeBy: MadeBy
    $nextToken: String
  ) {
    MPCListMarbylsByEntityId(
      entityId: $entityId
      limit: $limit
      dateFilterPreference: $dateFilterPreference
      sortPreference: $sortPreference
      madeBy: $madeBy
      nextToken: $nextToken
    ) {
      items {
        createdAt
        entityType
        episodeId
        episodeTitle
        marbylId
        marbylStartTime
        marbylText
        sharesCount
        showId
        showTitle
        userId
        userName
        viewersCount
        networkId
        networkName
      }
      nextToken
      totalResults
    }
  }
`;

export const getEntityById = /* GraphQL */ `
  query getEntityById($entityId: String) {
    MPCGetEntityById(entityId: $entityId) {
      entityId
      entityName
      entityType
    }
  }
`;

export const getDropdownDashboardItem = /* GraphQL */ `
  query getDropdownDashboardItem(
    $entityId: String
    $limit: Int
    $nextToken: String
    $filterByType: ItemFilter
  ) {
    entitiesForDropdown: MPCListEntitiesForDropdownDashboardView(
      entityId: $entityId
      limit: $limit
      nextToken: $nextToken
      itemFilter: $filterByType
    ) {
      items {
        entityId
        entityName
        entityType
      }
      totalResults
      nextToken
    }
  }
`;

export const searchMarbyls = /* GraphQL */ `
  query SearchMarbyls(
    $q: String!
    $limit: Int
    $nextToken: String
    $portalsLoggedAccountId: String
    $similaritySearch: Boolean
  ) {
    searchMarbyls(
      q: $q
      limit: $limit
      nextToken: $nextToken
      portalsLoggedAccountId: $portalsLoggedAccountId
      similaritySearch: $similaritySearch
    ) {
      nextToken
      totalResults
      items {
        PK
        createdAt
        episode {
          PK
          title
          show {
            PK
            title
            network
          }
        }
        startTime
        text
        shareCount
        user {
          PK
          userName
        }
        viewCount
        highlight
        innerHighlights
        isActivated
        GSI10PK
        Podcaster
      }
    }
  }
`;
