import { useEffect } from 'react';
import PropTypes from 'prop-types';

import { requestAPIGraphQL } from '@services/appSyncAPI';
import useNotifications from 'hooks/useNotifications';
import useReducer from 'hooks/useReducer';
import { useMPC } from 'contexts/MPCContext';

const initialState = {
  listRefreshing: false,
  listNextToken: '0',
  listFirstLoad: true,
  searchTermRequested: '',
  listLoading: true,
  listError: null,
  deletedItems: [],
  totalCount: 0,
  listEnd: false,
  list: [],
};

const useSearchList = (endpoint, limit = 20, entity = 'Marbyls') => {
  const [
    {
      searchTermRequested,
      listRefreshing,
      listNextToken,
      listFirstLoad,
      listLoading,
      totalCount,
      listError,
      listEnd,
      list,
    },
    setState,
  ] = useReducer(initialState);
  const {
    selectedAccount: { accountId, entityId },
  } = useMPC();

  const { showErrorMessages } = useNotifications();

  useEffect(() => {
    if (!!entityId) {
      requestList('', true);
    }
  }, [entityId]);

  const requestList = async (q = searchTermRequested, isRefreshing = false) => {
    if ((listEnd && !isRefreshing) || !accountId) return;

    const isNewRequest = isRefreshing || searchTermRequested !== q;
    if (isNewRequest) {
      setState({
        listRefreshing: true,
        listFirstLoad: true,
        listNextToken: '0',
        listLoading: true,
        listEnd: false,
        list: [],
      });
    } else {
      setState({ listLoading: true });
    }
    try {
      const payload = {
        limit: listNextToken === '0' || isNewRequest ? limit : 20,
        nextToken: isNewRequest ? '0' : listNextToken,
        similaritySearch: true,
        portalsLoggedAccountId: accountId,
        q: q,
      };

      const responseData = await requestAPIGraphQL(endpoint, payload);
      const {
        items,
        nextToken: newNextToken,
        totalResults,
      } = Object.values(responseData?.data)[0];
      const newItems = isNewRequest ? items : [...list, ...items];
      setState({
        listRefreshing: false,
        listNextToken: newNextToken,
        listFirstLoad: false,
        listLoading: false,
        listError: null,
        listEnd: newNextToken === null || items.length === 0,
        list: newItems,
        searchTermRequested: q,
        totalCount: totalResults,
      });
    } catch (err) {
      setState({
        listLoading: false,
      });
      showErrorMessages(
        err.errors,
        `There was an error trying to retrieve the ${entity}`
      );
    }
  };

  return {
    listRefreshing,
    listNextToken,
    listFirstLoad,
    listLoading,
    totalCount,
    listError,
    listEnd,
    list,
    requestList,
  };
};

useSearchList.propTypes = {
  endpoint: PropTypes.string.isRequired,
  limit: PropTypes.number,
  entity: PropTypes.string,
};

export default useSearchList;
