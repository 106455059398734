import { DynamicForm } from '@components/index';
import { FIELD_TYPES } from 'constants/dynamicFormTypes';
import { STRINGS } from 'constants/strings';
import React, { useMemo } from 'react';
import { Typography, Grid, TextField } from '@mui/material';
import { getActiveStatusValue } from '@utils/getActiveStatus';
import {
  STATUS_ACTIVE,
  STATUS_INACTIVE,
  STATUS_LABELS,
  NEW_STATUS_LABELS,
  NEW_STATUS_ACTIVE,
  NEW_STATUS_DISABLED,
} from 'constants/activeStatus';
import { useAuth } from 'contexts/AuthContext';
import { PODCAST_VIEW_ACTIONS } from 'constants/actionsList';

const EditPodcast = ({
  PK,
  isActivated,
  podcastMarbylizationIsEnabled,
  m11nAutoType,
  onCancel,
  onSubmit,
}) => {
  const { permissions } = useAuth();

  const canEditBasic = useMemo(
    () => !!permissions[PODCAST_VIEW_ACTIONS.EDIT_PODCAST_BASIC_INFO],
    [permissions]
  );

  const canToggleStatus = useMemo(
    () => !!permissions[PODCAST_VIEW_ACTIONS.TOGGLE_ACTIVATION_PODCAST],
    [permissions]
  );

  const canToggleM11n = useMemo(
    () => !!permissions[PODCAST_VIEW_ACTIONS.TOGGLE_M11N],
    [permissions]
  );

  const fields = useMemo(() => {
    return [
      {
        type: FIELD_TYPES.TITLE,
        label: STRINGS.PODCAST_SETTINGS,
      },
      ...(canToggleStatus
        ? [
            {
              type: FIELD_TYPES.SELECT,
              label: 'Podcast Availability',
              name: 'isActivated',
              items: [STATUS_ACTIVE, STATUS_INACTIVE].map((_status) => ({
                value: _status,
                label: STATUS_LABELS[_status],
              })),
              md: 6,
              sm: 6,
              xs: 12,
              color: 'secondary',
            },
          ]
        : []),
      ...(canToggleM11n
        ? [
            {
              type: FIELD_TYPES.SELECT,
              items: [NEW_STATUS_ACTIVE, NEW_STATUS_DISABLED].map((_status) => ({
                value: _status,
                label: NEW_STATUS_LABELS[_status],
              })),
              label: 'Marbylization Status',
              name: 'podcastMarbylizationIsEnabled',
              md: 6,
              sm: 6,
              xs: 12,
              color: 'secondary',
            },
          ]
        : []),
    ];
  }, [canEditBasic, canToggleStatus, canToggleM11n]);

  const initialValues = useMemo(() => {
    const init = {
      ...(canToggleStatus && {
        isActivated: getActiveStatusValue(isActivated),
      }),
      ...(canToggleM11n && {
        podcastMarbylizationIsEnabled: podcastMarbylizationIsEnabled,
      }),
    };

    return init;
  }, [
    isActivated,
    podcastMarbylizationIsEnabled,
    m11nAutoType,
    canEditBasic,
    canToggleStatus,
    canToggleM11n,
  ]);

  const handleSubmit = (values) => onSubmit({ id: PK, ...values });

  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
          <Typography variant="h6">{STRINGS.RSS_INFORMATION}</Typography>
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            label={STRINGS.FEED_URL}
            color="secondary"
            disabled
            name="rss-feed"
            fullWidth
            value={'https://feeds.simplecast.com/54nAGcIl'}
          />
        </Grid>
      </Grid>

      <DynamicForm
        formHeader={''}
        formHeaderVariant={'h5'}
        initialValues={initialValues}
        fields={fields}
        onCancel={onCancel}
        onSubmit={handleSubmit}
      />
    </>
  );
};

export default EditPodcast;
