import MicNoneOutlinedIcon from '@mui/icons-material/MicNoneOutlined';
import GroupIcon from '@mui/icons-material/Group';
import ReportIcon from '@mui/icons-material/Report';
import WindowOutlinedIcon from '@mui/icons-material/WindowOutlined';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import AddIcon from '@mui/icons-material/Add';
import { STRINGS } from 'constants/strings';

import packageFile from '../../package.json';

export const BASENAME = packageFile.homepage === '/' ? '' : packageFile.homepage;

export const MAIN_ROUTES = {
  LANDING: packageFile.homepage,
  HOME: BASENAME,
  POLICY: `${BASENAME}/policy`,
  TERMS: `${BASENAME}/terms`,
  OPEN_SOURCE_ATTRIBUTIONS: `${BASENAME}/open-source-attributions`,
  PARTNERS: `${BASENAME}/partners`,
  ONBOARDING: `${BASENAME}/partners`,
  LOGIN: `${BASENAME}/login`,
  RESET_PASSWORD: `${BASENAME}/reset-password/:id`,
  ACCOUNT_CONFIRMATION: `${BASENAME}/confirmation`,
  CONFIRM_ACCOUNT_USER: `${BASENAME}/confirm-account-user/`,
  CANCEL_ACCOUNT_DELETION: `${BASENAME}/cancel-account-deletion`,
  CONFIRM_ACCOUNT_DELETION: `${BASENAME}/confirm-account-deletion`,
  CONFIRM_SIGNUP: `${BASENAME}/confirm-signup`,
  APP: `${BASENAME}/app`,
  MPC: `${BASENAME}/app/mpc`,
  PODCASTS: `${BASENAME}/app/podcasts`,
  USERS: `${BASENAME}/app/users`,
  PROFILE: `${BASENAME}/app/profile`,
  REPORTED_CONTENT: `${BASENAME}/app/reports`,
  TRANSCRIPTION_ERRORS: `${BASENAME}/app/transcription-errors`,
  MARBYL_CHART_VIEW: `${BASENAME}/app/marbyl-chart-view`,
  BETA: `${BASENAME}/beta`,
};

export const MPC_ROUTES = {
  CONTENT_REPORTS: `/reports`,
  SINGLE_REPORT: `/report`,
  USERS: '/users',
  PODCASTER: '/podcasters',
  PODCAST: '/podcast',
  EPISODES: '/episodes',
  MARBYLS: '/marbyls',
  NETWORKS: '/networks',
  ADD: '/add',
  ID: '/:id',
  EDIT: '/edit',
  ACCOUNT: '/account',
  PROFILE: '/profile',
  TRANSCRIPT_VIEW: '/transcript',
};

export const AUTH_ROUTES = {
  LOGIN: '/login',
};

export const PARTNERS_ROUTES = {
  HOME: MAIN_ROUTES.PARTNERS,
  CLAIM: `${MAIN_ROUTES.PARTNERS}/claim`,
  PODCASTS: `${MAIN_ROUTES.PARTNERS}/podcasts`,
};

export const ROUTES = [
  {
    label: STRINGS.DASHBOARD,
    icon: <WindowOutlinedIcon />,
    to: MAIN_ROUTES.MARBYL_CHART_VIEW,
  },
  {
    label: STRINGS.PODCASTS,
    icon: <MicNoneOutlinedIcon />,
    to: MAIN_ROUTES.MPC,
    rightAction: {
      icon: <AddIcon />,
      to: MAIN_ROUTES.MPC,
    },
  },
  {
    label: STRINGS.USERS,
    icon: <GroupIcon />,
    to: MAIN_ROUTES.USERS,
  },
  {
    label: STRINGS.REPORTED_CONTENT,
    icon: <ReportIcon />,
    to: MAIN_ROUTES.REPORTED_CONTENT,
  },
  {
    label: STRINGS.TRANSCRIPT_REVIEWS,
    icon: <ContentPasteIcon />,
    to: MAIN_ROUTES.TRANSCRIPTION_ERRORS,
  },
];
