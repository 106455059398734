import styled from '@mui/styled-engine';
import { Link } from 'react-router-dom';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { HEX_COLORS } from 'constants/stylesVariables';

export const StyledBreadcrums = styled('nav')(() => ``);

export const StyledOl = styled('ol')(
  () => `
  display: flex;
  list-style: none;
  flex-wrap: wrap;
`
);

export const StyledLink = styled(Link)(() => ``);

export const StyledLi = styled('li')(
  ({ theme, active }) => `
  margin-right: ${theme.spacing(0.5)};
  color: ${active ? 'black' : HEX_COLORS.newPurple};
  font-family: space_grotesk_regular;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  a { 
    color: inherit;

    &:hover { 
      color: inherit;
    }
  }
`
);

export const StyledChevron = styled(ChevronRightIcon)(
  () => `
  font-weight: 400;
  font-size: 17px;
  color: #000000;
  position: relative;
  top: 2px;
`
);
