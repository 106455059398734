import React, { forwardRef } from 'react';
import { AgGridReact } from 'ag-grid-react';

const TEMPLATE =
  '<div class="ag-cell-label-container" role="presentation">' +
  '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
  '  <div ref="eLabel" class="ag-header-cell-label justify-center" style="align-items: flex-end; padding-bottom: 8px;" role="presentation">' +
  '    <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>' +
  '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
  '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
  '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
  '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
  '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
  '  </div>' +
  '</div>';

const customRowStyle = {
  background: '#FFFFFF',
  boxShadow: 'inset 0px -1px 0px #D7D7D7',
  borderColor: '#FFF',
  fontFamily: 'space_grotesk_regular',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: 16,
  color: '#333333',
  cursor: 'pointer',
  maxHeight: 60,
  // color: #7535FF; purple for titles
};

const EntitiesDataGrid = forwardRef(
  (
    {
      // topHeight = 400,
      columnDefs,
      rowData = null,
      onCellClicked = () => null,
      onSelectionChanged = () => null,
      headerHeight = 40,
      rowModelType = 'clientSide',
      onGridReady = () => null,
      cacheBlockSize = 100,
      rowClassRules = {},
      getRowId = null,
      rowStyle = customRowStyle,
      getRowStyle = () => null,
      isEmpty = false,
    },
    ref
  ) => {
    return (
      <div
        className="ag-theme-custom"
        style={{
          marginTop: 16,
          height: isEmpty ? 0 : '65vh',
          width: '100%',
          position: 'relative',
        }}
      >
        <AgGridReact
          ref={ref}
          columnDefs={columnDefs}
          rowData={rowData}
          defaultColDef={{
            headerComponentParams: {
              template: TEMPLATE,
            },
          }}
          rowSelection={'multiple'}
          rowModelType={rowModelType}
          suppressRowClickSelection={true}
          suppressAggFuncInHeader={true}
          rowHeight={60}
          onSelectionChanged={onSelectionChanged}
          onGridReady={onGridReady}
          cacheBlockSize={cacheBlockSize}
          onCellClicked={onCellClicked}
          headerHeight={headerHeight}
          rowStyle={rowStyle}
          rowClassRules={rowClassRules}
          groupHeaderHeight={30}
          getRowId={getRowId}
          getRowStyle={getRowStyle}
          tooltipShowDelay={0}
          tooltipHideDelay={2000}
          rowBuffer={0}
        ></AgGridReact>
      </div>
    );
  }
);

export default EntitiesDataGrid;
