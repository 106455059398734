import { STRINGS } from 'constants/strings';
import { ConfirmPage } from '@components/index';
import { useHistory } from 'react-router-dom';
import { MAIN_ROUTES } from 'constants/routing';
import { useAuth } from 'contexts/AuthContext';
import { toast } from 'react-toastify';
import { record } from '@utils/analytics';
import {
  TAP_CONFIRM_CODE,
  TAP_RESEND_CODE,
  USER_ACCOUNT_CREATED,
} from 'constants/analytics';

const ConfirmSignUpPage = () => {
  const history = useHistory();
  const { loading, confirmSignUp, resendCode } = useAuth();

  const handleConfirm = (code) => {
    record(TAP_CONFIRM_CODE);

    confirmSignUp(code)
      .then(() => {
        record(USER_ACCOUNT_CREATED);
        history.push(MAIN_ROUTES.APP);
      })
      .catch((err) => toast.error(err.message));
  };

  const handleResend = () => {
    record(TAP_RESEND_CODE);

    resendCode()
      .then(() => toast.success(STRINGS.CODE_HAS_BEEN_SENT))
      .catch((err) => toast.error(err.message));
  };

  return (
    <>
      <ConfirmPage
        title={STRINGS.CONFIRM_SIGN_UP}
        loading={loading}
        fieldProperties={{
          placeholder: 'Enter your confirmation code',
          label: 'Confirmation Code',
          required: true,
        }}
        description={STRINGS.PLEASE_CHECK_FOR_EMAIL_FOR_CONFIRMATION_CODE}
        submitBtnText={STRINGS.CONFIRM_CODE}
        secondBtnText={STRINGS.RESEND_CODE}
        onSubmit={handleConfirm}
        onCancel={handleResend}
      />
    </>
  );
};

export default ConfirmSignUpPage;
