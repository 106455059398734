import React, { useState } from 'react';
import PropTypes from 'prop-types';

import FormatListBulletedRoundedIcon from '@mui/icons-material/FormatListBulletedRounded';
import GridViewRoundedIcon from '@mui/icons-material/GridViewRounded';

import { StyledContainer, StylecIconContainer } from './styled';

import { FILTER_TYPES } from 'constants/filterTypes';

const ViewFilter = ({ defaultFilter, onChangeFilter }) => {
  const [filterMode, setFilterMode] = useState(defaultFilter || FILTER_TYPES.CARD);

  const handleOnChangeFilter = (filter) => {
    setFilterMode(filter);
    onChangeFilter(filter);
  };
  return (
    <StyledContainer>
      <StylecIconContainer
        disableRipple
        onClick={() => handleOnChangeFilter(FILTER_TYPES.CARD)}
        sx={
          filterMode === FILTER_TYPES.CARD
            ? {
                border: '1px solid #C6CBD4',
                backgroundColor: '#000',
              }
            : {
                backgroundColor: '#fff',
              }
        }
      >
        <GridViewRoundedIcon
          sx={{
            color: filterMode === FILTER_TYPES.CARD ? 'white' : 'black',
            fontSize: 19,
          }}
        />
      </StylecIconContainer>
      <StylecIconContainer
        disableRipple
        onClick={() => handleOnChangeFilter(FILTER_TYPES.LIST)}
        sx={
          filterMode === FILTER_TYPES.LIST
            ? {
                border: '1px solid #C6CBD4',
                backgroundColor: '#000',
              }
            : {
                backgroundColor: '#fff',
              }
        }
      >
        <FormatListBulletedRoundedIcon
          fontSize="small"
          sx={{
            color: filterMode === FILTER_TYPES.LIST ? 'white' : 'black',
          }}
        />
      </StylecIconContainer>
    </StyledContainer>
  );
};

ViewFilter.propTypes = {
  onChangeFilter: PropTypes.func,
  defaultFilter: PropTypes.string,
};

ViewFilter.defaultProps = {
  onChangeFilter: () => {},
  defaultFilter: FILTER_TYPES.CARD,
};

export default ViewFilter;
