import React, { useCallback, useRef } from 'react';
import { InfiniteLoader } from '@components/index';
import useInfiniteScroll from 'hooks/useInfiniteScroll';
import { useDeps } from 'contexts/DepsContext';
import { Box, CircularProgress, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import { STRINGS } from 'constants/strings';
import { useTheme } from '@mui/material/styles';
import { StyledImg, StyledOl } from './styled';
import { props } from './props';

export const PodcastSearchResultItem = ({
  onClickItem = () => {},
  onClose = () => {},
  item,
  index = 0,
  isSelected = false,
  style = {},
}) => {
  return (
    <Box
      role="listitem"
      sx={{
        ...style,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        cursor: 'pointer',
        p: 2,
        '&:hover': !isSelected && {
          backgroundColor: 'primary.dark',
        },
        bgcolor: !isSelected ? 'primary.main' : 'main.contrastBackground',
        color: 'secondary.main',
        borderTop: 1,
        borderBottom: isSelected ? 1 : 0,
        borderColor: 'lightgray',
      }}
      onClick={() => {
        onClickItem(item, index);
        onClose();
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row',
        }}
      >
        <StyledImg src={item?.imageUrl} alt="Podcast art" />
        <Typography sx={{ fontSize: 15, fontWeight: '500' }}>{item?.title}</Typography>
      </Box>
      <Typography
        sx={{
          fontSize: 15,
          fontWeight: '500',
          color: !isSelected ? 'main.purple4' : 'lightGray',
        }}
      >
        {isSelected ? STRINGS.SELECTED : STRINGS.SELECT}
      </Typography>
    </Box>
  );
};

const PodcastSearchResults = ({
  searchText,
  onClose,
  onClickItem,
  searchHeight,
  onLoad,
}) => {
  const { podcastsSearchAPI } = useDeps();
  const nextPage = useRef(0);
  const firstLoad = useRef(false);
  const theme = useTheme();

  const getItems = useCallback(() => {
    return new Promise((resolve) => {
      podcastsSearchAPI(searchText, nextPage.current)
        .then(({ results, next_offset, total }) => {
          if (!firstLoad.current) {
            onLoad();
            firstLoad.current = true;
          }

          if (results.length === 0) {
            toast.warn(STRINGS.NO_RESULTS_WERE_FOUND);
          }
          nextPage.current = next_offset;

          resolve({
            data: results,
            hasNextPage: results.length < total,
            total,
          });
        })
        .catch((err) => {
          toast.warn(err.message);
          onClose();
        });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  const { total, items, hasNextPage, isNextPageLoading, loadNextPage } =
    useInfiniteScroll({ getItems });

  const renderItem = useCallback(
    (item, style, index) => {
      return (
        <PodcastSearchResultItem
          item={item}
          index={index}
          style={style}
          onClickItem={onClickItem}
          onClose={onClose}
          isSelected={false}
        />
      );
    },
    [onClose, onClickItem]
  );

  return (
    <StyledOl show={items.length > 0} role="list">
      <InfiniteLoader
        hasNextPage={hasNextPage}
        isNextPageLoading={isNextPageLoading}
        loadNextPage={loadNextPage}
        items={items}
        height={searchHeight}
        itemSize={100}
        width={'100%'}
        renderComponent={renderItem}
        style={{
          backgroundColor: theme.palette.primary.main,
          boxShadow:
            '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
        }}
        renderLoading={(style) => {
          return (
            <Box sx={{ ...style, display: 'flex', justifyContent: 'center' }}>
              <CircularProgress color="secondary" />
            </Box>
          );
        }}
        count={total}
      />
    </StyledOl>
  );
};

PodcastSearchResults.propTypes = props;

export default PodcastSearchResults;
