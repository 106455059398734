import React, { useCallback, useMemo } from 'react';
import { Button, Box } from '@mui/material';
import { useRouteMatch } from 'react-router-dom';
import { useHistory } from 'react-router';
import { MetaInfo } from '@components/index';
import { StyledHeaderSection } from '@shared/styledGeneral';
import { getEpisodeProcessingStatus } from '@utils/m11n';
import { dateToMMDoYYYY } from '@utils/dateFormat';
import { formatDuration } from '@utils/time';
import { convertGeoArrayToText } from '@utils/getAvailableInGeos';
import { EPISODE_VIEW_ACTIONS } from 'constants/actionsList';
import { ACTIVATION, MARBYLIZATION } from 'constants/episodeActions';
import { M11N_AUTO_TYPES } from 'constants/m11n';
import { MPC_ROUTES } from 'constants/routing';
import { STRINGS } from 'constants/strings';
import { useAuth } from 'contexts/AuthContext';

import { levenshteinDistance } from '@utils/string';
import { breakLineRegex } from '@utils/regex';

const EpisodeDetail = ({
  title,
  subtitle,
  summary,
  guid,
  enclosure,
  infoLink,
  imageUrl,
  author,
  creator,
  pubDate,
  episode,
  season,
  episodeType,
  explicit,
  duration,
  block,
  description,
  isActivated,
  isActivatedUpdated,
  availableInGeos,
  m11nIsEnabled,
  m11nIsEnabledUpdated,
  m11nProcessingState,
  lastM11nModelUsed,
  lastM11nDate,
  lastM11nResult,
  lastM11nModelVersion,
  lastM11nMarbylsCreated,
  show: { m11nAutoType },
  onClickAction,
}) => {
  const history = useHistory();
  const { url } = useRouteMatch();
  const { permissions } = useAuth();

  const metaPodcastInfo = useMemo(() => {
    return [
      { label: STRINGS.TITLE, value: title, md: 2.4, xs: 12 },
      { label: STRINGS.SUBTITLE, value: subtitle, md: 2.4, xs: 12 },
      { label: 'GUID', value: guid, md: 2.4, xs: 12 },
      {
        label: STRINGS.ENCLOSURE,
        value: enclosure,
        md: 2.4,
        xs: 12,
        renderValue: (val) => {
          return (
            <a href={val} target="_blank" rel="noreferrer">
              Link
            </a>
          );
        },
      },
      { label: STRINGS.BLOCK, value: block, md: 2.4, xs: 12 },
      {
        label: STRINGS.INFO_LINK,
        value: infoLink,
        md: 2.4,
        xs: 12,
        renderValue: (val) => {
          return (
            <a href={val} target="_blank" rel="noreferrer">
              Link
            </a>
          );
        },
      },
      {
        label: STRINGS.IMAGE_URL,
        value: imageUrl,
        md: 2.4,
        xs: 12,
        renderValue: (val) => {
          return (
            <a href={val} target="_blank" rel="noreferrer">
              Link
            </a>
          );
        },
      },
      { label: STRINGS.AUTHOR, value: author?.name || author?.email, md: 2.4, xs: 12 },
      { label: STRINGS.CREATOR, value: creator, md: 2.4, xs: 12 },
      {
        label: STRINGS.PUBLISH_DATE,
        value: pubDate,
        md: 2.4,
        xs: 12,
        renderValue: (val) => (val ? dateToMMDoYYYY(val) : STRINGS.PENDING),
      },
      { label: STRINGS.EPISODE, value: episode, md: 2.4, xs: 12 },
      { label: STRINGS.SEASON, value: season, md: 2.4, xs: 12 },
      { label: STRINGS.EPISODE_TYPE, value: episodeType, md: 2.4, xs: 12 },
      {
        label: STRINGS.EXPLICIT,
        value: explicit,
        md: 2.4,
        xs: 12,
      },
      {
        label: STRINGS.DURATION,
        value: duration ? formatDuration(duration) : '',
        md: 2.4,
        xs: 12,
      },
      {
        label: `${STRINGS.DESCRIPTION} / ${STRINGS.SUMMARY}`,
        value: description,
        md: 12,
        xs: 12,
        renderValue: () => {
          return levenshteinDistance(
            description?.replace(breakLineRegex, '') || '',
            summary?.replace(breakLineRegex, '') || ''
          ) === 0 ? (
            <p>{description}</p>
          ) : (
            <>
              {description && <p>{description}</p>}
              {summary && <p>{summary}</p>}
            </>
          );
        },
      },
    ];
  }, [
    title,
    subtitle,
    summary,
    guid,
    enclosure,
    infoLink,
    imageUrl,
    author,
    creator,
    pubDate,
    episode,
    season,
    episodeType,
    explicit,
    duration,
    block,
    description,
  ]);

  const episodeStatusInfo = useMemo(
    () => [
      {
        label: STRINGS.STATUS,
        value: isActivated,
        md: 4,
        xs: 12,
        renderValue: (val) => (val ? STRINGS.ACTIVE : STRINGS.INACTIVE),
      },
      {
        label: STRINGS.ACTIVATION_DATE,
        value: isActivatedUpdated,
        md: 4,
        xs: 12,
        renderValue: (val) => (val ? dateToMMDoYYYY(val) : STRINGS.PENDING),
      },
      {
        label: STRINGS.GEOGRAPHIC_AVAILABILITY,
        value: convertGeoArrayToText(availableInGeos),
        md: 4,
        xs: 12,
      },
    ],
    [isActivated, isActivatedUpdated, availableInGeos]
  );

  const marbylizationInfo = useMemo(
    () => [
      {
        label: STRINGS.ENABLED,
        value: m11nIsEnabled,
        md: 4,
        xs: 12,
        renderValue: (val) => (val ? 'Yes' : 'No'),
      },
      {
        label: STRINGS.DATE_ENABLED,
        value: m11nIsEnabledUpdated,
        md: 4,
        xs: 12,
        renderValue: (val) => dateToMMDoYYYY(val),
      },
      {
        label: STRINGS.CURRENT_STATE,
        value: getEpisodeProcessingStatus(m11nProcessingState).label,
        md: 4,
        xs: 12,
      },
    ],
    [m11nIsEnabled, m11nIsEnabledUpdated, m11nProcessingState]
  );

  const lastMarbylizationInfo = useMemo(
    () => [
      {
        label: STRINGS.MODEL,
        value: lastM11nModelUsed,
        md: 4,
        xs: 12,
      },
      {
        label: STRINGS.DATE_PROCESSED,
        value: lastM11nDate ? dateToMMDoYYYY(lastM11nDate) : STRINGS.PENDING,
        md: 4,
        xs: 12,
      },
      {
        label: STRINGS.RESULT,
        value: lastM11nResult,
        md: 4,
        xs: 12,
      },
      {
        label: STRINGS.VERSION,
        value: lastM11nModelVersion,
        md: 4,
        xs: 12,
      },
      {
        label: STRINGS.MARBYLS,
        value: lastM11nMarbylsCreated,
        md: 4,
        xs: 12,
      },
    ],
    [
      lastM11nDate,
      lastM11nModelUsed,
      lastM11nResult,
      lastM11nModelVersion,
      lastM11nMarbylsCreated,
    ]
  );

  const handleClickAction = useCallback(
    (type) => () => {
      onClickAction(type, type === ACTIVATION ? !isActivated : !m11nIsEnabled);
    },
    [isActivated, m11nIsEnabled, onClickAction]
  );

  const redirectToTranscript = useCallback(
    () => history.push(`${url}${MPC_ROUTES.TRANSCRIPT_VIEW}`),
    [history]
  );

  const renderRssData = useCallback(() => {
    return (
      permissions[EPISODE_VIEW_ACTIONS.VIEW_RSS_SOURCED_DATA] && (
        <>
          <StyledHeaderSection variant="h6">
            {STRINGS.RSS_INFORMATION}
          </StyledHeaderSection>

          <MetaInfo items={metaPodcastInfo} />
        </>
      )
    );
  }, [metaPodcastInfo, permissions]);

  const renderStatusData = useCallback(() => {
    return (
      permissions[EPISODE_VIEW_ACTIONS.VIEW_PROCESS_STATUS_DATA] && (
        <>
          <StyledHeaderSection sx={{ mt: 4 }} variant="h6">
            {STRINGS.EPISODE_STATUS}
          </StyledHeaderSection>

          <MetaInfo items={episodeStatusInfo} />
        </>
      )
    );
  }, [episodeStatusInfo, permissions]);

  const renderM11nData = useCallback(() => {
    return (
      permissions[EPISODE_VIEW_ACTIONS.VIEW_M11N_DATA] && (
        <>
          <StyledHeaderSection sx={{ mt: 4 }} variant="h6">
            {STRINGS.MARBYLIZATION_STATUS}
          </StyledHeaderSection>

          <MetaInfo items={marbylizationInfo} />
        </>
      )
    );
  }, [marbylizationInfo, permissions]);

  const renderMetricsHistoricalData = useCallback(() => {
    return (
      permissions[EPISODE_VIEW_ACTIONS.VIEW_METRICS_HISTORICAL_DATA] && (
        <>
          <StyledHeaderSection sx={{ mt: 4 }} variant="h6">
            {STRINGS.LAST_MARBYLIZATION}
          </StyledHeaderSection>

          <MetaInfo items={lastMarbylizationInfo} />
        </>
      )
    );
  }, [lastMarbylizationInfo, permissions]);

  const renderToggleStatusButton = useCallback(() => {
    return (
      permissions[EPISODE_VIEW_ACTIONS.DEACTIVATE_REACTIVATE_EPISODE] && (
        <Button
          sx={{ mr: 2 }}
          variant="outlined"
          color={isActivated ? 'error' : 'success'}
          onClick={handleClickAction(ACTIVATION)}
        >
          {isActivated ? STRINGS.DEACTIVATE_EPISODE : STRINGS.ACTIVATE_EPISODE}
        </Button>
      )
    );
  }, [permissions, isActivated, handleClickAction]);

  const renderToggleM11nButton = useCallback(() => {
    return (
      permissions[EPISODE_VIEW_ACTIONS.ENABLE_DISABLE_M11N] && (
        <Button
          onClick={handleClickAction(MARBYLIZATION)}
          variant="outlined"
          color={m11nIsEnabled ? 'error' : 'success'}
          disabled={m11nAutoType !== M11N_AUTO_TYPES.MANUAL || !isActivated}
        >
          {m11nIsEnabled ? STRINGS.DISABLE_MARBYLIZATION : STRINGS.ENABLE_MARBYLIZATION}
        </Button>
      )
    );
  }, [permissions, isActivated, m11nIsEnabled, m11nAutoType, handleClickAction]);

  const renderViewTranscriptButton = useCallback(
    () => (
      <Button onClick={redirectToTranscript} variant="outlined" color="success">
        {STRINGS.VIEW_TRANSCRIPT}
      </Button>
    ),
    []
  );

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <Box sx={{ display: 'flex', width: '50%', justifyContent: 'flex-start' }}>
          {renderViewTranscriptButton()}
        </Box>
        <Box sx={{ display: 'flex', width: '50%', justifyContent: 'flex-end' }}>
          {renderToggleStatusButton()}
          {renderToggleM11nButton()}
        </Box>
      </Box>

      {false && renderRssData()}
      {false && renderStatusData()}
      {false && renderM11nData()}
      {false && renderMetricsHistoricalData()}
    </>
  );
};

export default EpisodeDetail;
