import { useState, useEffect, useCallback } from 'react';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { SkeletonLoading } from '@components/index';
import { StyledContainer } from '@shared/styledLandingPages';
import { LandingPagesContainer } from '@components/containers/index';
import { requestAPIGraphQL } from '@services/appSyncAPI';
import useNotifications from 'hooks/useNotifications';
import useQuery from 'hooks/useQuery';
import { STRINGS } from 'constants/strings';
import { FONT_FAMILIES, HEX_COLORS } from 'constants/stylesVariables';

const CancelConfirmDeleteAccount = ({ deleteMutation, textMessage, action }) => {
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState('');
  const { showErrorMessages } = useNotifications();
  const query = useQuery();

  const deleteAction = useCallback(() => {
    const token = query?.get('request');
    let mainMessage = '';

    if (token) {
      requestAPIGraphQL(deleteMutation, { token }, { authMode: 'API_KEY' })
        .then(({ data }) => {
          if (data?.actionResponse) {
            mainMessage = textMessage;
          }
        })
        .catch(({ errors }) => {
          mainMessage =
            STRINGS.WE_ENCOUNTERED_AN_ERROR_WITH_YOUR_REQUEST_PLEASE_TRY_TAPPING_THE_LINK;

          showErrorMessages(
            errors,
            `There was an error trying to ${action} delete the account`
          );
        })
        .finally(() => {
          setMessage(mainMessage);
          setLoading(false);
        });
    } else {
      setMessage(
        STRINGS.WE_ENCOUNTERED_AN_ERROR_WITH_YOUR_REQUEST_PLEASE_TRY_TAPPING_THE_LINK
      );
    }
  }, []);

  useEffect(() => {
    deleteAction();
  }, []);

  if (loading) {
    return <SkeletonLoading />;
  }

  return (
    <StyledContainer>
      <LandingPagesContainer spacerSize={0} showPartners={false} imgBackground="">
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            height: '100%',
            justifyContent: 'center',
          }}
        >
          <Typography
            sx={{
              textAlign: 'center',
              color: HEX_COLORS.white,
              fontSize: '40px',
              fontWeight: 900,
              fontFamily: FONT_FAMILIES.spaceGroteskMedium,
              lineHeight: '48.72px',
            }}
            variant="body1"
          >
            {message}
          </Typography>
        </Box>
      </LandingPagesContainer>
    </StyledContainer>
  );
};

CancelConfirmDeleteAccount.propTypes = {
  deleteMutation: PropTypes.string.isRequired,
  textMessage: PropTypes.string.isRequired,
  action: PropTypes.oneOf(['delete', 'confirm']),
};

export default CancelConfirmDeleteAccount;
