import PropTypes from 'prop-types';
import { Box, Card as CardMUI, CardActions, CardContent } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import RedoIcon from '@mui/icons-material/Redo';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import {
  StyledMarbylText,
  StyledEpisodeDetails,
  StyledEMarbylDetails,
  StyledChip,
} from './styles';
import { HighlightedText } from '@components/index';

const Card = ({
  PK,
  showPK,
  episodePK,
  marbylText,
  marbylViews,
  marbylShares,
  creatorUserName,
  showName,
  episodeName,
  marbylStartTime,
  onPress,
  highlight,
  networkPK,
  podcasterPK,
}) => {
  const theme = useTheme();
  const userName = creatorUserName === 'ai' ? 'Marbyl-AI' : creatorUserName;

  const limitCharacters = (text) => {
    if (text.length > 23) return `${text.substring(0, 22)}...`;

    return text;
  };

  const getStartTime = () => {
    const splittedData = marbylStartTime.split(':');
    const formattedData = `${splittedData[1]}m${splittedData[2].substring(
      0,
      splittedData[2].indexOf('.')
    )}s`;

    return formattedData;
  };

  const handleOnClick = () => onPress(networkPK, podcasterPK, showPK, episodePK, PK);

  return (
    <CardMUI
      sx={{
        borderRadius: '10px',
        boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.05), 0px 2px 7px rgba(0, 0, 0, 0.09)',
        border: '1px solid #CFCFCF',
        padding: theme.spacing(2, 2, 1, 2),
      }}
      variant="outlined"
      onClick={handleOnClick}
    >
      <CardActions
        disableSpacing
        sx={{
          padding: 0,
        }}
      >
        <StyledEpisodeDetails>
          {`${limitCharacters(showName)} : ${limitCharacters(
            episodeName
          )} : ${getStartTime()}`}
        </StyledEpisodeDetails>
      </CardActions>

      <CardActions
        disableSpacing
        sx={{
          padding: theme.spacing(1, 0),
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            padding: 0,
          }}
        >
          <StyledEMarbylDetails>{marbylViews}</StyledEMarbylDetails>
          <RemoveRedEyeOutlinedIcon
            sx={{ height: '0.7em', width: '0.7em', ml: theme.spacing(0.5) }}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            margin: theme.spacing(0, 1.5),
          }}
        >
          <StyledEMarbylDetails>{marbylShares}</StyledEMarbylDetails>
          <RedoIcon sx={{ height: '0.7em', width: '0.7em', ml: theme.spacing(0.5) }} />
        </Box>
        <StyledChip label={userName} />
      </CardActions>

      <CardContent
        sx={{
          height: 90,
          minHeight: 90,
          maxHeight: 90,
          padding: 0,
        }}
      >
        <StyledMarbylText>
          <HighlightedText highlight={highlight} text={marbylText} color={'#72FCD6'} />
        </StyledMarbylText>
      </CardContent>
    </CardMUI>
  );
};

Card.propTypes = {
  marbylText: PropTypes.string.isRequired,
  marbylViews: PropTypes.number.isRequired,
  marbylShares: PropTypes.number.isRequired,
  creatorUserName: PropTypes.string.isRequired,
  onPress: PropTypes.func,
};

export default Card;
