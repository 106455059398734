import React, { memo } from 'react';

const HighlightedText = memo(({ highlight, text = '', color }) =>
  highlight === null || highlight === undefined || highlight.length === 0
    ? text ?? ''
    : JSON.parse(highlight).map(({ type, text: hText }, i, arr) => (
        <React.Fragment key={i}>
          {arr[i - 1] && arr[i - 1].type === 'highlight' && type === 'highlight'
            ? ' '
            : ''}
          <span style={type === 'highlight' ? { backgroundColor: `${color}` } : {}}>
            {hText ?? ''}
          </span>
        </React.Fragment>
      ))
);

export default HighlightedText;
