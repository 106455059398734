import { DynamicForm } from '@components/index';
import { getActiveStatusValue } from '@utils/getActiveStatus';
import { STATUS_ACTIVE, STATUS_INACTIVE, STATUS_LABELS } from 'constants/activeStatus';
import { FIELD_TYPES } from 'constants/dynamicFormTypes';
import { AGREEMENT_TYPES, AGREEMENT_TYPES_LABELS } from 'constants/podcaster';
import { STRINGS } from 'constants/strings';
import React, { useMemo } from 'react';

const EditNetwork = ({
  onCancel,
  PK,
  name,
  description,
  contentAgreementType,
  isActivated,
  onSubmit,
}) => {
  const fields = useMemo(() => {
    return [
      {
        type: FIELD_TYPES.TEXT,
        label: 'Name',
        name: 'name',
        required: true,
        color: 'secondary',
        md: 12,
        xs: 12,
      },
      {
        type: FIELD_TYPES.TEXT,
        label: 'Description',
        name: 'description',
        color: 'secondary',
        md: 12,
        xs: 12,
      },
      {
        type: FIELD_TYPES.TITLE,
        label: 'Admin Settings',
      },
      {
        type: FIELD_TYPES.SELECT,
        label: 'Status',
        name: 'isActivated',
        items: [STATUS_ACTIVE, STATUS_INACTIVE].map((_status) => ({
          value: _status,
          label: STATUS_LABELS[_status],
        })),
        md: 6,
        color: 'secondary',
      },
      {
        type: FIELD_TYPES.SELECT,
        label: 'Agreement',
        name: 'contentAgreementType',
        items: Object.values(AGREEMENT_TYPES).map((_key) => ({
          value: _key,
          label: AGREEMENT_TYPES_LABELS[_key],
        })),
        md: 6,
        color: 'secondary',
      },
    ];
  }, []);

  const initialValues = useMemo(() => {
    return {
      name,
      description,
      isActivated: getActiveStatusValue(isActivated),
      contentAgreementType,
    };
  }, [name, description, isActivated, contentAgreementType]);

  const handleSubmit = (values) => onSubmit({ PK, ...values });

  return (
    <DynamicForm
      formHeader={`${STRINGS.NETWORK_DETAILS}:`}
      formHeaderVariant={'h5'}
      initialValues={initialValues}
      fields={fields}
      onCancel={onCancel}
      onSubmit={handleSubmit}
    />
  );
};

export default EditNetwork;
