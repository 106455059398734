import styled from '@mui/styled-engine';
import { Box } from '@mui/material';
import { DESKTOP_BREAKPOINT } from 'constants/breakpoints';
import { HEX_COLORS, FONT_FAMILIES } from 'constants/stylesVariables';

export const StyledFooter = styled('footer')(
  () => `
    margin: 0 auto;
    width: 93.75%;
    z-index: 999;

    @media only screen and ${DESKTOP_BREAKPOINT} {
      width: 86.055%;
    }
  `
);

export const StyledPartnersWrapper = styled(Box)(
  () => `
    display: flex;
    align-items: center;
    flex-direction: column;
  `
);

export const StyledPartnersLabel = styled('span')(
  () => `
    color: ${HEX_COLORS.white};
    font-family: ${FONT_FAMILIES.spaceGroteskBold};
    font-size: 18px;
    font-weight: 900;
    line-height: 21px;
    text-align: center;
  `
);

export const StyledSocialMediaLabel = styled('span')(
  () => `
    color: ${HEX_COLORS.white};
    font-family: ${FONT_FAMILIES.spaceGroteskRegular};
    font-size: 17px;
    font-weight: 500;
    line-height: 17px;
    text-align: center;
  `
);

export const StyledMailToA = styled('a')(
  () => `
  &:hover {
    text-decoration: none;
  }
  `
);

export const StyledPartnersBox = styled(Box)(
  () => `
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;

    img:last-of-type {
      margin-top: 38px;
    }


    @media only screen and ${DESKTOP_BREAKPOINT} {
      align-items: center;
      flex-direction: row;
      justify-content: center;

      img:last-of-type {
        margin-top: 0;
      }
    }
  `
);

export const StyledFooterCopyright = styled('div')(
  () => `
    color: ${HEX_COLORS.white};
    font-family: ${FONT_FAMILIES.spaceGroteskRegular};
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 40px;
    text-align: center;

    .__bolder {
      color: inherit;
      font-family: ${FONT_FAMILIES.spaceGroteskMedium};
    }

    .__link {
      cursor: pointer;
      color: inherit;

      &:hover {
        text-decoration: underline;
      }
    }
  `
);

export const StyledSocialMediaWrapper = styled(Box)(
  () => `
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-bottom: 52px
  `
);

export const StyledSocialMediaImg = styled('img')(
  () => `
  width: 17px;
  height: 21px;
  margin-right: 26px;
  `
);
