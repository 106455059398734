import { useState, useEffect } from 'react';
import { Modal } from '@mui/material';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { AuthState } from '@aws-amplify/ui-components';
import { useAuth } from 'contexts/AuthContext';
import {
  ForgotPasswordContainer,
  SignInContainer,
  SignUpContainer,
} from '@components/containers/index';
import { MessageIcon } from '@components/index';
import useQuery from 'hooks/useQuery';
import { record } from '@utils/analytics';
import { cleanPhoneNumber } from '@utils/phone';
import {
  //   TAP_CLAIM_PODCAST,
  //   TAP_LOGIN,
  //   TAP_MY_ACCOUNT,
  TAP_SIGN_UP_CONFIRMATION,
} from 'constants/analytics';
import { SIGN_IN_REDIRECT_KEY } from 'constants/localStorageKeys';
import { MAIN_ROUTES } from 'constants/routing';

const FORM_TYPES = {
  SIGNUP: 'signup',
  LOGIN: 'login',
  FORGOT_PASSWORD: 'forgot-password',
};

const UnauthenticatedForms = ({ isOpen, onClose, searchParams }) => {
  const [params, setParams] = useState('');
  const [formType, setFormType] = useState(FORM_TYPES.SIGNUP);
  const query = useQuery();
  const { authState, loading, login, register } = useAuth();
  const history = useHistory();

  useEffect(() => {
    if (location.search) {
      setParams(location.search);

      history.replace({
        search: '',
      });
    }
  }, []);

  const onSubmit = (values) => {
    record(TAP_SIGN_UP_CONFIRMATION);
    const { email, password, firstName, lastName, phoneCode, phoneNumber, verification } =
      values;

    const fullPhone = phoneNumber ? `${phoneCode}${cleanPhoneNumber(phoneNumber)}` : '';

    register(email, password, firstName, lastName, fullPhone, verification)
      .then(() => {
        history.push(MAIN_ROUTES.CONFIRM_SIGNUP);
      })
      .catch((err) => {
        toast.error(
          <MessageIcon text={err?.message || ''} icon="none" typographyVariant="body1" />
        );
      });
  };

  const handleLogin = (username, password) => {
    login(username, password).catch((err) => {
      toast.error(
        <MessageIcon text={err?.message || ''} icon="none" typographyVariant="body1" />
      );
    });
  };

  useEffect(() => {
    if (authState === AuthState.SignedIn) {
      const redirectTo = localStorage.getItem(SIGN_IN_REDIRECT_KEY);

      history.push({
        pathname: redirectTo || MAIN_ROUTES.APP,
        search: params,
      });
    } else if (authState === AuthState.ConfirmSignUp) {
      history.push(MAIN_ROUTES.CONFIRM_SIGNUP);
    }
  }, [authState, query, history]);

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <>
        {formType === FORM_TYPES.SIGNUP && (
          <SignUpContainer
            onSubmit={onSubmit}
            loading={loading}
            onClose={onClose}
            changeFormType={() => setFormType(FORM_TYPES.LOGIN)}
            searchParams={searchParams}
          />
        )}
        {formType === FORM_TYPES.LOGIN && (
          <SignInContainer
            onLogin={handleLogin}
            loading={loading}
            changeFormType={setFormType}
            onClose={onClose}
          />
        )}
        {formType === FORM_TYPES.FORGOT_PASSWORD && (
          <ForgotPasswordContainer changeFormType={setFormType} onClose={onClose} />
        )}
      </>
    </Modal>
  );
};

export default UnauthenticatedForms;
