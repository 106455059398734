import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { cleanPhoneNumber } from '@utils/phone';
import { requestAPIGraphQL } from '@services/appSyncAPI';
import {
  requestPodcastVerification,
  submitAddPodcastFlow,
  verifyPodcast,
} from '@graphql/mutations/ipsso';
import { uuid } from '@utils/uuid';
import { PODCASTER } from 'constants/idPrefixes';
import { getPodcasterName } from '@utils/getPodcasterName';
import { getAvailableInGeosPayload } from '@utils/getAvailableInGeos';
import _ from 'lodash';
import { removePrefix } from '@utils/string';
import { rssFeedExists } from '@graphql/queries/ipsso';

const AddYourPodcastContext = createContext({});

export const useAddYourPodcast = () => {
  return useContext(AddYourPodcastContext);
};

const AddYourPodcastContextProvider = ({ children, isOnboarding = false }) => {
  const [podcast, setPodcast] = useState(null);
  const [podcaster, setPodcaster] = useState({
    fullName: '',
    PK: null,
    id: null,
    companyName: '',
    owners: [],
    optedIntoTospp: false,
  });
  const [regions, setRegions] = useState([]);
  const [hosts, setHosts] = useState({});
  const [owners, setOwners] = useState([]);
  const [initPodcaster, setInitPodcaster] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [finish, setFinish] = useState(false);

  const resetDefaultState = () => {
    setPodcast(null);
    setRegions([]);
    setFinish(false);
  };

  const addOwner = () => {
    let _owners = _.cloneDeep(owners);

    _owners.push({
      id: `${PODCASTER}${uuid()}`,
      name: '',
      email: '',
      phoneCode: '+1',
      phoneNumber: '',
      ownerIsAccountUser: false,
      isNew: true,
    });

    setOwners(_owners);
  };

  const setOwner = (index, payload) => {
    const { field, value } = payload;
    let _owners = _.cloneDeep(owners);
    _owners[index][field] = value;

    setOwners(_owners);
  };

  const removeOwner = (index) => {
    let _owners = _.cloneDeep(owners);
    _owners.splice(index, 1);

    setOwners(_owners);
  };

  const setCompanyName = (value) =>
    setPodcaster((prev) => ({ ...prev, companyName: value }));

  const toggleHost = (id, value) => setHosts((prev) => ({ ...prev, [id]: value }));

  const getPodcasterPayload = () => {
    const { name, companyName, PK, optedIntoTospp } = podcaster;

    let newOwners = [];
    for (let index = 0; index < owners.length; index++) {
      const owner = owners[index];

      if (owner.name && owner.email) {
        const cleanedPhone = cleanPhoneNumber(owner.phoneNumber);

        newOwners.push({
          id: owner.id,
          name: owner.name,
          email: owner.email,
          phoneCode: owner.phoneCode || '+1',
          phoneNumber: cleanedPhone,
          ownerIsAccountUser: !!owner.ownerIsAccountUser,
        });
      }
    }

    return {
      podcasterId: PK,
      name: name ?? getPodcasterName(newOwners, companyName),
      availableInGeos: getAvailableInGeosPayload(regions),
      owningCompanyName: companyName,
      owners: newOwners,
      optedIntoTospp: isOnboarding || optedIntoTospp,
    };
  };

  const getPodcastPayload = () => {
    const { feedUrl: rssFeedLink, itunesId } = podcast;
    const { PK: podcasterId, podcastNetworkId } = podcaster;

    let hostsIds = [];

    for (const key in hosts) {
      if (hosts[key]) {
        hostsIds = [...hostsIds, key];
      }
    }

    return {
      podcasterId,
      input: {
        title: podcast.title,
        description: podcast.description || '',
        email: podcast?.itunes?.owner?.email || '',
        categories: podcast?.itunes?.categories || [],
        rssFeedLink,
        itunesId,
        availableInGeos: getAvailableInGeosPayload(regions),
        preferredSubscribeLink: null,
        preferredSubscribeLinkType: null,
        acknowledgment: false,
        host: hostsIds,
        podcastNetworkId,
      },
    };
  };

  const submitPodcastOwners = () =>
    new Promise((resolve, reject) => {
      const podcastPayload = getPodcastPayload();
      const podcasterPayload = getPodcasterPayload();

      requestAPIGraphQL(submitAddPodcastFlow, {
        podcasterId: podcastPayload.podcasterId,
        podcastInput: podcastPayload.input,
        podcasterInput: podcasterPayload,
      })
        .then(({ data: { createPodcast, updatePodcaster } }) => {
          setFinish(true);
          resolve({ createPodcast, updatePodcaster });
        })
        .catch((reason) => reject(reason));
    });

  const checkRSSExists = useCallback(async (rssFeed) => {
    try {
      const {
        data: { rssFeedExists: rssFeedExistsRes },
      } = await requestAPIGraphQL(rssFeedExists, { feedUrl: rssFeed });

      return rssFeedExistsRes;
    } catch (error) {
      throw error;
    }
  }, []);

  const requestVerificationCode = useCallback(async (payload) => {
    try {
      await requestAPIGraphQL(requestPodcastVerification, { input: payload });
    } catch (error) {
      throw error;
    }
  }, []);

  const verifyPodcastCode = useCallback(async (payload) => {
    try {
      const {
        data: { verifyPodcast: verifyResponse },
      } = await requestAPIGraphQL(verifyPodcast, {
        input: payload,
      });

      return verifyResponse;
    } catch (error) {
      throw error;
    }
  }, []);

  useEffect(() => {
    if (!initPodcaster || !initPodcaster.PK) return;

    const { owners, ..._podcaster } = initPodcaster;

    const _hosts = owners.reduce(
      (obj, _own) => ({
        ...obj,
        [_own.id]: removePrefix(_own.id) === removePrefix(_podcaster.PK),
      }),
      {}
    );
    setHosts(_hosts);
    setPodcaster(_podcaster);
    setOwners(owners);
  }, [initPodcaster]);

  return (
    <AddYourPodcastContext.Provider
      value={{
        podcaster: {
          owners,
          ...podcaster,
        },
        hosts,
        podcastState: [podcast, setPodcast],
        regionsState: [regions, setRegions],
        resetDefaultState,
        addOwner,
        setOwner,
        removeOwner,
        setCompanyName,
        toggleHost,
        submitPodcastOwners,
        checkRSSExists,
        requestVerificationCode,
        verifyPodcastCode,
        isOnboarding,
        isModalOpen,
        setModalOpen,
        setInitPodcaster,
        finish,
        setFinish,
      }}
    >
      {children}
    </AddYourPodcastContext.Provider>
  );
};

AddYourPodcastContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  isOnboarding: PropTypes.bool,
};

AddYourPodcastContextProvider.defaultProps = {
  isOnboarding: false,
};

export default AddYourPodcastContextProvider;
