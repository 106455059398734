import React, { useState, useEffect } from 'react';
import { TextField, Grid, MenuItem } from '@mui/material';
import { UnauthenticatedFormContainer } from '@components/containers';
import { STRINGS } from 'constants/strings';
import { useForm, Controller } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { DIAL_CODES, COUNTRIES_BY_CODE } from 'constants/countriesList';
import { StyledEmailTextField } from './SignUp.styles';
import { TAP_SIGN_IN_LINK } from 'constants/analytics';

const SignUp = ({ onSubmit, loading, onClose, changeFormType, searchParams }) => {
  const [disableEmail, setDisableEmail] = useState(false);
  const { control, setValue, handleSubmit } = useForm({
    defaultValues: {
      email: '',
      password: '',
      firstName: '',
      lastName: '',
      phoneCode: COUNTRIES_BY_CODE.US.Dial,
      phoneNumber: '',
      verification: null,
    },
  });

  useEffect(() => {
    if (!!searchParams) {
      const params = new URLSearchParams(searchParams);

      const firstName = params?.get('first_name') || '';
      if (!!firstName) {
        setValue('firstName', firstName);
        setValue('lastName', params?.get('last_name') || '');
        setValue('email', params?.get('email') || '');
        setValue('verification', params?.get('verification') || '');
        setDisableEmail(true);
      } else {
        changeFormType?.();
      }
    }
  }, [searchParams]);

  return (
    <UnauthenticatedFormContainer
      titleMessage={STRINGS.SIGN_UP_MESSAGE}
      title={STRINGS.SPEAK_AND_THEY_SHALL_FIND}
      onClose={onClose}
      onSubmit={handleSubmit(onSubmit)}
      submitBtnLabel={STRINGS.SIGN_UP.toLocaleLowerCase()}
      loading={loading}
      contentContainerSpacing={2}
      footerText={STRINGS.ALREADY_HAVE_ACCOUNT}
      mainLinkText={STRINGS.SIGN_IN}
      mainLinkRoute={changeFormType}
      mainLinkRecord={TAP_SIGN_IN_LINK}
      cancelBtnLabel={STRINGS.CANCEL.toLocaleLowerCase()}
      onCancel={onClose}
      maxWidth={800}
    >
      <Grid md={6} xs={12} item>
        <Controller
          name="firstName"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              variant="standard"
              margin="normal"
              placeholder="Enter your first name"
              label="First Name"
              fullWidth
              required
              focused
              sx={{ '.MuiInput-input': { color: 'black' } }}
            />
          )}
        />
      </Grid>

      <Grid md={6} xs={12} item>
        <Controller
          name="lastName"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              variant="standard"
              margin="normal"
              placeholder="Enter your last name"
              label="Last Name"
              fullWidth
              required
              focused
              sx={{ '.MuiInput-input': { color: 'black' } }}
            />
          )}
        />
      </Grid>

      <Grid md={12} xs={12} item>
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <StyledEmailTextField
              {...field}
              variant="standard"
              margin="normal"
              placeholder="Enter your email"
              label="Email Address"
              fullWidth
              required
              focused
              disabled={disableEmail}
              sx={{
                '.MuiInput-input': { color: 'black' },
                '& .MuiInputBase-input.Mui-disabled': {
                  WebkitTextFillColor: '#000000',
                },
              }}
              InputLabelProps={{
                classes: {
                  disabled: '__label',
                },
              }}
              InputProps={{
                classes: {
                  disabled: '__input',
                },
              }}
            />
          )}
        />
      </Grid>

      <Grid md={2} xs={12} item>
        <Controller
          name="phoneCode"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              id="outlined-select-currency"
              select
              label="Country code"
              variant="standard"
              margin="normal"
              sx={{ width: 75, '.MuiInput-input': { color: 'black' } }}
              focused
            >
              {DIAL_CODES.map((country) => (
                <MenuItem key={country.key} value={country.label}>
                  {country.label}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
      </Grid>

      <Grid md={10} xs={12} item>
        <Controller
          name="phoneNumber"
          control={control}
          render={({ field }) => (
            <InputMask {...field} mask="(999) 999-9999" alwaysShowMask={false}>
              {(inputProps) => (
                <TextField
                  {...inputProps}
                  variant="standard"
                  margin="normal"
                  placeholder="Enter your mobile phone"
                  label="Mobile phone / SMS"
                  type="tel"
                  fullWidth
                  focused
                  sx={{ '.MuiInput-input': { color: 'black' } }}
                />
              )}
            </InputMask>
          )}
        />
      </Grid>

      <Grid md={12} xs={12} item>
        <Controller
          name="password"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              variant="standard"
              margin="normal"
              placeholder="Enter your password"
              label="Password"
              type="password"
              fullWidth
              required
              focused
              sx={{ '.MuiInput-input': { color: 'black' } }}
            />
          )}
        />
      </Grid>
    </UnauthenticatedFormContainer>
  );
};

export default SignUp;
