import React, { useState } from 'react';

import { STRINGS } from 'constants/strings';
import { useAddYourPodcast } from 'contexts/AddYourPodcastContext';

import {
  Typography,
  Paper,
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { PodcastArtTitle } from '@components/index';

import CheckIcon from '@mui/icons-material/Check';
import { toast } from 'react-toastify';
import { StyledInvertedButton, StyledInvertedTableCell } from '@shared/styledInputs';
import { formatPhoneNumber } from '@utils/phone';

import {
  TAP_PREVIOUS_FROM_REVIEW_AND_SUBMIT,
  TAP_SUBMIT_FROM_REVIEW_AND_SUBMIT,
} from 'constants/analytics';
import { pageNames } from 'constants/ipsso';
import useLoadModalSubpage from 'hooks/useLoadModalSubpage';
import useRecordAnalyticsPodcast from 'hooks/useRecordAnalyticsPodcast';

const ReviewSubmit = ({ renderBackBtn }) => {
  const [loading, setLoading] = useState(false);

  const {
    podcaster: { owners, companyName: company },
    podcastState: [podcast],
    regionsState: [regions],
    submitPodcastOwners,
    hosts,
  } = useAddYourPodcast();

  const { recordAnalyticsPodcast } = useRecordAnalyticsPodcast();

  const handleSubmitPodcast = () => {
    recordAnalyticsPodcast(TAP_SUBMIT_FROM_REVIEW_AND_SUBMIT);

    toast.dismiss();
    setLoading(true);

    submitPodcastOwners()
      .then(() => {
        setLoading(true);
      })
      .catch((e) => {
        setLoading(false);

        const [{ message }] = e.errors;
        toast.error(message);
      });
  };

  useLoadModalSubpage(pageNames.REVIEW_SUBMIT);

  return (
    <>
      <PodcastArtTitle podcast={podcast} />
      <Typography sx={{ mb: 2, mt: 2 }} variant="h6">
        {STRINGS.DO_WE_HAVE_EVERYTHING_RIGHT}
      </Typography>

      <Typography variant="subtitle1">
        {STRINGS.REGIONS_AVAILABLE}:{' '}
        {regions.length === 0 ? STRINGS.WORLDWIDE : regions.join(',')}
      </Typography>

      {company && (
        <Typography variant="subtitle1">
          {STRINGS.COMPANY_NAME}: {company}
        </Typography>
      )}

      <TableContainer sx={{ mt: 1, bgcolor: 'primary.main' }} component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledInvertedTableCell>{STRINGS.OWNERS}</StyledInvertedTableCell>
              <StyledInvertedTableCell align="center">
                {STRINGS.HOST_CO_HOST_PODCAST}
              </StyledInvertedTableCell>
              <StyledInvertedTableCell align="left">
                {STRINGS.EMAIL}
              </StyledInvertedTableCell>
              <StyledInvertedTableCell align="left">
                {STRINGS.MOBILE_PHONE_SMS}
              </StyledInvertedTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {owners.map((row) => {
              if (!row.name) return null;

              return (
                <TableRow
                  key={row.email}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <StyledInvertedTableCell component="th">
                    {row.name}
                  </StyledInvertedTableCell>
                  <StyledInvertedTableCell align="center">
                    {hosts[row.id] ? <CheckIcon /> : null}
                  </StyledInvertedTableCell>
                  <StyledInvertedTableCell align="left">
                    {row.email}
                  </StyledInvertedTableCell>
                  <StyledInvertedTableCell align="left">
                    {row.phoneNumber
                      ? `${row.phoneCode} ${formatPhoneNumber(row.phoneNumber)}`
                      : ''}
                  </StyledInvertedTableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <Box sx={{ mt: 2 }}>
        {renderBackBtn(TAP_PREVIOUS_FROM_REVIEW_AND_SUBMIT)}

        {loading ? (
          <CircularProgress />
        ) : (
          <StyledInvertedButton
            onClick={handleSubmitPodcast}
            color="error"
            variant="contained"
          >
            {STRINGS.SUBMIT}
          </StyledInvertedButton>
        )}
      </Box>
    </>
  );
};

export default ReviewSubmit;
