import React, { useState, forwardRef } from 'react';
import { Checkbox } from '@mui/material';

const CheckAll = forwardRef((props, ref) => {
  const [checked, setChecked] = useState(false);

  const handleOnChange = () => {
    if (checked) {
      ref?.current?.api?.deselectAll();
    } else {
      ref?.current?.api?.forEachNode((node) => {
        node.setSelected(true);
      });
    }
    setChecked(!checked);
  };

  return (
    <Checkbox
      color="primaryButton"
      checked={checked}
      onChange={handleOnChange}
      size={'small'}
      sx={{ marginLeft: -1.3 }}
      {...props}
    />
  );
});

export default CheckAll;
