import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';

import { InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useMPC } from 'contexts/MPCContext';

import { StyledSearchBox, StyledIconButton, StyledCloseIcon } from './styled';

const SearchBox = ({ text, setText, onSearch, placeholder, onCleanInput }) => {
  const {
    selectedAccount: { accountId, entityId },
  } = useMPC();

  const handleOnChangeText = (e) => {
    setText(e.target.value);
  };

  const handleOnCleanInput = () => {
    setText('');
  };

  const debouncedResults = useMemo(
    () => debounce((text) => onSearch(text), 400),
    [accountId, entityId]
  );

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });

  useEffect(() => {
    if (text.length >= 3) {
      debouncedResults(text);
    }
    if (text.length === 0) {
      onCleanInput();
    }
  }, [text]);

  return (
    <StyledSearchBox
      startAdornment={
        <InputAdornment position="start">
          <SearchIcon color={'primaryButton'} />
        </InputAdornment>
      }
      endAdornment={
        <InputAdornment position="start">
          {text.length >= 1 && (
            <StyledIconButton onClick={handleOnCleanInput}>
              <StyledCloseIcon color={'primaryButton'} />
            </StyledIconButton>
          )}
        </InputAdornment>
      }
      onChange={handleOnChangeText}
      placeholder={placeholder}
      value={text}
    />
  );
};

SearchBox.propTypes = {
  placeholder: PropTypes.string,
  onSearch: PropTypes.func,
  onCleanInput: PropTypes.func,
  text: PropTypes.string,
  setText: PropTypes.func,
};

SearchBox.defaultProps = {
  placeholder: '',
  onSearch: () => {},
  onCleanInput: () => {},
  text: '',
  setText: () => {},
};

export default SearchBox;
