import React, { useCallback, useMemo, useRef } from 'react';
import { Typography, Button, Box, Grid } from '@mui/material';
import { STRINGS } from 'constants/strings';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { record } from '@utils/analytics';
import { TAP_EDIT_PODCASTER } from 'constants/analytics';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { AgGridReact } from 'ag-grid-react';
import { StyledHeaderSection } from '@shared/styledGeneral';
import { MetaInfo } from '@components/index';
import { AGREEMENT_TYPES_LABELS } from 'constants/podcaster';
import { getActiveStatusValue } from '@utils/getActiveStatus';
import { STATUS_LABELS } from 'constants/activeStatus';
import { useAuth } from 'contexts/AuthContext';
import { PODCASTER_VIEW_ACTIONS } from 'constants/actionsList';

const PodcasterDetail = ({
  id,
  name,
  owners = [],
  owningCompanyName,
  contentAgreementType,
  isActivated,
  description,
  optedIntoTospp,
  onClickEdit = () => null,
}) => {
  const handleEditPodcaster = useCallback(() => {
    onClickEdit();
    record(TAP_EDIT_PODCASTER, { id });
  }, [id, onClickEdit]);

  const { permissions } = useAuth();

  const gridRef = useRef();
  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);

  const renderEditButton = useCallback(() => {
    return permissions[PODCASTER_VIEW_ACTIONS.EDIT_PODCASTER_INFO] ? (
      <Button
        onClick={handleEditPodcaster}
        variant="outlined"
        color="secondary"
        sx={{
          border: '1.5px solid #4623E4',
          textTransform: 'none',
        }}
      >
        {STRINGS.EDIT}
        <EditOutlinedIcon sx={{ ml: 1, fontSize: 18 }} />
      </Button>
    ) : null;
  }, [permissions, handleEditPodcaster]);

  const podcasterDetailsInfo = useMemo(
    () => [
      {
        label: STRINGS.NAME,
        value: name,
        md: 4,
        xs: 12,
      },
      {
        label: STRINGS.DESCRIPTION,
        value: description,
        md: 8,
        xs: 12,
      },
    ],
    [name, description]
  );

  const adminSettingsInfo = useMemo(
    () => [
      {
        label: STRINGS.STATUS,
        value: isActivated,
        md: 4,
        xs: 12,
        renderValue: (val) => STATUS_LABELS[getActiveStatusValue(val)],
      },
      {
        label: STRINGS.AGREEMENT,
        value: AGREEMENT_TYPES_LABELS[contentAgreementType] || '',
        md: 4,
        xs: 12,
      },
      {
        label: STRINGS.OPTED_IN_TO_TOS_PP,
        value: optedIntoTospp,
        md: 4,
        xs: 12,
        renderValue: (val) =>
          val ? <CheckIcon color="success" /> : <CloseIcon color="error" />,
        styles: { marginLeft: 8 },
      },
    ],
    [optedIntoTospp, isActivated, contentAgreementType]
  );

  const columnsDef = useMemo(() => {
    return [
      { field: 'name', headerName: 'Name', minWidth: 300, flex: 1 },
      { field: 'email', headerName: 'Email', width: 400 },
      { field: 'phone', headerName: 'Phone', width: 350 },
    ];
  }, []);

  return (
    <>
      <Grid container sx={{ mb: 2 }}>
        <Grid item xs={10} md={11}>
          <StyledHeaderSection variant="h6">
            {STRINGS.PODCASTER_DETAILS}
          </StyledHeaderSection>
        </Grid>
        <Grid
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            flexDirection: 'row-reverse',
          }}
          item
          xs={2}
          md={1}
        >
          {renderEditButton()}
        </Grid>
      </Grid>

      <MetaInfo items={podcasterDetailsInfo} />

      <StyledHeaderSection sx={{ mb: 2, mt: 4 }} variant="h6">
        {STRINGS.OWNER_INFORMATION}
      </StyledHeaderSection>
      <div className="ag-theme-custom" style={gridStyle}>
        <AgGridReact
          ref={gridRef}
          columnDefs={columnsDef}
          rowData={owners}
          domLayout="autoHeight"
        />
      </div>

      <Box sx={{ mb: 2, mt: 2 }}>
        <Typography sx={{ fontWeight: 'bold' }} variant="subtitle1">
          {STRINGS.COMPANY_NAME}
        </Typography>
        <Typography variant="body1">
          {owningCompanyName ? owningCompanyName : 'N/A'}
        </Typography>
      </Box>

      <StyledHeaderSection sx={{ mt: 4 }} variant="h6">
        {STRINGS.ADMIN_SETTINGS}
      </StyledHeaderSection>

      <MetaInfo items={adminSettingsInfo} />
    </>
  );
};

export default PodcasterDetail;
