import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
import styled from '@mui/styled-engine';
import Footer from '../../footer/index';
import { MainMarbylIcon } from '@components/icons/index';
import Spacer from '@shared/spacer';
import { HEX_COLORS, FONT_FAMILIES } from 'constants/stylesVariables';
import { record } from '@utils/analytics';
import { history } from '@utils/history';
import { useAuth } from 'contexts/AuthContext';
import { STRINGS } from 'constants/strings';
import { TAP_LOGOUT } from 'constants/analytics';
import { MAIN_ROUTES } from 'constants/routing';

const StyledTopContainer = styled('div')(
  ({ theme }) => `
    display: flex;
    background: linear-gradient(288.41deg, ${HEX_COLORS.purple3} 14.04%, ${HEX_COLORS.purple} 35.73%, ${HEX_COLORS.purple2} 46.54%, ${HEX_COLORS.pink} 80.27%);
    flex-direction: column;
    height: 100%;
    overflow: auto;
    position: relative;
    width: 100%;

    &::-webkit-scrollbar { display: none; }

    @media (max-width: ${theme.breakpoints.values.sm}px) {
      padding: 0 16px;
    }
  `
);

const StyledTopBackground = styled('div')(
  ({
    theme,
    imgBackground,
    imgBackgroundDimensions,
    desktopTop,
    mobileTop = '127px',
  }) => `
    position: absolute;
    background-image: url(${imgBackground});
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    ${imgBackgroundDimensions && `height: ${imgBackgroundDimensions.height};`}
    ${imgBackgroundDimensions && `width: ${imgBackgroundDimensions.width};`}
    left: 0;
    right: 0;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    bottom: 0;
    top: 0;

    @media (max-width: ${theme.breakpoints.values.sm}px) {
      height: 433px;
      margin: 0;
      top: ${mobileTop};
      width: auto;
    }

    @media (min-width: ${theme.breakpoints.values.md}px) {
      top: ${desktopTop};
    }
  `
);

const StyledTopTab = styled(Box)(
  ({ theme }) => `
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
  margin-left: 26px;
  margin-right: 26px;
  margin-top: 26px;

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    justify-content: center;
    margin-top: 52px;
  }
`
);

export const StyledTopTabTitle = styled('h1')(
  () => `
  color: white;
  margin: 0;
  text-align: center;
  white-space: pre-wrap;
  font-family: ${FONT_FAMILIES.spaceGroteskMedium};
`
);

const StyledChildrenContainer = styled(Box)(
  () => `
    display: flex;
    flex: 1;
    z-index: 1;
    margin: auto;
  `
);

export const StyledTextButton = styled('span')(
  ({ theme }) => `
    color: ${HEX_COLORS.white};
    cursor: pointer;
    font-family: ${FONT_FAMILIES.spaceGroteskMedium};
    font-size: ${theme.typography.pxToRem(20)};
    padding: ${theme.spacing(1, 1)};

    line-height: 20px;
    transition: opacity 0.2s;
    z-index: 2;

    &:hover {
      opacity: 0.75;
    }

    @media (max-width: ${theme.breakpoints.values.sm}px) {
      position: absolute;
      font-family: ${FONT_FAMILIES.spaceGroteskRegular};
      font-size: ${theme.typography.pxToRem(14)};
      top: ${theme.spacing(1.25)};
      right: ${theme.spacing(1.25)};
    }`
);

const LandingPagesContainer = ({
  children,
  spacerSize,
  showPartners,
  imgBackground,
  imgBackgroundDimensions,
  mobileTop,
  desktopTop,
}) => {
  const { logout, authed } = useAuth();
  const { pathname } = useLocation();

  const handleLogout = () => {
    record(TAP_LOGOUT, { pathname });
    logout();
    // For a smoother transition to the logout disapearing
    setTimeout(() => {
      history.push(MAIN_ROUTES.HOME);
    }, 300);
  };

  return (
    <StyledTopContainer>
      <StyledTopBackground
        imgBackground={imgBackground}
        imgBackgroundDimensions={imgBackgroundDimensions}
        mobileTop={mobileTop}
        desktopTop={desktopTop}
      />
      <StyledTopTab>
        <MainMarbylIcon width={165} />
        {authed && (
          <StyledTextButton onClick={handleLogout}>{STRINGS.LOGOUT}</StyledTextButton>
        )}
      </StyledTopTab>
      <Spacer size={spacerSize} />
      <StyledChildrenContainer>{children}</StyledChildrenContainer>
      <Footer showPartners={showPartners} />
    </StyledTopContainer>
  );
};

LandingPagesContainer.propTypes = {
  children: PropTypes.node.isRequired,
  spacerSize: PropTypes.number.isRequired,
  showPartners: PropTypes.bool,
  imgBackground: PropTypes.string.isRequired,
  imgBackgroundDimensions: PropTypes.object,
  mobileTop: PropTypes.string,
};

LandingPagesContainer.defaultProps = {
  showPartners: true,
  imgBackgroundDimensions: {
    height: 'auto',
    width: 'auto',
  },
};

export default LandingPagesContainer;
