import React from 'react';
import { Grid, Box } from '@mui/material';

import { HighlightedText } from '@components/index';
import { StyledMarbylTextList } from './styles';
import { StyledGridList } from './styles';

const ListItem = (props) => {
  const { columns, isHeader, onPress, showPK, episodePK, PK, networkPK, podcasterPK } =
    props;

  const handleOnClick = () => onPress(networkPK, podcasterPK, showPK, episodePK, PK);

  return (
    <StyledGridList
      container
      columnSpacing={isHeader ? 0 : 2}
      isHeader
      onClick={handleOnClick}
    >
      {columns.map((column, index) => {
        const { headerName, md, sm, xs, display, cellRenderer, val, isHighlight } =
          column;
        return (
          <Grid
            item
            key={index}
            md={md || 4}
            sm={sm || 12}
            xs={xs || 12}
            display={display || {}}
          >
            {isHeader && <Box className="ag-custom-header-cell-label">{headerName}</Box>}
            {!isHeader && !isHighlight && (
              <StyledMarbylTextList>{cellRenderer?.(props[val])}</StyledMarbylTextList>
            )}
            {isHighlight && !isHeader && (
              <StyledMarbylTextList marbylText>
                <HighlightedText
                  highlight={props['highlight']}
                  text={props[val]}
                  color={'#72FCD6'}
                />
              </StyledMarbylTextList>
            )}
          </Grid>
        );
      })}
    </StyledGridList>
  );
};

export default ListItem;
