import React, { useMemo } from 'react';
import { Button, Grid } from '@mui/material';
import { STRINGS } from 'constants/strings';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { StyledHeaderSection } from '@shared/styledGeneral';
import { MetaInfo } from '@components/index';
import { AGREEMENT_TYPES_LABELS } from 'constants/podcaster';
import { getActiveStatusValue } from '@utils/getActiveStatus';
import { STATUS_LABELS } from 'constants/activeStatus';

const NetworkDetail = ({
  name,
  description,
  isActivated,
  contentAgreementType,
  onClickEdit = () => null,
}) => {
  const handleEdit = () => {
    onClickEdit();
  };

  const networkDetails = useMemo(
    () => [
      {
        label: STRINGS.NAME,
        value: name,
        md: 4,
        xs: 12,
      },
      {
        label: STRINGS.DESCRIPTION,
        value: description,
        md: 8,
        xs: 12,
      },
    ],
    [name, description]
  );

  const adminSettingsInfo = useMemo(
    () => [
      {
        label: STRINGS.STATUS,
        value: isActivated,
        md: 4,
        xs: 12,
        renderValue: (val) => STATUS_LABELS[getActiveStatusValue(val)],
      },
      {
        label: STRINGS.AGREEMENT,
        value: AGREEMENT_TYPES_LABELS[contentAgreementType] || '',
        md: 4,
        xs: 12,
      },
    ],
    [isActivated, contentAgreementType]
  );

  return (
    <>
      <Grid container sx={{ mb: 2 }}>
        <Grid item xs={10} md={11}>
          <StyledHeaderSection variant="h6">
            {STRINGS.NETWORK_DETAILS}
          </StyledHeaderSection>
        </Grid>
        <Grid
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            flexDirection: 'row-reverse',
          }}
          item
          xs={2}
          md={1}
        >
          <Button
            onClick={handleEdit}
            variant="outlined"
            color="secondary"
            sx={{
              border: '1.5px solid #4623E4',
              textTransform: 'none',
            }}
          >
            {STRINGS.EDIT}
            <EditOutlinedIcon sx={{ ml: 1, fontSize: 18 }} />
          </Button>
        </Grid>
      </Grid>
      <MetaInfo items={networkDetails} md={12} />
      <StyledHeaderSection variant="h6" sx={{ mt: 3, mb: 1 }}>
        {STRINGS.ADMIN_SETTINGS}
      </StyledHeaderSection>
      <MetaInfo items={adminSettingsInfo} md={12} />
    </>
  );
};

export default NetworkDetail;
