import { useMemo, useCallback } from 'react';
import { isEmpty } from 'lodash';
import { Box, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useHistory } from 'react-router-dom';
// import Sidebar from './Sidebar';
import { EllipsisWrapper, SearchBox, ViewFilter } from '@components/index';
import Card from './Card';
import Loader from './Loader';
import ListItem from './ListItem';
import {
  StyledMainContainer,
  StyledFiltersContainer,
  StyledTitle,
  StyledScrollContainer,
  StyledAllItemsText,
  StyledSubtitle,
  StyledCounter,
} from './styles';
import { uuid } from '@utils/uuid';
import { isNotEmpty } from '@utils/arrays';
import { searchMarbyls } from '@graphql/queries/dashboard';
import { RIGHT_DRAWER_WIDTH } from 'constants/navigation';
import { FILTER_TYPES } from 'constants/filterTypes';
import { STRINGS } from 'constants/strings';
import { MAIN_ROUTES, MPC_ROUTES } from 'constants/routing';
import useDesktopMobile from 'hooks/useDesktopMobile';
import useReducer from 'hooks/useReducer';
import useSearchList from 'hooks/useSearchList';
import { dateToMMDoYYYY } from '@utils/dateFormat';
import { useMPC } from 'contexts/MPCContext';
// import { useEntityNames } from 'contexts/EntitiesNamesContext';
import {
  NETWORK,
  PODCASTER,
  PODCAST_PREFIX,
  EPISODE_PREFIX,
  MARBYL_PREFIX,
  NETWORK_ENTITY,
} from 'constants/idPrefixes';

const initialState = {
  viewType: FILTER_TYPES.CARD,
  isSidebarOpen: false,
  searchTerm: '',
};

const MarbylChartView = () => {
  const [{ viewType, isSidebarOpen, searchTerm }, setState] = useReducer(initialState);
  const theme = useTheme();
  const scrollContainerId = useMemo(() => `ScrollContainer-${uuid()}`, []);
  const drawerWidth = isSidebarOpen ? RIGHT_DRAWER_WIDTH : 0;
  const { isDesktopOrLaptop } = useDesktopMobile();
  const history = useHistory();
  const {
    selectedAccount: { entityId, entityAccountType },
  } = useMPC();
  // const setEntity = useEntityNames()[1];

  const {
    requestList,
    list: marbylsList,
    totalCount,
    listNextToken,
    listEnd,
  } = useSearchList(searchMarbyls);

  // const setIsSidebarOpen = (value) => setState({ isSidebarOpen: value });

  // const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  const setViewType = (type) => setState({ viewType: type });

  const setSearchTerm = (text) => {
    setState({ searchTerm: text });
  };

  const onCleanInput = () => {
    requestList('', true);
  };

  const loadMorePages = () => {
    requestList();
  };

  const handleClickMarbyl = (networkPK, podcasterPK, showPK, episodePK, PK) => {
    const _networkPK = networkPK.replace(NETWORK, '');
    const _podcasterPK = podcasterPK ? podcasterPK.replace(PODCASTER, '') : '';
    const _showPK = showPK.replace(PODCAST_PREFIX, '');
    const _episodePK = episodePK.replace(EPISODE_PREFIX, '');
    const _marbylPK = PK.replace(MARBYL_PREFIX, '');

    const addNetwork =
      entityAccountType === 'TGM' ||
      entityId === NETWORK_ENTITY + 'tgm' ||
      entityId.startsWith(NETWORK_ENTITY);

    const redirectTo = `${MAIN_ROUTES.MPC}${
      addNetwork ? MPC_ROUTES.NETWORKS + '/' + _networkPK : ''
    }${podcasterPK ? MPC_ROUTES.PODCASTER + '/' + _podcasterPK : ''}${
      MPC_ROUTES.PODCAST
    }/${_showPK}${MPC_ROUTES.EPISODES}/${_episodePK}${MPC_ROUTES.MARBYLS}/${_marbylPK}`;
    // setEntity(_podcasterPK, 'Podcaster');
    // setEntity(_showPK, 'Show');
    // setEntity(_episodePK, 'Episode');
    setTimeout(() => {
      history.push(redirectTo);
    }, 100);
  };

  const columns = useMemo(
    () => [
      {
        headerName: 'Marbyl Text',
        flex: 1,
        md: 5,
        sm: 5,
        val: 'marbylText',
        isHighlight: true,
      },
      {
        headerName: 'Episode',
        md: 2,
        sm: 2,
        display: { xs: 'none', sm: 'flex', md: 'flex' },
        val: 'episodeName',
        cellRenderer: (value) => <EllipsisWrapper value={value} />,
      },
      {
        headerName: 'Creation Date',
        md: 2,
        sm: 2,
        display: { xs: 'none', sm: 'flex', md: 'flex' },
        val: 'createdAt',
        cellRenderer: (value) => <EllipsisWrapper value={dateToMMDoYYYY(value)} />,
      },
      {
        headerName: 'Status',
        md: 1,
        sm: 1,
        display: { xs: 'none', sm: 'flex', md: 'flex' },
        val: 'isActivated',
        cellRenderer: (value) => (value ? 'Active' : 'Inactive'),
      },
      {
        headerName: 'Network',
        md: 1,
        sm: 1,
        display: { xs: 'none', sm: 'flex', md: 'flex' },
        val: 'network',
        cellRenderer: (value) => <EllipsisWrapper value={value} />,
      },
    ],
    []
  );

  const renderList = useCallback(
    () =>
      marbylsList.map((marbylItem) => {
        if (viewType === FILTER_TYPES.CARD) {
          return (
            <Card
              key={uuid()}
              marbylText={marbylItem?.text || 'marbylText'}
              marbylViews={marbylItem?.viewCount ?? 12}
              marbylShares={marbylItem?.shareCount ?? 11}
              creatorUserName={marbylItem?.user?.userName || 'userName'}
              showName={marbylItem?.episode?.show?.title || 'showTitle'}
              episodeName={marbylItem?.episode?.title || 'episodeTitle'}
              marbylStartTime={marbylItem?.startTime || '0:00:00.000'}
              PK={marbylItem?.PK || 'MARBYL#'}
              highlight={marbylItem?.highlight}
              episodePK={marbylItem?.episode?.PK || 'EPISODE#'}
              showPK={marbylItem?.episode?.show?.PK || 'SHOW#'}
              onPress={handleClickMarbyl}
              networkPK={marbylItem?.GSI10PK || 'NETWORK#'}
              podcasterPK={marbylItem?.Podcaster}
            />
          );
        }

        return (
          <ListItem
            key={uuid()}
            columns={columns}
            marbylText={marbylItem?.text || 'marbylText'}
            episodeName={marbylItem?.episode?.title || 'episodeTitle'}
            createdAt={marbylItem?.createdAt || ''}
            highlight={marbylItem?.highlight}
            isActivated={marbylItem?.isActivated}
            episodePK={marbylItem?.episode?.PK}
            showPK={marbylItem?.episode?.show?.PK}
            PK={marbylItem?.PK || 'MARBYL#'}
            onPress={handleClickMarbyl}
            networkPK={marbylItem?.GSI10PK || 'NETWORK#'}
            podcasterPK={marbylItem?.Podcaster}
            network={marbylItem?.episode?.show?.network}
          />
        );
      }),
    [marbylsList, viewType, entityId]
  );

  const renderContent = useCallback(() => {
    const isLoading = listNextToken === '0' && !isNotEmpty(marbylsList);

    if (isLoading) {
      return <Loader size={120} />;
    }

    if (isEmpty(marbylsList)) {
      return (
        <Grid container padding={5} direction="column" alignItems="center">
          <Typography variant="h6">{STRINGS.NO_RESULTS_WERE_FOUND}</Typography>
        </Grid>
      );
    }
    return (
      <>
        <InfiniteScroll
          scrollThreshold="300px"
          className={viewType === FILTER_TYPES.CARD ? 'List' : 'List-View'}
          dataLength={marbylsList.length}
          scrollableTarget={scrollContainerId}
          next={loadMorePages}
          hasMore={!listEnd}
          loader={<Loader size={50} />}
          endMessage={
            <StyledAllItemsText>
              <b>You have seen it all!</b>
            </StyledAllItemsText>
          }
        >
          {renderList()}
        </InfiniteScroll>
      </>
    );
  }, [viewType, scrollContainerId, marbylsList, listEnd, loadMorePages]);

  return (
    <Box>
      <StyledMainContainer
        style={{ maxWidth: isDesktopOrLaptop ? `calc(100% - ${drawerWidth}px)` : '100%' }}
      >
        <StyledFiltersContainer>
          <StyledTitle sx={{ marginRight: theme.spacing(1) }}>Dashboard</StyledTitle>
        </StyledFiltersContainer>
        <StyledFiltersContainer sx={{ marginTop: theme.spacing(3) }}>
          <SearchBox
            text={searchTerm}
            setText={setSearchTerm}
            placeholder="Search Marbyls"
            onSearch={requestList}
            onCleanInput={onCleanInput}
          />
        </StyledFiltersContainer>
        <Box
          sx={{
            display: 'flex',
            margin: theme.spacing(4, 3, 2, 0),
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <StyledCounter>({totalCount})</StyledCounter>
            <StyledSubtitle variant="h6">Marbyls</StyledSubtitle>
          </Box>

          <ViewFilter defaultFilter={viewType} onChangeFilter={setViewType} />
        </Box>
        {viewType === FILTER_TYPES.LIST && <ListItem columns={columns} isHeader />}
        <StyledScrollContainer id={scrollContainerId}>
          {renderContent()}
        </StyledScrollContainer>
      </StyledMainContainer>
      {/* <Sidebar isOpen={isSidebarOpen} toggle={toggleSidebar} /> */}
    </Box>
  );
};

export default MarbylChartView;
