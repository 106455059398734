import { useCallback } from 'react';
import { toast } from 'react-toastify';
import { isNotEmpty } from '@utils/arrays';

const useNotifications = () => {
  const showErrorMessages = useCallback((errors, defaultMessage) => {
    const errorMessage = isNotEmpty(errors) ? errors[0]?.message : defaultMessage;

    toast.error(errorMessage);
  }, []);

  return {
    showErrorMessages,
  };
};

export default useNotifications;
