import PropTypes from 'prop-types';
import { Box, CircularProgress } from '@mui/material';

const Loader = ({ size }) => {
  return (
    <Box display="flex" alignItems="center" justifyContent="center" height={200}>
      <CircularProgress sx={{ color: 'secondary.main' }} size={size} />
    </Box>
  );
};

Loader.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Loader.defaultProps = {
  size: 40,
};

export default Loader;
