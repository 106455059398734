import styled from '@mui/styled-engine';
import { OutlinedInput, IconButton } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { FONT_FAMILIES } from 'constants/stylesVariables';

export const StyledSearchBox = styled(OutlinedInput)(({ theme }) => ({
  backgroundColor: theme.palette.main.main,
  fontFamily: FONT_FAMILIES.spaceGroteskMedium,
  borderRadius: 21,
  '& legend': { display: 'none' },
  '& fieldset': { top: 0 },
  height: 45,
  minWidth: 365,
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.secondary.newPurple,
    borderWidth: 1.5,
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.secondary.newPurple,
    borderWidth: 1.5,
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.secondary.newPurple,
    borderWidth: 1.5,
  },
}));

export const StyledIconButton = styled(IconButton)({
  position: 'absolute',
  right: 2,
});

export const StyledCloseIcon = styled(CloseRoundedIcon)({
  fontWeight: 'bold',
  fontSize: 18,
});
