export const loadRSSFeed = /* GraphQL */ `
  mutation loadRSSFeed($podcastId: String!) {
    loadRSSFeed(podcastId: $podcastId) {
      PK
    }
  }
`;

export const updatePodcast = /* GraphQL */ `
  mutation updatePodcast(
    $podcastId: String!
    $podcastMarbylizationIsEnabled: podcastMarbylizationIsEnabledEnum
    $isActivated: Boolean
    $availableInGeos: [Alpha2Codes]
    $m11nAutoType: M11nAutoType
    $preferredSubscribeLink: String
    $preferredSubscribeLinkType: SubscribeLinkType
  ) {
    updatePodcast: MPCUpdateShowV2(
      input: {
        podcastId: $podcastId
        podcastMarbylizationIsEnabled: $podcastMarbylizationIsEnabled
        isActivated: $isActivated
        availableInGeos: $availableInGeos
        m11nAutoType: $m11nAutoType
        preferredSubscribeLink: $preferredSubscribeLink
        preferredSubscribeLinkType: $preferredSubscribeLinkType
      }
    ) {
      PK
    }
  }
`;

export const enableDisableEpisodesMarbylization = /* GraphQL */ `
  mutation enableDisableEpisodesMarbylization(
    $episodeIds: [String]!
    $episodeMarbylizationIsEnabled: episodeMarbylizationIsEnabledEnum
  ) {
    batchWriteEpisodes: MPCBatchWriteEpisodesV2(
      input: {
        episodeIds: $episodeIds
        episodeMarbylizationIsEnabled: $episodeMarbylizationIsEnabled
      }
    )
  }
`;

export const activateDeactivateEpisodes = /* GraphQL */ `
  mutation activateDeactivateEpisodes($episodeIds: [String]!, $isActivated: Boolean!) {
    batchWriteEpisodes: MPCBatchWriteEpisodesV2(
      input: { episodeIds: $episodeIds, isActivated: $isActivated }
    )
  }
`;

export const changeEpisodesGeoAvailability = /* GraphQL */ `
  mutation changeEpisodesGeoAvailability(
    $episodeIds: [String]!
    $availableInGeos: [Alpha2Codes]!
  ) {
    batchWriteEpisodes: MPCBatchWriteEpisodesV2(
      input: { episodeIds: $episodeIds, availableInGeos: $availableInGeos }
    )
  }
`;

export const deleteEpisodes = /* GraphQL */ `
  mutation deleteEpisodes($episodeIds: [String]!) {
    softDeleteEpisodes(input: { episodeIds: $episodeIds })
  }
`;
