import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { Typography, Box } from '@mui/material';
import { toast } from 'react-toastify';
import { useHistory, useLocation } from 'react-router';
import { cloneDeep } from 'lodash';
import pluralize from 'pluralize';
import {
  EntitiesDataGrid,
  HierarchyHeader,
  SimpleDropdown,
  SkeletonLoading,
  EllipsisWrapper,
  CheckAll,
} from '@components/index';
import { useEntityNames } from 'contexts/EntitiesNamesContext';
import { useAuth } from 'contexts/AuthContext';
import useInfiniteRow from 'hooks/useInfiniteRow';
import { getPodcastById, listEpisodesForPodcastTable } from '@graphql/queries/podcasts';
import {
  activateDeactivateEpisodes,
  deleteEpisodes,
  enableDisableEpisodesMarbylization,
  updatePodcast,
} from '@graphql/mutations/podcasts';
import { requestAPIGraphQL } from '@services/appSyncAPI';
import { STRINGS } from 'constants/strings';
import { FONT_FAMILIES } from 'constants/stylesVariables';
import { EPISODE_PREFIX, PODCAST_PREFIX } from 'constants/idPrefixes';
import { TAP_EPISODE_ITEM, TAP_MARBYLIZE_EPISODES } from 'constants/analytics';
import TOAST_OPTIONS from 'constants/toastOptions';
import { STATUS_LABELS } from 'constants/activeStatus';
import { DEFAULT_LIMIT } from 'constants/appSync';
import { M11N_AUTO_TYPES } from 'constants/m11n';
import { PODCAST_VIEW_ACTIONS } from 'constants/actionsList';
import { MPC_ROUTES } from 'constants/routing';
import { dateToMMDoYYYY } from '@utils/dateFormat';
import { formatDuration } from '@utils/time';
import { getActiveStatusValue, getIsActive } from '@utils/getActiveStatus';
import { replaceLiterals } from '@utils/replaceLiterals';
import MySwal from '@utils/swalWrapper';
import { record } from '@utils/analytics';
import { errorLog } from '@utils/logs';
import EditPodcast from './edit';
import PodcastDetail from './detail';

const EPISODES_ACTIONS = {
  ENABLE_MARBYLIZATION: {
    key: 'enable_marbylization',
    label: 'Enable Marbylization',
  },
  DISABLE_MARBYLIZATION: {
    key: 'disable_marbylization',
    label: 'Disable Marbylization',
  },
  ACTIVATE: {
    key: 'activate_episodes',
    label: 'Activate Episodes',
  },
  DEACTIVATE: {
    key: 'deactivate_episodes',
    label: 'Deactivate Episodes',
  },
};

const EPISODES_BLOCK = DEFAULT_LIMIT;

const getRowStyle = ({ data }) => {
  if (!data) return;

  const { isDeleted } = data;

  if (isDeleted) {
    return { color: 'gray', cursor: 'normal' };
  }
};

const PodcastContainer = ({ id }) => {
  const gridRef = useRef();
  const history = useHistory();
  const { pathname } = useLocation();
  const [podcast, setPodcast] = useState();
  const [selEpisodes, setSelEpisodes] = useState([]);
  const [loading, setLoading] = useState(false);
  const setEntity = useEntityNames()[1];
  const { permissions } = useAuth();

  const [editMode, setEditMode] = useState(false);

  const { getDataSource } = useInfiniteRow({
    query: listEpisodesForPodcastTable,
    payload: {
      podcastId: `${PODCAST_PREFIX}${id}`,
      limit: EPISODES_BLOCK,
    },
    responseKeyName: 'listEpisodesByPodcastId',
  });

  const columnDefs = useMemo(
    () => [
      {
        field: '',
        checkboxSelection: ({ data }) => {
          if (!data) return false;

          const { isDeleted } = data;

          if (isDeleted) return false;

          return true;
        },
        headerName: '',
        width: 60,
        cellStyle: { display: 'flex', alignItems: 'center' },
        headerComponent: () => <CheckAll ref={gridRef} />,
      },
      {
        field: 'isDeleted',
        hide: true,
      },
      {
        field: 'title',
        headerName: 'Title',
        minWidth: 300,
        flex: 1,
        cellRenderer: EllipsisWrapper,
      },
      {
        field: 'pubDate',
        headerName: 'Pub Date',
        width: 200,
        cellRenderer: ({ value }) => (
          <EllipsisWrapper
            value={
              value ? dateToMMDoYYYY(value) : dateToMMDoYYYY(new Date().toISOString())
            }
          />
        ),
      },
      {
        field: 'isActivated',
        headerName: 'Status',
        cellRenderer: ({ value, data }) => {
          const { isDeleted } = data || { isDeleted: false };

          if (isDeleted || value === null) {
            return STRINGS.DELETED;
          }

          const status = getActiveStatusValue(value);

          return <EllipsisWrapper value={STATUS_LABELS[status]} />;
        },
        width: 100,
      },
      {
        field: 'duration',
        headerName: 'Duration',
        width: 130,
        cellRenderer: ({ value }) => (
          <EllipsisWrapper value={value ? formatDuration(value) : ''} />
        ),
      },
      {
        field: 'episodeMarbylizationIsEnabled',
        headerName: 'Marbylization Status',
        cellRenderer: ({ value }) => <EllipsisWrapper value={value ?? STRINGS.PENDING} />,
      },
      {
        field: 'marbylCount',
        headerName: 'Marbyls',
        cellRenderer: ({ value }) => <EllipsisWrapper value={value ?? 0} />,
      },
    ],
    []
  );

  const episodesActions = useMemo(() => {
    const podcastsLength = selEpisodes.length;
    if (podcastsLength === 0) {
      return [];
    }

    let tempActions = cloneDeep(EPISODES_ACTIONS);
    let canMarbylize = true;
    let canChangeStatus = true;
    let canDelete = true;

    if (!(PODCAST_VIEW_ACTIONS.TOGGLE_M11N_EPISODES in permissions)) {
      canMarbylize = false;
      delete tempActions.DISABLE_MARBYLIZATION;
      delete tempActions.ENABLE_MARBYLIZATION;
    }

    if (!(PODCAST_VIEW_ACTIONS.TOGGLE_EPISODES in permissions)) {
      canChangeStatus = false;
      delete tempActions.ACTIVATE;
      delete tempActions.DEACTIVATE;
    }

    if (!(PODCAST_VIEW_ACTIONS.DELETE_EPISODES in permissions)) {
      canDelete = false;
      delete tempActions.DELETE;
    }

    if (canMarbylize) {
      const { m11nAutoType } = podcast;

      if (m11nAutoType === M11N_AUTO_TYPES.DISABLED) {
        tempActions.ENABLE_MARBYLIZATION.disabled = true;
        tempActions.DISABLE_MARBYLIZATION.disabled = true;
      }
    }

    if (canChangeStatus) {
      const activatedLength = selEpisodes.reduce(
        (accumulator, current) => (accumulator += current.isActivated),
        0
      );

      if (podcastsLength === 1) {
        tempActions.ACTIVATE.label = pluralize.singular(tempActions.ACTIVATE.label);
        tempActions.DEACTIVATE.label = pluralize.singular(tempActions.DEACTIVATE.label);

        activatedLength
          ? (tempActions.ACTIVATE.disabled = true)
          : (tempActions.DEACTIVATE.disabled = true);
      } else {
        if (activatedLength === podcastsLength) {
          tempActions.ACTIVATE.disabled = true;
        }

        if (activatedLength === 0) {
          tempActions.DEACTIVATE.disabled = true;
        }
      }
    }

    if (canDelete) {
      // const deleted = selEpisodes.reduce(
      //   (accumulator, current) => (accumulator += current.isDeleted),
      //   0
      // );
      // if (deleted) {
      //   tempActions.DELETE.disabled = true;
      // }
      // if (podcastsLength === 1) {
      //   tempActions.DELETE.label = pluralize.singular(tempActions.DELETE.label);
      // }
    }

    return Object.values(tempActions);
  }, [selEpisodes, podcast, permissions]);

  const setEpisodesDataSource = useCallback(
    () => gridRef.current.api.setDatasource(getDataSource(true)),
    [getDataSource]
  );

  const getRowId = useCallback(function (params) {
    return params.data.PK;
  }, []);

  const loadPodcast = useCallback(
    () =>
      requestAPIGraphQL(getPodcastById, { podcastId: `${PODCAST_PREFIX}${id}` })
        .then(({ data: { getPodcastById } }) => {
          setPodcast(getPodcastById);
        })
        .catch(),
    [id]
  );

  const handleRowClick = ({ PK, isDeleted, title }) => {
    if (isDeleted || selEpisodes.length) return;

    const _id = PK.replace(`${EPISODE_PREFIX}`, '');
    record(TAP_EPISODE_ITEM, { id: PK });
    setEntity(_id, title);
    history.push(`${pathname}${MPC_ROUTES.EPISODES}/${_id}`);
  };

  const onClickAction = (action) => {
    const { ids, hash } = selEpisodes.reduce(
      (obj, pod) => ({
        ids: [...obj.ids, pod.PK],
        hash: {
          ...obj.hash,
          [pod.PK]: pod.title,
        },
      }),
      { ids: [], hash: [] }
    );

    let query = null;
    let message = '';
    let payload = {
      episodeIds: ids,
    };
    let confirmationMessage = '';

    const actionCaller = (actionPromise, successMessage) =>
      new Promise((resolve, reject) => {
        setLoading(true);

        return actionPromise
          .then(() => {
            toast.success(successMessage, TOAST_OPTIONS);
            const { episodeIds } = payload;

            switch (action) {
              case EPISODES_ACTIONS.ENABLE_MARBYLIZATION.key:
              case EPISODES_ACTIONS.DISABLE_MARBYLIZATION.key: {
                const { episodeMarbylizationIsEnabled } = payload;
                episodeIds.forEach((_episodeId) => {
                  const rowNode = gridRef.current.api.getRowNode(_episodeId);
                  rowNode.setDataValue(
                    'episodeMarbylizationIsEnabled',
                    episodeMarbylizationIsEnabled
                  );
                });
                break;
              }

              case EPISODES_ACTIONS.ACTIVATE.key:
              case EPISODES_ACTIONS.DEACTIVATE.key: {
                const { isActivated } = payload;
                episodeIds.forEach((_episodeId) => {
                  const rowNode = gridRef.current.api.getRowNode(_episodeId);
                  rowNode.setDataValue('isActivated', isActivated);
                });
                break;
              }

              default:
                break;
            }

            resolve();
          })
          .catch((err) => {
            errorLog(err);
            toast.error(STRINGS.AN_ERROR_OCCURED_ON_SERVER__TRY_AGAIN, TOAST_OPTIONS);
            reject();
          })
          .finally(() => {
            gridRef.current.api.deselectAll();
            setLoading(false);
          });
      });

    const getMessage = (singleMessage, multipleMessage) => {
      return ids.length === 1
        ? replaceLiterals(singleMessage, {
            episodeName: hash[ids[0]],
          })
        : replaceLiterals(multipleMessage, {
            count: ids.length,
          });
    };

    switch (action) {
      case EPISODES_ACTIONS.ENABLE_MARBYLIZATION.key: {
        payload.episodeMarbylizationIsEnabled = 'Marbylized';
        query = enableDisableEpisodesMarbylization;
        message = getMessage(
          STRINGS.EPISODE_NAME_MARBYLIZATION_ENABLED,
          STRINGS.EPISODES_MARBYLIZATION_ENABLED
        );

        record(TAP_MARBYLIZE_EPISODES, {
          episodeIds: JSON.stringify(payload.episodeIds),
          episodeMarbylizationIsEnabled: 'Marbylized',
        });

        break;
      }

      case EPISODES_ACTIONS.DISABLE_MARBYLIZATION.key: {
        payload.episodeMarbylizationIsEnabled = 'Disabled';
        query = enableDisableEpisodesMarbylization;
        message = getMessage(
          STRINGS.EPISODE_NAME_MARBYLIZATION_DISABLE,
          STRINGS.EPISODES_MARBYLIZATION_DISABLED
        );

        record(TAP_MARBYLIZE_EPISODES, {
          episodeIds: JSON.stringify(payload.episodeIds),
          episodeMarbylizationIsEnabled: 'Disabled',
        });

        break;
      }

      case EPISODES_ACTIONS.ACTIVATE.key: {
        payload.isActivated = true;
        query = activateDeactivateEpisodes;
        message = getMessage(STRINGS.EPISODE_NAME_ACTIVATED, STRINGS.EPISODES_ACTIVATED);
        break;
      }

      case EPISODES_ACTIONS.DEACTIVATE.key: {
        payload.isActivated = false;
        query = activateDeactivateEpisodes;
        message = getMessage(
          STRINGS.EPISODE_NAME_DEACTIVATED,
          STRINGS.EPISODES_DEACTIVATED
        );
        break;
      }

      case EPISODES_ACTIONS.DELETE.key: {
        query = deleteEpisodes;
        message = getMessage(STRINGS.EPISODE_NAME_DELETED, STRINGS.EPISODES_DELETED);
        confirmationMessage = getMessage(
          STRINGS.ARE_YOU_SURE_DO_YOU_WANT_TO_DELETE_EPISODE_NAME,
          STRINGS.ARE_YOU_SURE_DO_YOU_WANT_TO_DELETE_SELECTED_EPISODES
        );

        break;
      }

      default:
        break;
    }

    if (confirmationMessage) {
      MySwal.fire({
        html: <Typography variant="body1">{confirmationMessage}</Typography>,
        showCancelButton: true,
        confirmButtonText: STRINGS.CONFIRM,
        denyButtonText: STRINGS.CANCEL,
      }).then(({ isConfirmed }) => {
        if (isConfirmed) {
          actionCaller(requestAPIGraphQL(query, payload), message);
        }
      });

      return;
    }

    actionCaller(requestAPIGraphQL(query, payload), message);
  };

  const renderExpanded = useCallback(() => {
    const handleSubmit = (values) => {
      const payload = {
        podcastId: values.id,
        ...(permissions[PODCAST_VIEW_ACTIONS.TOGGLE_ACTIVATION_PODCAST] && {
          isActivated: getIsActive(values.isActivated),
        }),
        ...(permissions[PODCAST_VIEW_ACTIONS.TOGGLE_M11N] && {
          podcastMarbylizationIsEnabled: values.podcastMarbylizationIsEnabled,
        }),
        ...(permissions[PODCAST_VIEW_ACTIONS.EDIT_M11N_OPTIONS] && {
          m11nAutoType: values.m11nAutoType,
        }),
      };

      requestAPIGraphQL(updatePodcast, { ...payload })
        .then(() => {
          toast.success(STRINGS.PODCAST_UPDATED, TOAST_OPTIONS);
          setEditMode(false);
          loadPodcast();
        })
        .catch((err) => {
          errorLog(err);
          toast.error(STRINGS.AN_ERROR_OCCURED_ON_SERVER__TRY_AGAIN, TOAST_OPTIONS);
        });
    };

    if (editMode) {
      return (
        <EditPodcast
          onCancel={() => setEditMode(false)}
          onSubmit={handleSubmit}
          {...podcast}
        />
      );
    }

    return <PodcastDetail onClickEdit={() => setEditMode(true)} {...podcast} />;
  }, [editMode, podcast, permissions, loadPodcast]);

  useEffect(() => {
    Promise.all([loadPodcast()]);
  }, [loadPodcast]);

  if (!podcast) return <SkeletonLoading />;

  return (
    <>
      <HierarchyHeader
        title={podcast.title}
        headerType={STRINGS.PODCAST}
        {...(permissions[PODCAST_VIEW_ACTIONS.VIEW_PODCAST_DETAILS] && {
          renderExpandedContent: renderExpanded,
        })}
        podcastShowArt={podcast.showArtSmall}
      />

      <Box
        sx={{
          display: 'flex',
          mt: 5,
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography
          sx={{
            fontFamily: FONT_FAMILIES.spaceGroteskSemiBold,
            fontSize: 18,
            fontWeight: '500',
          }}
        >
          {STRINGS.EPISODES}
        </Typography>
        <SimpleDropdown
          disabled={selEpisodes.length === 0 || loading}
          actions={episodesActions}
          onClickAction={onClickAction}
        />
      </Box>

      <EntitiesDataGrid
        ref={gridRef}
        columnDefs={columnDefs}
        onCellClicked={({ data }) => handleRowClick(data)}
        onSelectionChanged={(evt) => {
          setSelEpisodes(evt.api.getSelectedRows());
        }}
        rowModelType={'infinite'}
        onGridReady={setEpisodesDataSource}
        cacheBlockSize={EPISODES_BLOCK}
        getRowId={getRowId}
        getRowStyle={getRowStyle}
      />
    </>
  );
};

export default PodcastContainer;
