import React from 'react';

import {
  Button,
  Typography,
  Box,
  Grid,
  FormControlLabel,
  Checkbox,
  MenuItem,
  TextField,
  Alert,
} from '@mui/material';
import { StyledOutLinedInput, PrimaryButton } from '@shared/styledInputs';
import { PodcastArtTitle } from '@components/index';

import useLoadModalSubpage from 'hooks/useLoadModalSubpage';

import { pageNames } from 'constants/ipsso';
import { STRINGS } from 'constants/strings';
import { useAddYourPodcast } from 'contexts/AddYourPodcastContext';
import { TAP_NEXT_TO_CONFIRM_PODCAST_INFO } from 'constants/analytics';
import { useMediaQuery } from 'react-responsive';
import useDesktopMobile from 'hooks/useDesktopMobile';
import { DESKTOP_BREAKPOINT } from 'constants/breakpoints';
import { DIAL_CODES } from 'constants/countriesList';
import ReactInputMask from 'react-input-mask';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Spacer from '@shared/spacer';

const PodcastOwners = ({ renderBackBtn, onNextClick }) => {
  const {
    podcaster: { owners, PK },
    hosts,
    podcastState: [podcast],
    toggleHost,
    setOwner,
    removeOwner,
    addOwner,
  } = useAddYourPodcast();

  const isDesktopOrLaptop = useMediaQuery({
    query: DESKTOP_BREAKPOINT,
  });
  const { renderMobileComponent, renderDesktopComponent } = useDesktopMobile();

  const handleSubmit = (evt) => {
    evt.preventDefault();
    onNextClick(TAP_NEXT_TO_CONFIRM_PODCAST_INFO);
  };

  const handleHost = (id) => (evt) => {
    const {
      target: { checked },
    } = evt;

    toggleHost(id, checked);
  };

  const handleOwner = (index, field) => (evt) => {
    const {
      target: { value },
    } = evt;

    setOwner(index, { field, value });
  };

  const handleRemoveOwner = (index) => () => removeOwner(index);
  const handleAddOwner = () => addOwner();

  useLoadModalSubpage(pageNames.PODCAST_INFO);

  return (
    <>
      <Typography variant="h6">{STRINGS.PODCAST_VERIFIED}</Typography>
      <PodcastArtTitle podcast={podcast} />
      <Typography sx={{ mt: 2, fontSize: 18, fontWeight: '500' }}>
        {STRINGS.PODCAST_OWNERS}
      </Typography>

      <form id="owner-form" onSubmit={handleSubmit}>
        <Box sx={{ mt: 1 }}>
          <Grid alignItems="center" container rowSpacing={1}>
            {renderDesktopComponent(() => (
              <Grid container item columnSpacing={1}>
                <Grid item md={4}>
                  <Typography variant="subtitle1">{STRINGS.OWNERS_NAME}</Typography>
                </Grid>
                <Grid item md={1}>
                  <Typography
                    sx={{ textAlign: 'center', whiteSpace: 'pre-wrap' }}
                    variant="subtitle1"
                  >
                    {STRINGS.IS_HOST}
                  </Typography>
                </Grid>
                <Grid item md={3}>
                  <Typography variant="subtitle1">{STRINGS.OWNERS_EMAIL}</Typography>
                </Grid>
                <Grid item md={3}>
                  <Typography variant="subtitle1">{STRINGS.OWNERS_MOBILE}</Typography>
                </Grid>

                <Grid item md={1}></Grid>
              </Grid>
            ))}

            {owners.map((_owner, index) => (
              <Grid container item key={_owner.id} columnSpacing={1} rowSpacing={2}>
                <Grid item md={4} xs={12}>
                  <StyledOutLinedInput
                    value={_owner.name}
                    required
                    onChange={handleOwner(index, 'name')}
                    sx={{ display: 'flex' }}
                  />
                </Grid>
                <Grid
                  sx={{ ...(isDesktopOrLaptop && { textAlign: 'center' }) }}
                  item
                  md={1}
                  xs={12}
                >
                  {renderMobileComponent(() => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primaryButton"
                          checked={hosts[_owner.id] || false}
                          onChange={handleHost(_owner.id)}
                        />
                      }
                      label={STRINGS.HOST_CO_HOST_PODCAST}
                    />
                  ))}

                  {renderDesktopComponent(() => (
                    <Checkbox
                      color="primaryButton"
                      checked={hosts[_owner.id] || false}
                      onChange={handleHost(_owner.id)}
                      style={{
                        transform: 'scale(1.1)',
                      }}
                    />
                  ))}
                </Grid>
                <Grid item md={3} xs={12}>
                  <StyledOutLinedInput
                    value={_owner.email}
                    required
                    onChange={handleOwner(index, 'email')}
                    focused
                  />
                </Grid>
                <Grid item md={3} xs>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <TextField
                      color="secondary"
                      value={_owner.phoneCode}
                      id="outlined-select-code"
                      select
                      sx={{
                        width: 115,
                        '& .MuiOutlinedInput-root': {
                          height: 45,
                          borderTopRightRadius: 0,
                          borderBottomRightRadius: 0,
                          '& fieldset': {
                            borderColor: '#B5B5B5',
                          },
                          '&:hover fieldset': {
                            borderColor: '#B5B5B5',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#B5B5B5',
                          },
                        },
                        left: 2,
                        '& legend': {
                          display: 'none',
                        },
                        '& fieldset': {
                          top: 0,
                        },
                      }}
                      onChange={handleOwner(index, 'phoneCode')}
                      focused
                    >
                      {DIAL_CODES.map((country) => (
                        <MenuItem key={country.key} value={country.label}>
                          {country.label}
                        </MenuItem>
                      ))}
                    </TextField>
                    <ReactInputMask
                      value={_owner.phoneNumber}
                      onChange={handleOwner(index, 'phoneNumber')}
                      mask="(999) 999-9999"
                    >
                      {(inputProps) => (
                        <StyledOutLinedInput
                          {...inputProps}
                          color="secondary"
                          focused
                          sx={{
                            '& .MuiOutlinedInput-notchedOutline': {
                              borderTopLeftRadius: 0,
                              borderBottomLeftRadius: 0,
                            },
                            '& .MuiOutlinedInput-input': {
                              borderTopLeftRadius: 0,
                              borderBottomLeftRadius: 0,
                            },
                          }}
                        />
                      )}
                    </ReactInputMask>
                  </Box>
                </Grid>

                {(_owner.isNew || index > 0) && (
                  <Grid
                    item
                    md={1}
                    xs={2}
                    sx={{
                      justifyContent: 'center',
                      alignItems: 'center',
                      display: 'flex',
                    }}
                  >
                    <DeleteOutlineIcon
                      fontSize={'large'}
                      role="button"
                      onClick={handleRemoveOwner(index)}
                    />
                  </Grid>
                )}

                {renderMobileComponent(() => (
                  <Grid item xs={12}>
                    <Spacer size={10} />
                  </Grid>
                ))}
              </Grid>
            ))}

            {PK?.startsWith('USER#') && owners.length === 0 && (
              <Grid item md={12}>
                <Alert variant="filled" color="main" severity="info">
                  <i>{STRINGS.PODCAST_FROM_NETWORK_MESSAGE}</i>
                </Alert>
              </Grid>
            )}

            <Grid item md={6}>
              <Button
                color="primaryButton"
                onClick={handleAddOwner}
                sx={{ textTransform: 'none' }}
              >
                {STRINGS.ADD_ANOTHER_OWNER}
              </Button>
            </Grid>
          </Grid>

          {/*<Box sx={{ mt: 4 }}>
            <CountriesSelect
              color="secondary"
              name="countries"
              value={regions}
              onChange={(_value) => setRegions(_value)}
            />
          </Box>*/}
        </Box>

        <Box sx={{ mt: 2 }}>
          {renderBackBtn()}

          <PrimaryButton
            variant="contained"
            color="primaryButton"
            type="submit"
            sx={{
              width: 184,
            }}
          >
            {STRINGS.NEXT}
          </PrimaryButton>
        </Box>
      </form>
    </>
  );
};

export default PodcastOwners;
