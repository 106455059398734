import styled from '@mui/styled-engine';
import {
  Button,
  Radio,
  Checkbox,
  TextField,
  TableCell,
  OutlinedInput,
} from '@mui/material';

export const PrimaryButton = styled(Button)(
  ({ theme: { palette } }) => `
  padding: 0em 2em;
  height: 45px;
  text-transform: none;
  font-size: 16px;
  &.Mui-disabled {
    background-color: ${palette.primaryButton.dark};
    color: ${palette.primaryButton.contrastText};
  }
  `
);

export const SecondaryButton = styled(Button)(
  ({ theme: { palette } }) => `
  padding: 0em 2em;
  border: 1px solid #CCCCCC;
  font-size: 16px;
  &.Mui-disabled {
    background-color: ${palette.secondaryButton.dark};
    color: ${palette.secondaryButton.contrastText};
  }
  `
);

export const StyledOutLinedInput = styled(OutlinedInput)({
  borderRadius: 4,
  '& legend': { display: 'none' },
  '& fieldset': { top: 0 },
  pt: 0.5,
  pb: 0.5,
  height: 45,
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#B5B5B5',
    borderWidth: 2,
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: '#B5B5B5',
    borderWidth: 2,
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#B5B5B5',
    borderWidth: 2,
  },
});

export const StyledInvertedButton = styled(Button)(
  ({ theme: { palette }, color }) => `

  &.Mui-disabled {
    background-color: ${palette[color].dark};
    color: ${palette.secondary.dark};
  }
`
);

export const StyledInvertedRadio = styled(Radio)(
  ({ theme: { palette } }) => `
  &.MuiRadio-root {
    color: ${palette.secondary.main};
  }
  &.MuiRadio-colorPrimary: {
    color: ${palette.secondary.main};
  }

  &.MuiRadio-colorSecondary: {
    color: ${palette.primary.main};
  }
`
);

export const StyledInvertedCheckbox = styled(Checkbox)(
  ({ theme: { palette } }) => `
  &.MuiCheckbox-colorPrimary { color: ${palette.secondary.main}; }
`
);

export const StyledInvertedTextField = styled(TextField)(
  ({ theme: { palette } }) => `
  &.MuiTextField-root input { color: ${palette.secondary.main}; }
`
);

export const StyledInvertedTableCell = styled(TableCell)(
  ({ theme: { palette } }) => `
  color: ${palette.secondary.main};
`
);
