import { createContext, useContext, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import { useAuth } from 'contexts/AuthContext';
import { useDeps } from 'contexts/DepsContext';
import { getAllAccountsByUserId } from '@graphql/queries/accounts';
import { MAIN_ROUTES } from 'constants/routing';
import { SELECTED_USER_ACCOUNT_KEY } from 'constants/localStorageKeys';
import { USER_ROLES } from 'constants/userRoles';

const MPCContext = createContext(null);

export const useMPC = () => {
  return useContext(MPCContext);
};

// TODO: Create a function that receive a param, to select an account

const MPCContextProvider = ({ children }) => {
  const [permissions, setPermissions] = useState({});
  const [selectedAccount, setSelectedAccount] = useState({});
  const [accounts, setAccounts] = useState([]);
  const [hasSaved, setHasSaved] = useState(false);
  const [loading, setLoading] = useState(false);
  const { user = {}, authed } = useAuth();
  const { httpClient } = useDeps();
  const history = useHistory();
  const userAccount = user?.userAccount || {};

  const setInitialData = useCallback(() => {
    if (authed) {
      const selAccount = JSON.parse(localStorage.getItem(SELECTED_USER_ACCOUNT_KEY));

      if (!isEmpty(selAccount) && isEmpty(selectedAccount)) {
        setSelectedAccount(selAccount);
      } else {
        const tempSelectedAccount = {
          ...userAccount,
          email: user?.attributes?.email,
          accountId: userAccount?.accountId || userAccount?.userDefaultAccountId,
          accountRole: userAccount?.userDefaultAccountRole,
        };

        delete userAccount?.userDefaultAccountId;

        setSelectedAccount(tempSelectedAccount);
        localStorage.setItem(
          SELECTED_USER_ACCOUNT_KEY,
          JSON.stringify(tempSelectedAccount)
        );
      }
    }
  }, [authed, selectedAccount, userAccount]);

  const getIsSuperUser = useCallback(() => {
    if (!authed) return false;

    return selectedAccount?.userDefaultAccountRole === USER_ROLES.TGM_SUPERUSER;
  }, [authed, selectedAccount]);

  const retrieveAccounts = useCallback(
    (callback) => {
      if (authed) {
        setLoading(true);

        httpClient(getAllAccountsByUserId, { userId: userAccount?.userId })
          .then(({ data: { getAllAccountsByUserId: response } }) => {
            setAccounts(response?.userAccounts);

            if (typeof callback === 'function') callback(response?.userAccounts);
          })
          .catch(() => {
            toast.error('There was an error trying to retrieve the accounts.');
          })
          .finally(() => {
            setLoading(false);
          });
      }
    },
    [authed]
  );

  useEffect(() => {
    setInitialData();
    retrieveAccounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authed]);

  const redirectToMPC = useCallback(
    () => history.push(MAIN_ROUTES.MARBYL_CHART_VIEW),
    []
  );

  useEffect(() => {
    if (hasSaved) {
      setHasSaved(false);
      redirectToMPC();
    }
  }, [redirectToMPC, selectedAccount, hasSaved]);

  const saveSelectedUserAccount = useCallback((newSelectedAccount, callback) => {
    const tempSelectedAccount = {
      ...newSelectedAccount,
      userDefaultAccountRole: newSelectedAccount?.accountRole,
    };

    setHasSaved(true);

    delete tempSelectedAccount.label;
    delete tempSelectedAccount.value;

    localStorage.setItem(SELECTED_USER_ACCOUNT_KEY, JSON.stringify(tempSelectedAccount));
    setSelectedAccount(tempSelectedAccount);

    if (typeof callback === 'function') callback();
  }, []);

  const selectUserAccountAndRedirect = useCallback(
    (accountId) => {
      const callback = (accountsList) => {
        const id = accountId;
        const filteredAccount = accountsList?.find(
          (account) => account?.entityAccountId === id
        );

        saveSelectedUserAccount(filteredAccount);
      };

      retrieveAccounts(callback);
    },
    [accounts, authed]
  );

  return (
    <MPCContext.Provider
      value={{
        permissions,
        setPermissions,
        selectedAccount,
        saveSelectedUserAccount,
        selectUserAccountAndRedirect,
        accounts,
        loading,
        getIsSuperUser,
      }}
    >
      {children}
    </MPCContext.Provider>
  );
};

MPCContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MPCContextProvider;
