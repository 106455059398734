import React, { forwardRef } from 'react';
import { StyledFieldTitle, StyledFieldSubtitle, SelectStyles } from './styled';
import { Grid, Box } from '@mui/material';
import { props } from './props';

const MetaInfo = forwardRef(({ items }, ref) => (
  <Grid ref={ref} sx={{ wordBreak: 'break-word' }} container spacing={2}>
    {items.map((item, i) => (
      <Grid key={i} item md={item.md || 6} sm={item.sm || 12} xs={item.xs || 12}>
        <StyledFieldTitle variant="subtitle2">{item.label}</StyledFieldTitle>
        {item.renderValue ? (
          <Box
            {...(item.styles
              ? { sx: { ...SelectStyles, ...item.styles } }
              : { sx: { ...SelectStyles } })}
          >
            {item.renderValue(item.value)}
          </Box>
        ) : (
          <StyledFieldSubtitle {...(item.styles && { sx: item.styles })} variant="body1">
            {item.value && item.value != '' ? item.value : 'N/A'}
          </StyledFieldSubtitle>
        )}
      </Grid>
    ))}
  </Grid>
));

MetaInfo.propTypes = props;

export default MetaInfo;
