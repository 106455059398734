import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { MAIN_ROUTES } from 'constants/routing';
import { STRINGS } from 'constants/strings';
import { SIGN_IN_REDIRECT_KEY } from 'constants/localStorageKeys';
import { useAuth } from 'contexts/AuthContext';
import { toast } from 'react-toastify';

const PrivateRoute = ({
  component: Component,
  path,
  exact,
  redirectTo,
  render,
  ...rest
}) => {
  const location = useLocation();
  const { authed } = useAuth();

  if (!authed) {
    const pathname = redirectTo || MAIN_ROUTES.PARTNERS;

    if (pathname === MAIN_ROUTES.PARTNERS)
      localStorage.setItem(SIGN_IN_REDIRECT_KEY, path);

    if (path === MAIN_ROUTES.CONFIRM_ACCOUNT_USER) {
      toast.warning(STRINGS.LOGIN_MESSAGE);
    }

    return <Redirect to={{ ...location, pathname }} />;
  }

  return render ? (
    <Route path={path} exact={exact} render={render} />
  ) : (
    <Route path={path} exact={exact}>
      <Component {...rest} />
    </Route>
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.elementType,
  path: PropTypes.string,
  exact: PropTypes.bool,
  render: PropTypes.func,
};

export default PrivateRoute;
