import styled from '@mui/styled-engine';
import { Typography } from '@mui/material';
import { FONT_FAMILIES } from 'constants/stylesVariables';

export const StyledFieldTitle = styled(Typography)(
  () => `
  font-family: ${FONT_FAMILIES.spaceGroteskRegular};
  color: #1C1D1C;
`
);
export const StyledFieldSubtitle = styled(Typography)(
  () => `
  font-family: ${FONT_FAMILIES.spaceGroteskMedium};
`
);

export const SelectStyles = {
  color: '#1C1D1C',
  fontWeight: '400',
  fontSize: '1rem',
  lineHeight: 1.5,
  fontFamily: FONT_FAMILIES.spaceGroteskMedium,
};
