import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { Box, Typography } from '@mui/material';
import {
  EntitiesDataGrid,
  HierarchyHeader,
  SkeletonLoading,
  EllipsisWrapper,
} from '@components/index';
import { EntitiesIcon } from '@components/icons/index';
import { STRINGS } from 'constants/strings';
import { FONT_FAMILIES } from 'constants/stylesVariables';
import { useEntityNames } from 'contexts/EntitiesNamesContext';
import { useAddYourPodcast } from 'contexts/AddYourPodcastContext';
import { record } from '@utils/analytics';
import { getEntityType } from '@utils/getEntityType';
import {
  TAP_EDIT_NETWORK,
  TAP_NETWORK_ENTITY,
  TAP_PODCASTER_ENTITY,
} from 'constants/analytics';
import { PODCASTER, NETWORK, NETWORK_ENTITY, PODCAST_PREFIX } from 'constants/idPrefixes';
import {
  getNetworkById,
  listChildrenByPodcastNetworkId,
} from '@graphql/queries/networks';
import { requestAPIGraphQL } from '@services/appSyncAPI';
import useInfiniteRow from 'hooks/useInfiniteRow';
import { DEFAULT_LIMIT } from 'constants/appSync';
import { getIsActive } from '@utils/getActiveStatus';
import { updateNetwork } from '@graphql/mutations/networks';
import { showPromiseError } from '@utils/promiseError';
import { toast } from 'react-toastify';
import TOAST_OPTIONS from 'constants/toastOptions';
import { MPC_ROUTES } from 'constants/routing';
import EditNetwork from './edit';
import NetworkDetail from './detail';

const NETWORK_CHILDREN_BLOCK = DEFAULT_LIMIT;

const NetworkContainer = ({ id }) => {
  const gridRef = useRef();
  const history = useHistory();
  const { url } = useRouteMatch();
  const [network, setNetwork] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const setEntity = useEntityNames()[1];
  const { setInitPodcaster, finish, setFinish, isOnboarding } = useAddYourPodcast();

  const { getDataSource } = useInfiniteRow({
    query: listChildrenByPodcastNetworkId,
    payload: {
      networkdId: `${NETWORK}${id}`,
      limit: NETWORK_CHILDREN_BLOCK,
    },
    responseKeyName: 'listChildrenByPodcastNetworkId',
  });

  const handleEntityClick = ({ data: { PK, ...data } }) => {
    if (PK.includes(PODCASTER)) {
      const _id = PK.replace(PODCASTER, '');
      record(TAP_PODCASTER_ENTITY, { id: _id });
      setEntity(_id, data.name);

      history.push(`${url}${MPC_ROUTES.PODCASTER}/${_id}`);

      return;
    }
    if (PK.includes(NETWORK_ENTITY)) {
      const _id = PK.replace(NETWORK_ENTITY, '');
      record(TAP_NETWORK_ENTITY, { id: _id });
      setEntity(_id, data.podcastNetworkName);

      history.push(`${url}${MPC_ROUTES.NETWORKS}/${_id}`);

      return;
    }

    const _id = PK.replace(PODCAST_PREFIX, '');
    record(TAP_NETWORK_ENTITY, { id: _id });
    setEntity(_id, data.title);

    history.push(`${url}${MPC_ROUTES.PODCAST}/${_id}`);
  };

  const columnDefs = useMemo(
    () => [
      {
        width: 60,
        cellRenderer: ({ data }) => {
          const entity = getEntityType(data?.itemType);

          return (
            <EntitiesIcon
              centerIcon
              defaultImageDimensions
              isBold
              type={entity}
              podcastShowArt={data?.showArtSmall}
              height={24}
              width={24}
              color={'#000'}
            />
          );
        },
      },
      {
        field: 'name',
        headerName: 'Name',
        minWidth: 400,
        flex: 1,
        cellRenderer: ({ data }) => {
          if (!data) return '';

          const { name, podcastNetworkName, title } = data;
          return <EllipsisWrapper value={name || podcastNetworkName || title} />;
        },
      },
    ],
    []
  );

  const setDataSource = useCallback(
    () => gridRef.current.api.setDatasource(getDataSource()),
    [getDataSource]
  );

  const getRowId = useCallback(function (params) {
    return params.data.PK;
  }, []);

  const fetchNetwork = useCallback(() => {
    requestAPIGraphQL(getNetworkById, {
      networkId: `${NETWORK_ENTITY}${id}`,
    }).then(({ data: { getPodcastNetworkById } }) => {
      setNetwork(getPodcastNetworkById);
    });
  }, [id]);

  const handleSubmitNetwork = useCallback(
    (values) => {
      const {
        PK,
        name,
        description,
        contentAgreementType,
        isActivated,
        entity,
        entityType,
      } = values;

      const payload = {
        networkId: PK,
        contentAgreementType,
        description,
        isActivated: getIsActive(isActivated),
        entity,
        entityType,
        podcastNetworkName: name,
      };

      requestAPIGraphQL(updateNetwork, payload)
        .then(() => {
          setEditMode(false);
          setEntity(PK.replace(NETWORK_ENTITY, ''), name);
          fetchNetwork();
          toast.success(STRINGS.NETWORK_UPDATED, TOAST_OPTIONS);
        })
        .catch(showPromiseError);
    },
    [fetchNetwork, setEntity]
  );

  const renderExpanded = useCallback(() => {
    if (editMode) {
      record(TAP_EDIT_NETWORK, { id: network.PK });

      return (
        <EditNetwork
          {...network}
          onCancel={() => setEditMode(false)}
          onSubmit={handleSubmitNetwork}
        />
      );
    }

    return <NetworkDetail onClickEdit={() => setEditMode(true)} {...network} />;
  }, [editMode, network, handleSubmitNetwork]);

  const handlePodcastSubmit = useCallback(() => {
    fetchNetwork();
    setDataSource();
  }, [fetchNetwork, setDataSource]);

  useEffect(() => {
    fetchNetwork();
  }, [fetchNetwork]);

  useEffect(() => {
    if (network) {
      setEntity(id, network.name);
    }
  }, [network, id, setEntity]);

  useEffect(() => {
    if (!isOnboarding) {
      if (network) {
        setInitPodcaster({
          PK: 'USER#382c0264-e96f-4863-b231-ed22a7c8429c', // ML user
          podcastNetworkId: network.PK,
          owners: [],
        });
      }
    }
  }, [network]);

  useEffect(() => {
    if (finish) {
      handlePodcastSubmit();
      setFinish(false);
    }
  }, [finish]);

  if (!network) {
    return <SkeletonLoading />;
  }

  return (
    <>
      <HierarchyHeader
        title={network.name}
        renderExpandedContent={renderExpanded}
        headerType={STRINGS.NETWORK}
      />

      <Box
        sx={{
          display: 'flex',
          mt: 5,
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography
          sx={{
            fontFamily: FONT_FAMILIES.spaceGroteskSemiBold,
            fontSize: 18,
            fontWeight: '500',
          }}
        >
          {STRINGS.PODCASTS}
        </Typography>
      </Box>

      <EntitiesDataGrid
        ref={gridRef}
        columnDefs={columnDefs}
        onCellClicked={handleEntityClick}
        rowModelType={'infinite'}
        onGridReady={setDataSource}
        cacheBlockSize={NETWORK_CHILDREN_BLOCK}
        getRowId={getRowId}
      />
    </>
  );
};

export default NetworkContainer;
