import styled from '@mui/styled-engine';
import { forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import { HEX_COLORS, FONT_FAMILIES } from 'constants/stylesVariables';

export const LinkBehaviour = forwardRef((props, ref) => (
  <RouterLink ref={ref} to="/" {...props} />
));

export const StyledListItemText = styled(ListItem)(({ theme }) => ({
  color: theme.palette.main.lightGray,
}));

export const StyledTopMenuItem = styled(LinkBehaviour)(({ theme }) => ({
  textDecoration: 'none',
  color: theme.palette.secondary.dark,
  alignItems: 'center',
  cursor: 'pointer',
  display: 'flex',
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  width: 200,
  '&:hover': {
    textDecoration: 'none',
    color: theme.palette.secondary.main,
  },
  '&:focus': {
    color: theme.palette.secondary.light,
  },
  '&.active': {
    color: theme.palette.secondary.light,
  },
}));

export const StyledListItem = styled(ListItem)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  marginBottom: theme.spacing(1),
  width: '95%',
  borderRadius: 4,
  paddingTop: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.5),
  '&:hover': {
    backgroundColor: theme.palette.secondary.newPurple, //black
    '& .list-icon': {
      color: theme.palette.main.brandTeal,
    },
  },
  '&:focus': {
    outline: 'none',
    color: theme.palette.secondary.light,
  },
  '&.active': {
    backgroundColor: theme.palette.secondary.newPurple, //black
    '& .MuiListItem-root': {
      fontFamily: FONT_FAMILIES.spaceGroteskBold,
      color: HEX_COLORS.white,
    },
    '& .list-icon': {
      color: theme.palette.main.brandTeal,
    },
    '& .right-icon': {
      display: 'inline-flex',
    },
  },
  textDecoration: 'none',
}));

export const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  color: theme.palette.main.lightGray, //gray
  minWidth: 10,
}));

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.main.main, //white
  width: theme.spacing(3.1),
  height: theme.spacing(3.1),
  borderRadius: 2,
  overflow: 'hidden',
  marginRight: 1,
  '&:hover': {
    backgroundColor: theme.palette.main.main,
  },
}));

export const StyledVersions = styled('div')(
  () => `
    display: inline-block;
    font-size: 0.6em;
    line-height: 2em;
    opacity: 0.5;
    vertical-align: bottom;
    color: 'white';
  `
);
