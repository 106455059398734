import { HEX_COLORS } from 'constants/stylesVariables';

export const PALETTE = {
  mode: 'dark',
  primary: {
    main: HEX_COLORS.darkJungleGreen,
  },
  secondary: {
    main: HEX_COLORS.white,
  },
};

export const LIGHT_PALETTE = {
  mode: 'light',
  primary: {
    main: '#f7f7f7',
  },
  secondary: {
    main: HEX_COLORS.black,
    newPurple: HEX_COLORS.newPurple,
  },
  primaryDark: {
    main: HEX_COLORS.darkJungleGreen,
  },
  secondaryDark: {
    main: HEX_COLORS.white,
  },
  error: {
    main: HEX_COLORS.electricCrimson,
  },
  warning: {
    main: HEX_COLORS.brightYellow,
  },
  info: {
    main: HEX_COLORS.buttonBlue,
  },
  success: {
    main: HEX_COLORS.mantis,
  },
  main: {
    main: HEX_COLORS.white,
    mainPurple: HEX_COLORS.mainPurple,
    sidebarBlack: HEX_COLORS.sidebarBlack,
    iconPurple: HEX_COLORS.iconPurple,
    brandTeal: HEX_COLORS.brandTeal,
    purple4: HEX_COLORS.purple4,
    contrastBackground: HEX_COLORS.contrastBackground,
    lightGray: HEX_COLORS.lightGray,
    iconGray: HEX_COLORS.iconGray,
  },
  primaryButton: {
    main: HEX_COLORS.newPurple,
    contrastText: HEX_COLORS.white,
  },
  secondaryButton: {
    main: HEX_COLORS.buttonGray,
    contrastText: HEX_COLORS.contrastBlack,
  },
  blue: {
    main: HEX_COLORS.blueBerry,
  },
  celticBlue: {
    main: HEX_COLORS.celticBlue,
  },
};
