import { useEffect, useState } from 'react';
import { Box, Container } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { history } from '@utils/history';
import Sidebar from './sidebar';
import { useAuth } from 'contexts/AuthContext';
import { record } from '@utils/analytics';
import { MAIN_ROUTES } from 'constants/routing';
import { LEFT_DRAWER_WIDTH } from 'constants/navigation';
import { TAP_LOGOUT_IN_SIDEBAR } from 'constants/analytics';
import { SELECTED_USER_ACCOUNT_KEY } from 'constants/localStorageKeys';
import useDesktopMobile from 'hooks/useDesktopMobile';
import { requestAPIGraphQL } from '@services/appSyncAPI';
import { getBackendVersion } from '@graphql/queries/main';
import Navbar from '@components/navbar/index';
import { IconButton } from '@mui/material/index';
import MenuIcon from '@mui/icons-material/Menu';

const Layout = ({ children }) => {
  const { logout } = useAuth();
  const [beVersion, setBeVersion] = useState('');
  const [drawerWidth, setDrawerWidth] = useState(LEFT_DRAWER_WIDTH);
  const { isDesktopOrLaptop } = useDesktopMobile();
  const [open, setOpen] = useState(isDesktopOrLaptop);

  const handleSignOut = () => {
    record(TAP_LOGOUT_IN_SIDEBAR);

    logout().then(() => {
      localStorage.setItem(SELECTED_USER_ACCOUNT_KEY, '{}');
      history.push(MAIN_ROUTES.LOGIN);
    });
  };

  useEffect(() => {
    let isMounted = true;
    requestAPIGraphQL(getBackendVersion, null, { authMode: 'API_KEY' }).then(
      ({
        data: {
          getBackendVersion: { value },
        },
      }) => {
        if (isMounted) {
          setBeVersion(value);
        }
      }
    );

    return () => {
      isMounted = false;
    };
  }, []);

  // Toggling the sidebar when "open" state changes
  useEffect(() => {
    setDrawerWidth(open ? LEFT_DRAWER_WIDTH : 0);
  }, [open]);

  useEffect(() => {
    setOpen(isDesktopOrLaptop);
  }, [isDesktopOrLaptop]);

  const toggleNavbar = () => setOpen((prevOpen) => !prevOpen);

  const closeNavbar = () => setOpen(false);

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: `${drawerWidth}px auto`,
        backgroundColor: 'primary.main',
        overflow: 'auto',
        pl: 1,
      }}
    >
      <CssBaseline />
      <Sidebar
        isDesktop={isDesktopOrLaptop}
        version={beVersion}
        onSignOut={handleSignOut}
        open={open}
        onClose={closeNavbar}
        onClick={toggleNavbar}
      />
      {!open && (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={toggleNavbar}
            edge="start"
            style={{ marginLeft: 17, marginTop: 5 }}
          >
            <MenuIcon fontSize="medium" style={{ color: 'black' }} />
          </IconButton>
        </div>
      )}
      <div
        style={{
          position: 'absolute',
          right: 0,
          top: 0,
        }}
      >
        <Navbar onSignOut={handleSignOut} open={open} />
      </div>

      <Box
        component="main"
        sx={{
          maxWidth: '100vw',
          gridColumn: '1 / 3',
          color: 'secondary.main',
          height: '100vh',
          pt: 8,
          ...(isDesktopOrLaptop && {
            maxWidth: `calc(100vw - ${drawerWidth}px)`,
            gridColumn: '2 / 3',
          }),
        }}
      >
        <Container sx={{ pt: 3, position: 'relative' }} maxWidth={false}>
          {children}
        </Container>
      </Box>
    </Box>
  );
};

export default Layout;
