import styled from '@mui/styled-engine';
import { DESKTOP_BREAKPOINT } from 'constants/breakpoints';
import { Box } from '@mui/material';
import { HEX_COLORS, FONT_FAMILIES } from 'constants/stylesVariables';

export const StyledInfoText = styled('p')(
  ({ mt = 0 }) => `
    color: rgba(255, 255, 255, 0.8);
    font-weight: 300;
    margin-top: ${mt}px;
    text-align: center;
    white-space: pre-wrap;

    @media only screen and ${DESKTOP_BREAKPOINT} {
      text-align: initial;
    }
  `
);

export const StyledInnerContainer = styled(Box)(
  () => `
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  min-width: 320px;
`
);

export const StyledTitle = styled('h2')(
  ({ theme, marginTop, mobileMarginTop }) => `
    color: white;
    font-family: ${FONT_FAMILIES.spaceGroteskBold};
    font-size: 40px;
    font-weight: 900;
    line-height: 48.72px;
    margin: 0;
    margin-top: ${marginTop}px;
    text-align: center;
    white-space: pre-wrap;

    @media (max-width: ${theme.breakpoints.values.sm}px) {
      font-size: 36px;
      line-height: 39px;
      margin-top: ${mobileMarginTop}px;
    }
  `
);

export const StyledTextContainer = styled(Box)(
  () => `
    bottom: 0;
  `
);

export const StyledContainer = styled(Box)(
  () => `
    display: flex;
    background-color: ${HEX_COLORS.black};
    height: 100%;
    overflow: auto;
    width: 100%;

    &::-webkit-scrollbar { display: none; }
  `
);

export const StyledDownloadStoreImgContainer = styled(Box)(
  ({ theme }) => `
    display: flex;
    justify-content: center;
    margin-bottom: 33.87px;
    margin-top: 26.87px;

    @media (max-width: ${theme.breakpoints.values.sm}px) {
      margin-bottom: 74.87px;
    }
  `
);
