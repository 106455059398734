import styled from '@mui/styled-engine';
import { IconButton } from '@mui/material';

export const StyledImg = styled('img')(
  ({ theme }) => `
  border-radius: ${theme.shape.borderRadius}px;
  margin-right: ${theme.spacing(1)};
  width: 65px;
  height: 65px;
`
);

export const StyledIconButton = styled(IconButton)(
  () => `
    background-color: lightgray
  `
);
