import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Typography, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { AddPodcastModal } from '@components/containers/index';
import { useAuth } from 'contexts/AuthContext';
import AddYourPodcastContextProvider from 'contexts/AddYourPodcastContext';
import { StyledFullHeight, StyledInnerContainer } from '@shared/styledContainers';
import Spacer from '@shared/spacer';
import { TAP_ADD_YOUR_PODCAST, TAP_LOGOUT } from 'constants/analytics';
import { record } from '@utils/analytics';
import { history } from '@utils/history';
import { requestAPIGraphQL } from '@services/appSyncAPI';
import { PODCASTER } from 'constants/idPrefixes';
import { PodcastInfo } from '@components/containers/AddPodcast/index';
import { getPodcasterById } from '@graphql/queries/podcasters';
import { StyledTextButton } from '@components/containers/LandingPagesContainer/index';
import { Topbar } from '@components/index';
import { MAIN_ROUTES } from 'constants/routing';
import { STRINGS } from 'constants/strings';
import { useAddYourPodcast } from 'contexts/AddYourPodcastContext';

const ClaimPodcastPageWrapper = () => (
  <AddYourPodcastContextProvider isOnboarding>
    <ClaimPodcastPage />
  </AddYourPodcastContextProvider>
);

const ClaimPodcastPage = () => {
  const {
    logout,
    user: {
      userAccount: { entityId },
    },
  } = useAuth();
  const { pathname } = useLocation();
  const [podcaster, setPodcaster] = useState({});
  const theme = useTheme();
  const { setModalOpen, isModalOpen, setInitPodcaster, resetDefaultState, isOnboarding } =
    useAddYourPodcast();

  const handleOpen = () => {
    record(TAP_ADD_YOUR_PODCAST);
    setModalOpen(true);
  };
  const handleLogout = () => {
    record(TAP_LOGOUT, { pathname });
    logout();
    // For a smoother transition to the logout disapearing
    setTimeout(() => {
      history.push(MAIN_ROUTES.HOME);
    }, 300);
  };

  const renderLogoutButton = useCallback(
    () => <StyledTextButton onClick={handleLogout}>{STRINGS.LOGOUT}</StyledTextButton>,
    [logout]
  );

  const stages = useMemo(() => {
    return [
      {
        breadcrumbHeaderTitle: STRINGS.PODCAST_OWNER_INFORMATION,
        component: PodcastInfo,
      },
    ];
  }, []);

  useEffect(() => {
    const fetchListPodcasters = () =>
      requestAPIGraphQL(getPodcasterById, {
        podcasterId: entityId,
      }).then(({ data: { getPodcasterById: _podcaster } }) => {
        if (_podcaster) {
          const { owningCompanyName: companyName, PK, owners = [] } = _podcaster;
          let _newOwners = [];

          owners?.forEach((_owner) => {
            _newOwners.push({
              ..._owner,
              phoneCode: _owner.phoneCode ?? '+1',
            });
          });

          setPodcaster({
            PK,
            id: PK.replaceAll(PODCASTER, ''),
            companyName,
            owners: _newOwners,
          });
        }
      });

    fetchListPodcasters();
  }, [entityId]);

  useEffect(() => {
    if (isModalOpen) {
      if (podcaster) {
        setInitPodcaster(podcaster);
      }
      return;
    } else if (!isModalOpen && isOnboarding) {
      resetDefaultState();
    }
  }, [isModalOpen]);

  return (
    <>
      <StyledFullHeight>
        <StyledInnerContainer>
          <Topbar item={renderLogoutButton()} />

          <Spacer size={50} />

          <Box
            sx={{
              width: '80%',
            }}
          >
            <Typography
              sx={{ mt: 2, mb: 2, color: theme.palette.secondaryDark.main }}
              variant="h2"
            >
              Prepare your podcast for Marbylization
            </Typography>

            <Typography color="info.main" sx={{ mb: 2 }} variant="h6">
              Marbyl allows listeners to create “shareable” clips, called Marbyls, from
              inside your content. All of these Marbyls, both AI and user generated,
              become “discovery” for new listeners and drive downloads for your show.
              <br /> We have launched in English but plan to support more languages in the
              future.
            </Typography>

            <Box sx={{ mb: 2 }}>
              <Typography
                sx={{ mb: 1, color: theme.palette.secondaryDark.main }}
                variant="h4"
              >
                1. Link to your RSS feed
              </Typography>

              <Typography
                sx={{ color: theme.palette.secondaryDark.main }}
                variant="subtitle1"
              >
                Claim your RSS now, so that our AI and actual humans will be equipped to
                find and share moments from your show once we open up our ecosystem to the
                podcaster community.
              </Typography>
            </Box>

            <Box sx={{ mb: 2 }}>
              <Typography
                sx={{ mb: 1, color: theme.palette.secondaryDark.main }}
                variant="h4"
              >
                2. Get to know your audience
              </Typography>

              <Typography
                sx={{ color: theme.palette.secondaryDark.main }}
                variant="subtitle1"
              >
                As a podcaster on our platform, you will receive some of the most
                insightful data about your listeners. You will gain access to a management
                dashboard offering a comprehensive suite of engagement tools and metrics.
              </Typography>
            </Box>

            <Button onClick={handleOpen} variant="outlined" color="secondaryDark">
              Add your podcast
            </Button>
          </Box>
        </StyledInnerContainer>
      </StyledFullHeight>

      <AddPodcastModal stages={stages} />
    </>
  );
};

export default ClaimPodcastPageWrapper;
