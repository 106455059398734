import { useReducer as useReactReducer } from 'react';
import useIsMounted from 'hooks/useIsMounted';

const useReducer = (initialState) => {
  const isMounted = useIsMounted();
  const [store, dispatch] = useReactReducer(
    (state, newState) => ({ ...state, ...newState }),
    initialState
  );

  const setState = (data) => {
    if (isMounted()) dispatch({ ...data });
  };

  return [store, setState];
};

export default useReducer;
