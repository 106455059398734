import { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { requestAPIGraphQL } from '@services/appSyncAPI';
import { STRINGS } from 'constants/strings';
import { toast } from 'react-toastify';
import TOAST_OPTIONS from 'constants/toastOptions';

const useInfiniteRow = ({
  query,
  payload,
  responseKeyName,
  client = requestAPIGraphQL,
  setIsEmpty,
}) => {
  const token = useRef('');

  const apiCall = useCallback(
    () =>
      new Promise((resolve) => {
        client(query, {
          ...payload,
          ...(token.current && { nextToken: token.current }),
        })
          .then(({ data }) => {
            const { [responseKeyName]: response } = data;

            if (!response) {
              throw new Error(STRINGS.AN_ERROR_OCCURED_ON_SERVER__TRY_AGAIN);
            }

            const { items, nextToken } = response;

            if (items.length === 0) {
              if (setIsEmpty) {
                setIsEmpty(true);
              } else {
                toast.warning(STRINGS.NO_RESULTS_WERE_FOUND, TOAST_OPTIONS);
              }
            } else {
              setIsEmpty?.(false);
            }
            resolve({ items, nextToken });
          })
          .catch((err) => toast.error(err.message, TOAST_OPTIONS));
      }),
    [query, payload, responseKeyName, client]
  );

  const getDataSource = useCallback(() => {
    token.current = '';

    return {
      getRows: (rowParams) => {
        apiCall().then(({ items, nextToken }) => {
          if (!nextToken) {
            const { startRow } = rowParams;
            rowParams.successCallback(items, startRow + items.length);
            return;
          }

          token.current = nextToken;
          rowParams.successCallback(items);
        });
      },
    };
  }, [apiCall]);

  return {
    getDataSource,
  };
};

useInfiniteRow.propTypes = {
  query: PropTypes.string.isRequired,
  payload: PropTypes.object,
  responseKeyName: PropTypes.string.isRequired,
  client: PropTypes.func,
};

useInfiniteRow.defaultProps = {
  payload: {},
};

export default useInfiniteRow;
