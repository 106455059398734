import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  EpisodeIcon,
  EpisodeBoldIcon,
  NetworkIcon,
  NetworkBoldIcon,
  PodcasterIcon,
  PodcasterBoldIcon,
} from '@components/icons/index';
import { STRINGS } from 'constants/strings';
import { props, defaultProps } from './entitiesIconsProps';

const EntitiesIcon = ({
  centerIcon,
  defaultImageDimensions,
  height,
  isBold,
  marginTop,
  podcastShowArt,
  type,
  width,
  isHeader,
  color,
}) => {
  const [imageHeight, setImageHeight] = useState(124);
  const [imageWidth, setImageWidth] = useState(124);

  useEffect(() => {
    setImageHeight(defaultImageDimensions ? 40 : height);
    setImageWidth(defaultImageDimensions ? 40 : width);
  }, [defaultImageDimensions, height, width]);

  const getContainerStyle = useCallback(() => {
    return centerIcon
      ? {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
        }
      : {};
  }, [centerIcon]);

  const renderHeaderType = useCallback(() => {
    if (!type) return null;

    const icons = [
      {
        type: STRINGS.NETWORK,
        iconType: isBold ? (
          <NetworkBoldIcon height={height} width={width} color={color} />
        ) : (
          <NetworkIcon height={height} width={width} color={color} />
        ),
      },
      {
        type: STRINGS.PODCASTER,
        iconType: isBold ? (
          <PodcasterBoldIcon height={height} width={width} color={color} />
        ) : (
          <PodcasterIcon height={height} width={width} color={color} />
        ),
      },
      {
        type: STRINGS.PODCAST,
        iconType: podcastShowArt ? (
          <img
            src={podcastShowArt}
            alt={type}
            style={{
              borderRadius: 5,
              height: imageHeight,
              width: imageWidth,
              marginRight: 10,
            }}
          />
        ) : null,
      },
      {
        type: STRINGS.EPISODE,
        iconType: isBold ? (
          <EpisodeBoldIcon height={height} width={width} color={color} />
        ) : (
          <EpisodeIcon height={height} width={width} color={color} />
        ),
      },
    ];
    const iconToRender = icons.find((icon) => icon.type === type);
    const renderIcon = iconToRender.iconType;
    return isHeader && iconToRender?.type !== STRINGS.PODCAST ? (
      <div
        style={{
          backgroundColor: 'white',
          padding: 7,
          borderRadius: 4,
          border: '1px solid #E0E0E0',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
        }}
      >
        {renderIcon}
      </div>
    ) : (
      renderIcon
    );
  }, [height, imageHeight, imageWidth, isBold, podcastShowArt, type, width, isHeader]);

  return <div style={{ marginTop, ...getContainerStyle() }}>{renderHeaderType()}</div>;
};

EntitiesIcon.propTypes = {
  centerIcon: PropTypes.bool,
  defaultImageDimensions: PropTypes.bool,
  isBold: PropTypes.bool,
  marginTop: PropTypes.number,
  podcastShowArt: PropTypes.string,
  type: PropTypes.string.isRequired,
  isHeader: PropTypes.bool,
  ...props,
};

EntitiesIcon.defaultProps = {
  centerIcon: false,
  defaultImageDimensions: false,
  isBold: false,
  marginTop: 0,
  podcastShowArt: '',
  isHeader: false,
  ...defaultProps,
};

export default EntitiesIcon;
