import moment from 'moment';

export const millisToMinutesAndSeconds = (millis) => {
  var minutes = Math.floor(millis / 60000);
  var seconds = ((millis % 60000) / 1000).toFixed(0);
  return minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
};

export const secondsToHms = (d, isString = false) => {
  d = Number(d);
  var h = Math.floor(d / 3600);
  var m = Math.floor((d % 3600) / 60);
  var s = Math.floor((d % 3600) % 60);

  var timeSet = [
    { value: h, suffix: 'h' },
    { value: m, suffix: 'm' },
    { value: s, suffix: 's' },
  ];

  if (!isString) {
    return `${h}:${m}:${s}`;
  } else {
    let stringValue = '';
    timeSet.forEach((time) => {
      if (time.value != 0) {
        stringValue = stringValue.concat(`${time.value}${time.suffix} `);
      }
    });
    return stringValue;
  }
};

export const formatDuration = (d) => {
  const seconds = moment.duration(d).asSeconds();
  return secondsToHms(seconds, true);
};
