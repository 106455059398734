import React, { useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import podcastPlaceholderImg from '@images/podcast.jpeg';
import { props } from './props';

const PodcastArtTitle = ({ podcast }) => {
  const podcastImage = useMemo(() => {
    return (
      podcast?.imageUrl ||
      podcast?.itunes?.image ||
      podcast?.image?.url ||
      podcastPlaceholderImg
    );
  }, [podcast]);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        mt: 2,
        borderBottom: 1.5,
        pb: 2,
        borderColor: 'lightGray',
      }}
    >
      <Box sx={{ width: 115, height: 115, mr: 3 }}>
        <img
          style={{
            width: 'inherit',
            height: 'inherit',
            objectFit: 'contain',
            borderRadius: 4,
          }}
          src={podcastImage}
          alt="Podcast Art"
        />
      </Box>
      <Typography sx={{ fontSize: 17, fontWeight: 500 }}>
        {podcast?.title || 'N/A'}
      </Typography>
    </Box>
  );
};

PodcastArtTitle.propTypes = props;

export default PodcastArtTitle;
