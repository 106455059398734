import React, { useCallback, useMemo } from 'react';
import { Button, Grid } from '@mui/material';
import { STRINGS } from 'constants/strings';
import { record } from '@utils/analytics';
import { TAP_EDIT_PODCAST } from 'constants/analytics';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { MetaInfo } from '@components/index';
import { StyledHeaderSection } from '@shared/styledGeneral';
import { dateToMMDoYYYY } from '@utils/dateFormat';
import { categoriesWithSubsToText } from '@utils/categories';
import { useAuth } from 'contexts/AuthContext';
import { PODCAST_VIEW_ACTIONS } from 'constants/actionsList';
import { NEW_STATUS_ACTIVE } from 'constants/activeStatus';
import { SelectStyles } from '@components/metaInfo/styled.js';

const PodcastDetail = ({
  PK,
  rssFeedLink: rssFeed,
  title,
  subtitle,
  description,
  author,
  owner,
  infoLink,
  imageUrl,
  language,
  podcastType: type,
  copyright,
  categoriesWithSubs,
  explicit,
  isActivated: status,
  isActivatedUpdated: statusDate,
  podcastMarbylizationIsEnabled,
  onClickEdit = () => null,
}) => {
  const { permissions } = useAuth();

  const metaPodcastInfo = useMemo(() => {
    return [
      { label: STRINGS.TITLE, value: title, md: 3, xs: 12 },
      { label: STRINGS.SUBTITLE, value: subtitle, md: 3, xs: 12 },
      {
        label: STRINGS.INFO_LINK,
        value: infoLink,
        md: 3,
        xs: 12,
        renderValue: (val) => (
          <a href={val} target="_blank" rel="noreferrer">
            Link
          </a>
        ),
      },
      {
        label: STRINGS.IMAGE_URL,
        value: imageUrl,
        md: 3,
        xs: 12,
        renderValue: (val) => (
          <a href={val} target="_blank" rel="noreferrer">
            Link
          </a>
        ),
      },

      { label: STRINGS.AUTHOR, value: author?.name || author?.email, md: 3, xs: 12 },
      { label: STRINGS.OWNER, value: owner?.name, md: 3, xs: 12 },
      { label: STRINGS.LANGUAGE, value: language, md: 3, xs: 12 },
      { label: STRINGS.TYPE, value: type, md: 3, xs: 12 },

      {
        label: STRINGS.COMPLETE,
        value: title,
        renderValue: (val) => (val ? 'Yes' : 'No'),
        md: 3,
        xs: 12,
      },
      { label: STRINGS.COPYRIGHT, value: copyright, md: 3, xs: 12 },
      {
        label: STRINGS.CATEGORIES,
        value: categoriesWithSubsToText(categoriesWithSubs),
        md: 3,
        xs: 12,
      },
      {
        label: STRINGS.EXPLICIT,
        value: explicit,
        renderValue: (val) => (val ? 'Yes' : 'No'),
        md: 3,
        xs: 12,
      },
      { label: STRINGS.DESCRIPTION, value: description, md: 12, xs: 12 },
    ];
  }, [
    title,
    subtitle,
    imageUrl,
    author,
    owner,
    categoriesWithSubs,
    copyright,
    description,
    explicit,
    language,
    type,
    infoLink,
  ]);

  const metaPodcastAvailability = useMemo(() => {
    return [
      {
        label: STRINGS.PODCAST_IS_ACTIVATED,
        value: status,
        renderValue: (val) => (val ? 'Yes' : 'No'),
        md: 4,
        xs: 12,
      },
      {
        label: STRINGS.DATE_UPDATED,
        value: statusDate,
        md: 4,
        xs: 12,
        renderValue: (val) => dateToMMDoYYYY(val),
      },
    ];
  }, [status, statusDate]);

  const metaMarbylizationSettings = useMemo(() => {
    return [
      {
        label: STRINGS.ENABLED,
        value: podcastMarbylizationIsEnabled,
        renderValue: (val) => (val === NEW_STATUS_ACTIVE ? 'Yes' : 'No'),
        md: 4,
        xs: 12,
      },
    ];
  }, [podcastMarbylizationIsEnabled]);

  const renderRssData = useCallback(() => {
    return permissions[PODCAST_VIEW_ACTIONS.RSS_SOURCED_DATA] ? (
      <>
        <Grid container sx={{ mt: 2, mb: 2 }}>
          <Grid item xs={10} md={11}>
            <StyledHeaderSection variant="h6">
              {STRINGS.RSS_INFORMATION}:{' '}
              <a
                href={rssFeed}
                target="_blank"
                rel="noreferrer"
                style={{ ...SelectStyles, color: '' }}
              >
                {rssFeed}
              </a>
            </StyledHeaderSection>
          </Grid>

          <Grid
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              flexDirection: 'row-reverse',
            }}
            item
            xs={2}
            md={1}
          >
            <Button
              onClick={() => {
                record(TAP_EDIT_PODCAST, { id: PK });
                onClickEdit(PK);
              }}
              variant="outlined"
              color="secondary"
              sx={{
                border: '1.5px solid #4623E4',
                textTransform: 'none',
              }}
            >
              {STRINGS.EDIT}
              <EditOutlinedIcon sx={{ ml: 1, fontSize: 18 }} />
            </Button>
          </Grid>
        </Grid>

        <MetaInfo items={metaPodcastInfo} />
      </>
    ) : null;
  }, [permissions, metaPodcastInfo, rssFeed, permissions, PK, onClickEdit]);

  const renderPodcastAvailabilityData = useCallback(() => {
    return permissions[PODCAST_VIEW_ACTIONS.PLATFORM_SOURCED_DATA] ? (
      <>
        <StyledHeaderSection sx={{ mt: 4 }} variant="h6">
          {STRINGS.PODCAST_AVAILABILITY}
        </StyledHeaderSection>
        <MetaInfo items={metaPodcastAvailability} />
      </>
    ) : null;
  }, [permissions, metaPodcastAvailability]);

  const renderMarbylizationSettings = useCallback(() => {
    return permissions[PODCAST_VIEW_ACTIONS.M11N_RELATED_DATA] ? (
      <>
        <StyledHeaderSection sx={{ mt: 4 }} variant="h6">
          {STRINGS.MARBYLIZATION_SETTINGS}
        </StyledHeaderSection>

        <MetaInfo items={metaMarbylizationSettings} />
      </>
    ) : null;
  }, [permissions, metaMarbylizationSettings]);

  return (
    <>
      {renderRssData()}
      {renderPodcastAvailabilityData()}
      {renderMarbylizationSettings()}
    </>
  );
};

export default PodcastDetail;
