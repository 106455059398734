import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { Typography, Box } from '@mui/material';
import { useHistory, useLocation } from 'react-router';
import { STRINGS } from 'constants/strings';
import { FONT_FAMILIES } from 'constants/stylesVariables';
import {
  EntitiesDataGrid,
  HierarchyHeader,
  SimpleDropdown,
  SkeletonLoading,
  EllipsisWrapper,
  CheckAll,
} from '@components/index';
import { useEntityNames } from 'contexts/EntitiesNamesContext';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { requestAPIGraphQL } from '@services/appSyncAPI';
import { getEpisodeById, listMarbylsByEpisode } from '@graphql/queries/episodes';
import { EPISODE_PREFIX, MARBYL_PREFIX } from 'constants/idPrefixes';
import {
  activateDeactivateEpisodes,
  enableDisableEpisodesMarbylization,
} from '@graphql/mutations/episodes';
import { toggleMarbylStatus } from '@graphql/mutations/marbyls';
import { toast } from 'react-toastify';
import TOAST_OPTIONS from 'constants/toastOptions';
import { replaceLiterals } from '@utils/replaceLiterals';
import { ACTIVATION, MARBYLIZATION } from 'constants/episodeActions';
import { DEFAULT_LIMIT } from 'constants/appSync';
import useInfiniteRow from 'hooks/useInfiniteRow';
import { errorLog } from '@utils/logs';
import { EPISODE_VIEW_ACTIONS } from 'constants/actionsList';
import { useAuth } from 'contexts/AuthContext';
import { MPC_ROUTES } from 'constants/routing';
import EpisodeDetail from './detail';

const marbylsActions = {
  ACTIVATE_MARBYLS: {
    key: 'activate',
    label: 'Activate Marbyls',
  },
  DEACTIVATE_MARBYLS: { key: 'deactivate', label: 'Deactivate Marbyls' },
};

const MARBYLS_BLOCK = DEFAULT_LIMIT;

const EpisodeContainer = ({ id }) => {
  const gridRef = useRef();
  const history = useHistory();
  const { pathname } = useLocation();
  const [episode, setEpisode] = useState(null);
  const [selMarbyls, setSelMarbyls] = useState([]);
  const setEntity = useEntityNames()[1];
  const { permissions } = useAuth();

  const getRowId = useCallback(function (params) {
    return params.data.PK;
  }, []);

  const columnDefs = useMemo(
    () => [
      {
        field: '',
        checkboxSelection: true,
        headerName: '',
        width: 110,
        cellStyle: { display: 'flex', alignItems: 'center' },
        headerComponent: () => <CheckAll ref={gridRef} />,
      },
      {
        field: 'source',
        headerName: 'Source',
        width: 150,
        cellRenderer: EllipsisWrapper,
      },
      {
        field: 'text',
        headerName: 'Marbyl Text',
        minWidth: 250,
        flex: 1,
        cellRenderer: EllipsisWrapper,
      },
      {
        field: 'isActivated',
        headerName: 'Status',
        cellRenderer: (params) => {
          const { value } = params;

          if (value) {
            return <CheckIcon color="success" />;
          }

          return <CloseIcon color="error" />;
        },
        width: 120,
        cellStyle: { display: 'flex', alignItems: 'center' },
      },
    ],
    []
  );

  const { getDataSource } = useInfiniteRow({
    query: listMarbylsByEpisode,
    payload: {
      episodeId: `${EPISODE_PREFIX}${id}`,
      limit: MARBYLS_BLOCK,
      showInactiveItems: true,
    },
    responseKeyName: 'listMarbylsByEpisodeId',
  });

  const setMarbylsDataSource = useCallback(
    () => gridRef.current.api.setDatasource(getDataSource(true)),
    [getDataSource]
  );

  const loadEpisode = useCallback(
    () =>
      requestAPIGraphQL(getEpisodeById, { episodeId: `${EPISODE_PREFIX}${id}` })
        .then(({ data: { getMPCEpisodeById } }) => {
          setEpisode(getMPCEpisodeById);
        })
        .catch(),
    [id]
  );

  const onClickAction = (action) => {
    const isActivated = action === 'activate';
    const rows = gridRef.current.api
      .getSelectedRows()
      .filter((row) => isActivated !== row.isActivated);
    if (rows.lenght === 0) {
      gridRef.current.api.deselectAll();
      return;
    }
    const promises = rows.map(({ PK }) =>
      requestAPIGraphQL(toggleMarbylStatus, { marbylId: PK })
    );
    Promise.all(promises)
      .then(() => {
        rows.forEach(({ PK }) => {
          const rowNode = gridRef.current.api.getRowNode(PK);
          rowNode.setDataValue('isActivated', isActivated);
        });
        gridRef.current.api.deselectAll();
      })
      .catch((err) => {
        errorLog(err);
        toast.error(STRINGS.AN_ERROR_OCCURED_ON_SERVER__TRY_AGAIN, TOAST_OPTIONS);
      });
  };

  const handleRowClick = ({ PK }) => {
    const _id = PK.replace(MARBYL_PREFIX, '');
    setEntity(_id, 'Marbyl', 'marbyls');
    history.push(`${pathname}${MPC_ROUTES.MARBYLS}/${_id}`);
  };

  const renderEpisodeDetail = useCallback(() => {
    const onClickEpisodeAction = (type, value) => {
      const { PK } = episode;
      let message = '';
      let query = '';
      let payload = {
        episodeIds: [PK],
      };

      switch (type) {
        case ACTIVATION: {
          payload.isActivated = value;
          message = replaceLiterals(
            value ? STRINGS.EPISODE_NAME_ACTIVATED : STRINGS.EPISODE_NAME_DEACTIVATED,
            { episodeName: episode.title }
          );
          query = activateDeactivateEpisodes;

          break;
        }

        case MARBYLIZATION: {
          payload.isEnabled = value;
          message = replaceLiterals(
            value
              ? STRINGS.EPISODE_NAME_MARBYLIZATION_ENABLED
              : STRINGS.EPISODE_NAME_MARBYLIZATION_DISABLE,
            { episodeName: episode.title }
          );
          query = enableDisableEpisodesMarbylization;

          break;
        }

        default:
          return;
      }

      requestAPIGraphQL(query, payload)
        .then(() => {
          toast.success(message, TOAST_OPTIONS);
          loadEpisode();
        })
        .catch(() => {
          toast.error(STRINGS.AN_ERROR_OCCURED_ON_SERVER__TRY_AGAIN, TOAST_OPTIONS);
        });
    };

    return <EpisodeDetail onClickAction={onClickEpisodeAction} {...episode} />;
  }, [episode, loadEpisode]);

  useEffect(() => {
    loadEpisode();
  }, [loadEpisode]);

  if (!episode) return <SkeletonLoading />;

  return (
    <>
      <HierarchyHeader
        title={episode.title}
        headerType={STRINGS.EPISODE}
        {...(permissions[EPISODE_VIEW_ACTIONS.VIEW_EPISODE_DETAILS] && {
          renderExpandedContent: renderEpisodeDetail,
        })}
      />

      <Box
        sx={{
          display: 'flex',
          mt: 5,
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography
          sx={{
            fontFamily: FONT_FAMILIES.spaceGroteskSemiBold,
            fontSize: 18,
            fontWeight: '500',
          }}
        >
          {STRINGS.MARBYLS}
        </Typography>
        {permissions[EPISODE_VIEW_ACTIONS.TOGGLE_MARBYLS_STATUS] && (
          <SimpleDropdown
            disabled={selMarbyls.length === 0}
            actions={Object.values(marbylsActions)}
            onClickAction={onClickAction}
          />
        )}
      </Box>

      <EntitiesDataGrid
        ref={gridRef}
        columnDefs={columnDefs}
        onCellClicked={({ data }) => handleRowClick(data)}
        onSelectionChanged={(evt) => {
          setSelMarbyls(evt.api.getSelectedRows());
        }}
        rowModelType={'infinite'}
        onGridReady={setMarbylsDataSource}
        cacheBlockSize={MARBYLS_BLOCK}
        getRowId={getRowId}
      />
    </>
  );
};

export default EpisodeContainer;
