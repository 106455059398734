import React from 'react';

const EllipsisWrapper = ({ value }) => {
  return (
    <div
      style={{
        display: 'block',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      }}
    >
      {value}
    </div>
  );
};

export default EllipsisWrapper;
