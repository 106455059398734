import React, { useState, useCallback, useRef } from 'react';
import { Box, Button, Modal, Fade } from '@mui/material';
import { STRINGS } from 'constants/strings';
import { Stepper } from '@components/index';
import { useMemo } from 'react';
import { useAddYourPodcast } from 'contexts/AddYourPodcastContext';
import CloseIcon from '@mui/icons-material/Close';
import { useHistory } from 'react-router-dom';
import { PARTNERS_ROUTES } from 'constants/routing';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import Spacer from '@shared/spacer';
import useDesktopMobile from 'hooks/useDesktopMobile';
import poweredByLogo from '@images/powered-by-listen-notes-white.png';
import {
  TAP_NEXT_FROM_FEED_CONFIRMATION,
  TAP_NEXT_TO_CONFIRM_PODCAST_INFO,
  TAP_X_CLOSE_ADD_PODCAST_MODAL,
} from 'constants/analytics';
import {
  FeedConfirmation,
  PodcastInfo,
  ReviewSubmit,
  SendCode,
  VerifyCode,
} from '../index';
import { record } from '@utils/analytics';
import useRecordAnalyticsPodcast from 'hooks/useRecordAnalyticsPodcast';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  bgcolor: 'primary.main',
  border: '2px solid #000',
  boxShadow: 24,
  pl: 2,
  pr: 2,
  pt: 2,
  pb: 2,
  height: '100vh',
  color: 'secondary.main',
  '&:focus-visible': {
    outline: 'none',
  },
};

const steps = ['Verify RSS', 'Add Podcast Info', 'Review & Submit'];

const STEPS_DIRECTIONS = { l: 'l', r: 'r' };

const AddYourPodcastModal = ({ open, redirect = true, isOnboarding = false }) => {
  const { renderMobileComponent } = useDesktopMobile();
  const [headerHeight, setHeaderHeight] = useState(0);
  const [modalHeight, setModalHeight] = useState(0);
  const [activeStep, setActiveStep] = useState(0);
  const { resetDefaultState, finish } = useAddYourPodcast();
  const modalRef = useRef(null);
  const headerRef = useRef(null);

  const { recordAnalyticsPodcast } = useRecordAnalyticsPodcast();

  const modalCallback = useCallback((ref) => {
    if (!ref) return;

    modalRef.current = ref;
    setModalHeight(ref.clientHeight);
  }, []);

  const headerCallback = useCallback((ref) => {
    if (!ref) return;

    headerRef.current = ref;
    setHeaderHeight(ref.clientHeight);
  }, []);

  const history = useHistory();

  const contentHeight = useMemo(
    () => (modalHeight > headerHeight ? modalHeight - headerHeight - 32 : modalHeight),
    [modalHeight, headerHeight]
  );

  const breadcrumbActiveStep = useMemo(() => {
    if (activeStep < 3) return 0;

    return activeStep - 3 + 1;
  }, [activeStep]);

  const handleRedirect = useCallback(
    () => history.push(PARTNERS_ROUTES.PODCASTS),
    [history]
  );

  const handleReset = useCallback(() => {
    setActiveStep(0);
    resetDefaultState();
  }, [resetDefaultState]);

  const handleClose = useCallback(() => {
    handleReset();
  }, [handleReset]);

  const onNextBackClick = useCallback(
    (direction, event) => {
      event && recordAnalyticsPodcast(event);

      if (direction === STEPS_DIRECTIONS.l) {
        if (activeStep === 0) {
          handleClose();
          return;
        }

        setActiveStep((prev) => prev - 1);

        return;
      }

      if (direction === STEPS_DIRECTIONS.r) {
        if (breadcrumbActiveStep === steps.length - 1) {
          return;
        }

        setActiveStep((prev) => prev + 1);
      }
    },
    [activeStep, breadcrumbActiveStep, handleClose, recordAnalyticsPodcast]
  );

  const renderBackBtn = useCallback(
    (event) => {
      return (
        <Button
          color="secondary"
          variant="outlined"
          onClick={() => {
            onNextBackClick(STEPS_DIRECTIONS.l, event);
          }}
          sx={{ mr: 2 }}
        >
          {activeStep === 0 ? STRINGS.CANCEL : STRINGS.PREVIOUS}
        </Button>
      );
    },
    [activeStep, onNextBackClick]
  );

  const renderCurrentStep = useCallback(() => {
    // if (finish) {
    //   return (
    //     <WelcomeMessage
    //       isClaim={isOnboarding}
    //       handleReset={() => {
    //         recordAnalyticsPodcast(TAP_DONE_FROM_WELCOME_TO_MARBYL);
    //         handleReset();

    //         if (redirect) {
    //           handleRedirect();
    //         }
    //       }}
    //     />
    //   );
    // }

    switch (activeStep) {
      case 0:
        return (
          <FeedConfirmation
            renderBackBtn={renderBackBtn}
            onNextClick={() =>
              onNextBackClick(STEPS_DIRECTIONS.r, TAP_NEXT_FROM_FEED_CONFIRMATION)
            }
          />
        );

      case 1:
        return (
          <SendCode
            renderBackBtn={renderBackBtn}
            onNextClick={() => onNextBackClick(STEPS_DIRECTIONS.r)}
          />
        );

      case 2:
        return (
          <VerifyCode
            renderBackBtn={renderBackBtn}
            onNextClick={() => onNextBackClick(STEPS_DIRECTIONS.r)}
          />
        );

      case 3:
        return (
          <PodcastInfo
            renderBackBtn={renderBackBtn}
            onNextClick={() =>
              onNextBackClick(STEPS_DIRECTIONS.r, TAP_NEXT_TO_CONFIRM_PODCAST_INFO)
            }
          />
        );

      case 4:
        return <ReviewSubmit renderBackBtn={renderBackBtn} />;

      default:
        return null;
    }
  }, [
    activeStep,
    renderBackBtn,
    onNextBackClick,
    handleReset,
    handleRedirect,
    redirect,
    recordAnalyticsPodcast,
    isOnboarding,
  ]);

  const renderStepper = useCallback(() => {
    return !finish && <Stepper steps={steps} activeStep={breadcrumbActiveStep} />;
  }, [finish, breadcrumbActiveStep]);

  const renderModalContent = useCallback(() => {
    return (
      open && (
        <>
          <Box ref={headerCallback}>
            <CloseIcon
              onClick={() => {
                record(TAP_X_CLOSE_ADD_PODCAST_MODAL);
                if (finish) {
                  handleReset();
                  return;
                }

                handleClose();
              }}
              fontSize="large"
              sx={{ position: 'absolute', top: 8, left: 8, cursor: 'pointer' }}
            />
            {renderMobileComponent(() => (
              <Spacer size={32} />
            ))}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              {renderStepper()}
            </Box>
          </Box>

          <Box
            sx={{
              overflow: 'auto',
              height: `${contentHeight}px`,
            }}
          >
            {renderCurrentStep()}
          </Box>
        </>
      )
    );
  }, [
    open,
    finish,
    handleClose,
    handleReset,
    renderStepper,
    renderCurrentStep,
    renderMobileComponent,
    headerCallback,
    contentHeight,
  ]);

  const renderPoweredByLogo = useCallback(() => {
    if (activeStep !== 0) {
      return null;
    }

    return (
      <img
        style={{ position: 'absolute', bottom: 0, left: 0, width: 200 }}
        src={poweredByLogo}
        alt="Powered by Listen Notes"
      />
    );
  }, [activeStep]);

  useEffect(() => {
    toast.dismiss();
  }, [activeStep, open]);

  useEffect(() => {
    const resizeCallback = () => {
      if (!modalRef.current || !headerRef.current) {
        return;
      }
      setModalHeight(modalRef.current.clientHeight);
      setHeaderHeight(headerRef.current.clientHeight);
    };

    window.addEventListener('resize', resizeCallback);

    return () => {
      window.removeEventListener('resize', resizeCallback);
    };
  }, []);

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Fade in={open}>
        <Box ref={modalCallback} sx={style}>
          {renderPoweredByLogo()}
          <Box
            sx={{
              maxWidth: 1400,
              width: '100%',
              margin: '0 auto',
            }}
          >
            {renderModalContent()}
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default AddYourPodcastModal;
