import React, { useCallback, useEffect, useState } from 'react';
import {
  Grid,
  TextField,
  MenuItem,
  Box,
  Typography,
  Button,
  Checkbox,
  ListItemText,
  OutlinedInput,
  FormControl,
  InputLabel,
  Select,
  FormControlLabel,
  Chip,
} from '@mui/material';
import { PrimaryButton } from '@shared/styledInputs';
import { STRINGS } from 'constants/strings';
import { FIELD_TYPES } from 'constants/dynamicFormTypes';
import {
  CheckboxSelect,
  CountriesSelect,
  FieldArrayInput,
  ImagePreview,
  PodcastSearch,
} from '@components/index';
import { useForm, Controller } from 'react-hook-form';
import { propTypes } from './props';
import MuiPhoneNumber from 'material-ui-phone-number';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const DynamicForm = ({
  formHeader,
  formHeaderVariant = 'h4',
  fields,
  onSubmit,
  validations = {},
  initialValues = {},
  onCancel = null,
}) => {
  const {
    handleSubmit,
    control,
    formState: { isDirty, isSubmitting },
    setValue,
    getValues,
  } = useForm({
    defaultValues: initialValues,
  });

  const [multipleItemsByName, setMultipleItemsByName] = useState({});

  const _onSubmit = (data) => {
    onSubmit(data);
  };

  const renderFields = useCallback(() => {
    return fields.map((field, index) => {
      const { type, color = 'primary', md, sm, xs, inputType, ...fieldProps } = field;

      const validationsProps = Object.prototype.hasOwnProperty.call(
        validations,
        fieldProps.name
      )
        ? validations[fieldProps.name]({ values: getValues() })
        : {};

      return (
        <Grid key={index} item md={md || 12} sm={sm || 12} xs={xs || 12}>
          {type === FIELD_TYPES.TITLE && (
            <Typography variant={fieldProps.variant || 'h6'}>
              {fieldProps.label}
            </Typography>
          )}

          {type === FIELD_TYPES.TEXT && (
            <Controller
              control={control}
              name={fieldProps.name}
              render={({ field }) => (
                <TextField
                  {...fieldProps}
                  {...validationsProps}
                  {...field}
                  {...(inputType && { type: inputType })}
                  fullWidth
                  color={color}
                />
              )}
            />
          )}

          {type === FIELD_TYPES.CHECKBOX && (
            <Controller
              control={control}
              name={fieldProps.name}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      {...field}
                      {...validationsProps}
                      disabled={fieldProps.disabled || false}
                      color={color}
                      checked={field.value}
                      disableRipple
                    />
                  }
                  label={fieldProps.label}
                />
              )}
            />
          )}

          {type === FIELD_TYPES.SELECT && (
            <Controller
              control={control}
              name={fieldProps.name}
              render={({ field }) => {
                const { required, label, size, items, disabled = false } = fieldProps;

                return (
                  <TextField
                    {...field}
                    required={required}
                    size={size}
                    select
                    color={color}
                    id={`select-${field.name}`}
                    label={label}
                    fullWidth
                    disabled={disabled}
                    {...validationsProps}
                  >
                    {items.map((_item) => {
                      if (typeof _item === 'object') {
                        return (
                          <MenuItem key={_item.value} value={_item.value}>
                            {_item.label}
                          </MenuItem>
                        );
                      }

                      return (
                        <MenuItem key={_item} value={_item}>
                          {_item}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                );
              }}
            />
          )}

          {type === FIELD_TYPES.SELECT_MULTIPLE && (
            <Controller
              control={control}
              name={fieldProps.name}
              render={({ field }) => {
                const {
                  required,
                  label,
                  size,
                  items,
                  valueName,
                  labelName,
                  disabled = false,
                } = fieldProps;

                return (
                  <FormControl
                    size={size || 'medium'}
                    required={required || false}
                    fullWidth
                    color={color}
                    disabled={disabled}
                  >
                    <InputLabel id={`select-multiple-${field.name}-label`}>
                      {label}
                    </InputLabel>
                    <Select
                      labelId={`select-multiple-${field.name}-label`}
                      id={`select-multiple-${field.name}`}
                      name={field.name}
                      multiple
                      input={<OutlinedInput label={label} />}
                      renderValue={(selected) => {
                        if (!multipleItemsByName[field.name]) return '';

                        const _items = selected.map((sel) => ({
                          key: sel,
                          label: multipleItemsByName[field.name][sel],
                        }));

                        return (
                          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {_items.map((item) => (
                              <Chip
                                onMouseDown={(event) => {
                                  event.stopPropagation();
                                }}
                                key={item.key}
                                label={item.label}
                                {...(!disabled && {
                                  onDelete: () => {
                                    const _selected = selected.filter(
                                      (_sel) => _sel !== item.key
                                    );

                                    setValue(
                                      field.name,
                                      typeof _selected === 'string'
                                        ? _selected.split(',')
                                        : _selected,
                                      { shouldDirty: true }
                                    );
                                  },
                                })}
                              />
                            ))}
                          </Box>
                        );
                      }}
                      MenuProps={MenuProps}
                      value={field.value}
                      required={required || false}
                      onChange={(evt) => {
                        const {
                          target: { value },
                        } = evt;

                        setValue(
                          field.name,
                          typeof value === 'string' ? value.split(',') : value,
                          { shouldDirty: true }
                        );
                      }}
                      {...validationsProps}
                    >
                      {items.map((_item) => (
                        <MenuItem key={_item[valueName]} value={_item[valueName]}>
                          <Checkbox
                            color={color}
                            checked={field.value.indexOf(_item[valueName]) > -1}
                          />
                          <ListItemText primary={_item[labelName]} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                );
              }}
            />
          )}

          {type === FIELD_TYPES.PODCAST_SEARCH && (
            <PodcastSearch
              rssState={fieldProps.rssState}
              onRSSLoad={fieldProps.onRSSLoad}
            />
          )}

          {type === FIELD_TYPES.CHECKBOX_SELECT && (
            <Controller
              control={control}
              name={fieldProps.name}
              render={({ field }) => {
                const {
                  checkbox: { label: chkLabel, initialValue },
                  required,
                  label,
                  size,
                  items,
                  valueName,
                  labelName,
                  checkedPlaceholder,
                  uncheckedPlaceholder,
                  helperText,
                } = fieldProps;

                return (
                  <CheckboxSelect
                    checkbox={{
                      label: chkLabel,
                      initialValue: initialValue,
                    }}
                    color={color}
                    select={{
                      required: required || false,
                      size: size || 'medium',
                      name: field.name,
                      label,
                      value: field.value || [],
                      items,
                      handleChange: (newValue) =>
                        setValue(field.name, newValue, {
                          shouldDirty: true,
                        }),
                      valueName,
                      labelName,
                      checkedPlaceholder,
                      uncheckedPlaceholder,
                      helperText,
                    }}
                  />
                );
              }}
            />
          )}

          {type === FIELD_TYPES.IMAGE && (
            <Controller
              control={control}
              name={fieldProps.name}
              render={({ field }) => {
                const { label } = fieldProps;

                return (
                  <ImagePreview
                    color={color}
                    label={label}
                    name={field.name}
                    src={field.value || ''}
                    onSelectedImage={(src) =>
                      setValue(field.name, src, { shouldDirty: true })
                    }
                  />
                );
              }}
            />
          )}

          {type === FIELD_TYPES.LIST && (
            <FieldArrayInput
              control={control}
              name={fieldProps.name}
              headerColumns={fieldProps.headerColumns}
              inputs={fieldProps.inputs}
              label={fieldProps.label}
              addButtonLabel={fieldProps.addButtonLabel || ''}
            />
          )}

          {type === FIELD_TYPES.COUNTRIES_SELECT && (
            <Controller
              control={control}
              name={fieldProps.name}
              render={({ field }) => {
                const { label } = fieldProps;

                return (
                  <CountriesSelect
                    {...(label && { label })}
                    color={color}
                    name={field.name}
                    value={field.value}
                    onChange={(newVal) =>
                      setValue(field.name, newVal, { shouldDirty: true })
                    }
                  />
                );
              }}
            />
          )}

          {type === FIELD_TYPES.PHONE_NUMBER && (
            <Controller
              control={control}
              name={fieldProps.name}
              render={({ field }) => {
                const { label } = fieldProps;

                return (
                  <MuiPhoneNumber
                    {...field}
                    fullWidth
                    defaultCountry={'us'}
                    variant="outlined"
                    color={color}
                    label={label}
                  />
                );
              }}
            />
          )}
        </Grid>
      );
    });
  }, [fields, multipleItemsByName, validations, control, setValue, getValues]);

  useEffect(() => {
    let selectMultipleHashByName = {};

    for (let index = 0; index < fields.length; index++) {
      const field = fields[index];

      if (field.type === FIELD_TYPES.SELECT_MULTIPLE) {
        const temp = field.items.reduce(
          (obj, key) => ({
            ...obj,
            [key[field.valueName]]: key[field.labelName],
          }),
          {}
        );

        selectMultipleHashByName = {
          ...selectMultipleHashByName,
          [field.name]: temp,
        };
      }
    }

    setMultipleItemsByName(selectMultipleHashByName);
  }, [fields, initialValues]);

  return (
    <Box>
      <Typography color="secondary" variant={formHeaderVariant} sx={{ mb: 2 }}>
        {formHeader}
      </Typography>
      <form onSubmit={handleSubmit(_onSubmit)}>
        <Grid container spacing={2}>
          {renderFields()}
        </Grid>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
          {onCancel && (
            <Button
              sx={{ mr: 2 }}
              onClick={onCancel}
              variant="outlined"
              color="secondary"
            >
              {STRINGS.CANCEL}
            </Button>
          )}
          <PrimaryButton
            disabled={!isDirty || isSubmitting}
            type="submit"
            variant="contained"
            color="primaryButton"
          >
            {STRINGS.SAVE}
          </PrimaryButton>
        </Box>
      </form>
    </Box>
  );
};

DynamicForm.propTypes = propTypes;

export default DynamicForm;
