import { cloneElement } from 'react';
import { useTheme } from '@mui/material/styles';
import { IconButton, Drawer, Toolbar, List } from '@mui/material/index';
import MenuIcon from '@mui/icons-material/Menu';

import { PODCAST_NETWORK_VIEW_ACTIONS } from 'constants/actionsList';
import { ROUTES } from 'constants/routing';
import { STRINGS } from 'constants/strings';
import { LEFT_DRAWER_WIDTH } from 'constants/navigation';
import { useAddYourPodcast } from 'contexts/AddYourPodcastContext';
// import useDesktopMobile from 'hooks/useDesktopMobile';
import MainMarbylIcon from '@components/icons/mainMarbylIcon';

import {
  StyledListItem,
  LinkBehaviour,
  StyledListItemIcon,
  StyledIconButton,
  StyledVersions,
  StyledListItemText,
} from './layout.styled';
import { APP_VERSION } from 'constants/secrets';
import { useAuth } from 'contexts/AuthContext';
import { useMPC } from 'contexts/MPCContext';

const Sidebar = ({ open, onClose, version, onClick }) => {
  const { permissions } = useAuth();
  const { getIsSuperUser } = useMPC();
  const theme = useTheme();
  const { setModalOpen } = useAddYourPodcast();

  // const { isDesktopOrLaptop } = useDesktopMobile();

  const getRoutesList = () => {
    const isSuperUser = getIsSuperUser();
    const hasReportedContentAccess =
      permissions[PODCAST_NETWORK_VIEW_ACTIONS?.GO_TO_CONTENT_REPORTS_PAGE] &&
      isSuperUser;
    let updatedRoutes = [...ROUTES];

    if (!getIsSuperUser()) {
      const currentUserRoutes = [STRINGS.DASHBOARD, STRINGS.PODCASTS, STRINGS.USERS];
      updatedRoutes = updatedRoutes.filter((updatedRoute) =>
        currentUserRoutes.includes(updatedRoute.label)
      );
    } else {
      if (!hasReportedContentAccess)
        updatedRoutes = updatedRoutes.filter(
          (updatedRoute) => updatedRoute.label !== STRINGS.REPORTED_CONTENT
        );

      if (!isSuperUser)
        updatedRoutes = updatedRoutes.filter(
          (updateRoute) => updateRoute.label !== STRINGS.TRANSCRIPT_REVIEWS
        );
    }

    const onRighActionClick = (event) => {
      event.preventDefault();
      setModalOpen(true);
    };

    return updatedRoutes.map((item, index) => (
      <StyledListItem
        activeClassName="active"
        to={item.to}
        component={LinkBehaviour}
        key={index}
        secondaryAction={
          item?.rightAction && (
            <StyledIconButton
              edge="end"
              sx={{ display: 'none' }}
              className="right-icon"
              type="button"
              onClick={(event) => onRighActionClick(event)}
            >
              {cloneElement(item.rightAction.icon, {
                sx: { fontSize: '1.2rem', color: 'main.purple4' },
              })}
            </StyledIconButton>
          )
        }
      >
        <StyledListItemIcon className="list-icon" sx={{ color: 'inherit' }}>
          {item.icon}
        </StyledListItemIcon>
        <StyledListItemText>{item.label}</StyledListItemText>
      </StyledListItem>
    ));
  };

  return (
    <Drawer
      sx={{
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          color: 'secondary.main',
          display: 'flex',
          justifyContent: 'flex-start',
          width: LEFT_DRAWER_WIDTH,
          boxSizing: 'border-box',
          backgroundColor: theme.palette.main.sidebarBlack,
          backgroundImage: 'none',
        },
      }}
      variant="persistent"
      open={open}
      onClose={onClose}
      anchor="left"
    >
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={onClick}
          edge="start"
          style={{ marginLeft: 17 }}
        >
          <MenuIcon fontSize="medium" style={{ color: 'white' }} />
        </IconButton>
        <MainMarbylIcon isDarkTheme={false} isRecording={true} />
      </div>

      <List>{getRoutesList()}</List>
      <Toolbar
        sx={{
          position: 'absolute',
          bottom: 0,
          left: 10,
          marginBottom: 1,
          color: 'white',
        }}
      >
        <StyledVersions>{`${APP_VERSION}/1.${version}`}</StyledVersions>
      </Toolbar>
    </Drawer>
  );
};

export default Sidebar;
