import { useMemo } from 'react';
import { Layout } from '@components/index';
import MPCPage from '@pages/MPCPage/MPCPage';
import { MAIN_ROUTES } from 'constants/routing';
import { useAuth } from 'contexts/AuthContext';
import { useMPC } from 'contexts/MPCContext';
import { PODCAST_NETWORK_VIEW_ACTIONS } from 'constants/actionsList';
import { Switch, Redirect, Route } from 'react-router';
import UsersRoutes from './UsersRoutes_v2';
import MPCReportsRoutes from './MPCReportsRoutes';
import TranscriptionErrorsRoutes from './TranscriptionErrorsRoutes';
import AddYourPodcastContextProvider from 'contexts/AddYourPodcastContext';
import EntitiesNamesProvider from 'contexts/EntitiesNamesContext';
import { MarbylChartView } from '@components/containers';
import { STRINGS } from 'constants/strings';
import { PodcastOwners } from '@components/containers/AddPodcast';
import { AddPodcastModal } from '@components/containers/index';

const MPCPageWrapper = (...props) => <MPCPage {...props} />;

const DashboardRoutes = () => {
  const { permissions } = useAuth();
  const { getIsSuperUser } = useMPC();
  const hasReportedContentAccess =
    permissions[PODCAST_NETWORK_VIEW_ACTIONS?.GO_TO_CONTENT_REPORTS_PAGE] &&
    getIsSuperUser();

  const podcastStages = useMemo(() => {
    return [
      {
        breadcrumbHeaderTitle: STRINGS.PODCAST_OWNER_INFORMATION,
        component: PodcastOwners,
      },
    ];
  }, []);

  return (
    <Switch>
      <Redirect exact from={MAIN_ROUTES.APP} to={MAIN_ROUTES.MARBYL_CHART_VIEW} />
      <AddYourPodcastContextProvider>
        <Layout>
          <Route path={MAIN_ROUTES.MARBYL_CHART_VIEW}>
            <EntitiesNamesProvider>
              <MarbylChartView />
            </EntitiesNamesProvider>
          </Route>
          <Route path={MAIN_ROUTES.MPC}>
            <MPCPageWrapper />
          </Route>
          <Route path={MAIN_ROUTES.USERS}>
            <UsersRoutes />
          </Route>
          {getIsSuperUser() && (
            <>
              {hasReportedContentAccess && (
                <Route path={MAIN_ROUTES.REPORTED_CONTENT}>
                  <MPCReportsRoutes />
                </Route>
              )}
              <Route path={MAIN_ROUTES.TRANSCRIPTION_ERRORS}>
                <TranscriptionErrorsRoutes />
              </Route>
            </>
          )}
        </Layout>
        <AddPodcastModal stages={podcastStages} />
      </AddYourPodcastContextProvider>
    </Switch>
  );
};

export default DashboardRoutes;
