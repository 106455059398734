import { useMemo } from 'react';
import FiltersTable from '@components/FiltersTable/index';
import { EllipsisWrapper } from '@components/index';
import {
  getEpisodesSelectList,
  getMarbylsSelectList,
  getPodcastersByNetworkId,
  getPodcastsByPodcasterId,
  getSubnetworkByNetworkId,
  listContentReports,
  getPodcastsByNetworkId,
} from '@graphql/queries/reports';
import { format } from '@utils/dateFormat';
import { humanize } from '@utils/string';
import { REPORT } from 'constants/idPrefixes';
import { STRINGS } from 'constants/strings';

const ContentReports = () => {
  const filtersQuery = {
    main: {
      query: listContentReports,
      responseKeyName: 'contentReportsRes',
    },
    network: {
      query: getSubnetworkByNetworkId,
      responseKeyName: 'networksList',
    },
    podcaster: {
      query: getPodcastersByNetworkId,
      responseKeyName: 'podcastersList',
    },
    podcast: {
      query: getPodcastsByPodcasterId,
      secondary: getPodcastsByNetworkId,
      responseKeyName: 'response',
    },
    episode: {
      query: getEpisodesSelectList,
      responseKeyName: 'episodesList',
    },
    marbyl: {
      query: getMarbylsSelectList,
      responseKeyName: 'listMarbylsByEpisodeId',
    },
  };

  const columnDefs = useMemo(
    () => [
      {
        field: 'reportId',
        hide: true,
      },
      {
        field: 'createdAt',
        headerName: 'Date Created',
        cellRenderer: ({ value }) => (value ? format(value) : ''),
        minWidth: 100,
        maxWidth: 150,
      },
      {
        field: 'reportReason',
        headerName: 'Report Type',
        width: 200,
        cellRenderer: ({ value }) => <EllipsisWrapper value={humanize(value)} />,
      },
      {
        field: 'reportReasonDetail',
        headerName: 'Description',
        minWidth: 300,
        cellRenderer: EllipsisWrapper,
      },
      {
        field: 'marbylText',
        headerName: 'Marbyl Text',
        minWidth: 300,
        flex: 1,
        cellRenderer: EllipsisWrapper,
      },
      {
        field: 'status',
        headerName: 'Status',
        width: 100,
        cellRenderer: EllipsisWrapper,
      },
    ],
    []
  );

  return (
    <FiltersTable
      title={STRINGS.REPORTED_CONTENT}
      columnDefs={columnDefs}
      filtersQuery={filtersQuery}
      entityInfo={{
        idName: 'reportId',
        replacedString: REPORT,
        entity: STRINGS.REPORT_DETAIL,
      }}
    />
  );
};

export default ContentReports;
