import muiStyled from '@mui/styled-engine';
import { Box, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FONT_FAMILIES } from 'constants/stylesVariables';
import { DESKTOP_BREAKPOINT, MOBILE_BREAKPOINT } from 'constants/breakpoints';

export const StyledExpandedBox = muiStyled(Box)(
  ({ theme }) => `
  max-height: 0;
  overflow: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;
  background-color: ${theme.palette.main.main};
  &::-webkit-scrollbar { 
    display: none
  }
  margin-bottom: 5px;

  &.open {
    max-height: 1500px;
    overflow: auto;
    transition: max-height 0.25s ease-in;
    margin-bottom: ${theme.spacing(4)};
    padding: ${theme.spacing(3)};
  }
`
);

export const StyledExpandedContainer = muiStyled('div')(
  ({ theme }) => `
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  background-color: ${theme.palette.main.main};
  padding: 4px 10px 4px 12px;
`
);

export const StyledExpandedIcon = muiStyled(ExpandMoreIcon)(
  () => `
  margin-left: 6px;
  &.open {
    transform: rotate(180deg);
  }
`
);

export const StyledTopContainer = muiStyled(Box)(
  ({ theme }) => `
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: ${theme.spacing(3)};

    @media ${DESKTOP_BREAKPOINT} {
      align-items: center;
      flex-direction: row;
    }
  `
);

export const StyledTitleContainer = muiStyled('div')(
  ({ align = 'flex-end' }) => `
    display: flex;
    align-items: ${align};
    flex: 1;
    @media ${DESKTOP_BREAKPOINT} {
      flex-direction: row;
    }
  `
);

export const StyledTitle = muiStyled(Typography)(
  () => `
    flex: 1;
    margin-left: 0.33em;
    font-size: 30px;
    font-family: ${FONT_FAMILIES.spaceGroteskMedium}
    font-style: normal;
    font-weight: 600;

    @media ${MOBILE_BREAKPOINT} {
      margin-left: 0; 
    }
  `
);

export const StyledDescription = muiStyled(Typography)(
  ({ theme }) => `
    margin-top: ${theme.spacing(2)};

    @media ${MOBILE_BREAKPOINT} {
      margin: ${theme.spacing(1)}; 
    }
  `
);

export const StyledOptionsContainer = muiStyled('div')(
  ({ theme }) => `

  @media ${MOBILE_BREAKPOINT} {
    margin-top: ${theme.spacing(1)}; 
  }
  `
);

export const StyledTypographyButton = muiStyled(Typography)(() => ({
  fontFamily: 'space_grotesk_regular',
  fontSize: 14,
}));
