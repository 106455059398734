import React, { useState } from 'react';

import { STRINGS } from 'constants/strings';
import { useAddYourPodcast } from 'contexts/AddYourPodcastContext';

import {
  Typography,
  FormControlLabel,
  Grid,
  TextField,
  Box,
  Button,
  MenuItem,
  Checkbox,
} from '@mui/material';
import { ButtonLink, PodcastArtTitle } from '@components/index';
import { DIAL_CODES } from 'constants/countriesList';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ReactInputMask from 'react-input-mask';
import { StyledOutLinedInput, PrimaryButton } from '@shared/styledInputs';
import useDesktopMobile from 'hooks/useDesktopMobile';
import {
  TAP_NEXT_TO_CONFIRM_PODCAST_INFO,
  TAP_PRIVACY_POLICY_LINK,
  TAP_TERMS_OF_USE_LINK,
} from 'constants/analytics';
import { pageNames } from 'constants/ipsso';
import useLoadModalSubpage from 'hooks/useLoadModalSubpage';
import { record } from '@utils/analytics';
import { useMediaQuery } from 'react-responsive';
import { DESKTOP_BREAKPOINT } from 'constants/breakpoints';
import Spacer from '@shared/spacer';

const PodcastInfo = ({ renderBackBtn, onNextClick }) => {
  const {
    podcaster: { owners, companyName },
    hosts,
    podcastState: [podcast],
    addOwner,
    setOwner,
    removeOwner,
    setCompanyName,
    toggleHost,
  } = useAddYourPodcast();
  const isDesktopOrLaptop = useMediaQuery({
    query: DESKTOP_BREAKPOINT,
  });
  const { renderMobileComponent, renderDesktopComponent } = useDesktopMobile();

  const [terms, setTerms] = useState(false);

  const handleAddOwner = () => addOwner();

  const handleRemoveOwner = (index) => () => removeOwner(index);

  const handleSubmit = (evt) => {
    evt.preventDefault();
    onNextClick(TAP_NEXT_TO_CONFIRM_PODCAST_INFO);
  };

  const handleOwner = (index, field) => (evt) => {
    const {
      target: { value },
    } = evt;

    setOwner(index, { field, value });
  };

  const handleHost = (id) => (evt) => {
    const {
      target: { checked },
    } = evt;

    toggleHost(id, checked);
  };

  useLoadModalSubpage(pageNames.PODCAST_INFO);

  return (
    <>
      <PodcastArtTitle podcast={podcast} />
      <Typography sx={{ mt: 2 }} variant="h6">
        {STRINGS.ADD_SOME_DETAILS_ABOUT_YOUR_PODCAST}
      </Typography>

      <form id="owner-form" onSubmit={handleSubmit}>
        <Box
          sx={{
            pl: 1,
            pr: 1,
          }}
        >
          <Grid sx={{ mt: 2 }} alignItems="center" container rowSpacing={2}>
            {renderDesktopComponent(() => (
              <Grid container item>
                <Grid item md={3}>
                  <Typography variant="subtitle1">{STRINGS.OWNERS_NAME}</Typography>
                </Grid>
                <Grid item md={2}>
                  <Typography
                    sx={{ textAlign: 'center', whiteSpace: 'pre-wrap' }}
                    variant="subtitle1"
                  >
                    {STRINGS.IS_HOST}
                  </Typography>
                </Grid>
                <Grid item md={3}>
                  <Typography variant="subtitle1">{STRINGS.OWNERS_EMAIL}</Typography>
                </Grid>
                <Grid item md={3}>
                  <Typography variant="subtitle1">{STRINGS.OWNERS_MOBILE}</Typography>
                </Grid>

                <Grid item md={1}></Grid>
              </Grid>
            ))}

            {owners.map((_owner, index) => (
              <Grid container item key={_owner.id} columnSpacing={1} rowSpacing={2}>
                <Grid item md={3} xs={12}>
                  <StyledOutLinedInput
                    value={_owner.name}
                    required
                    fullWidth
                    onChange={handleOwner(index, 'name')}
                    focused
                    sx={{ display: 'flex' }}
                  />
                </Grid>
                <Grid
                  sx={{ ...(isDesktopOrLaptop && { textAlign: 'center' }) }}
                  item
                  md={2}
                  xs={12}
                >
                  {renderMobileComponent(() => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primaryButton"
                          checked={hosts[_owner.id] || false}
                          onChange={handleHost(_owner.id)}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      }
                      label={STRINGS.HOST_CO_HOST_PODCAST}
                    />
                  ))}

                  {renderDesktopComponent(() => (
                    <Checkbox
                      color="primaryButton"
                      checked={hosts[_owner.id] || false}
                      onChange={handleHost(_owner.id)}
                      inputProps={{ 'aria-label': 'controlled' }}
                      style={{
                        transform: 'scale(1.1)',
                      }}
                    />
                  ))}
                </Grid>
                <Grid item md={3} xs={12}>
                  <StyledOutLinedInput
                    value={_owner.email}
                    required
                    onChange={handleOwner(index, 'email')}
                    focused
                  />
                </Grid>
                <Grid item md={3} xs>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <TextField
                      color="secondary"
                      value={_owner.phoneCode}
                      id="outlined-select-code"
                      select
                      sx={{
                        width: 115,
                        '& .MuiOutlinedInput-root': {
                          height: 45,
                          borderTopRightRadius: 0,
                          borderBottomRightRadius: 0,
                          '& fieldset': {
                            borderColor: '#B5B5B5',
                          },
                          '&:hover fieldset': {
                            borderColor: '#B5B5B5',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#B5B5B5',
                          },
                        },
                        left: 2,
                        '& legend': {
                          display: 'none',
                        },
                        '& fieldset': {
                          top: 0,
                        },
                      }}
                      onChange={handleOwner(index, 'phoneCode')}
                      focused
                    >
                      {DIAL_CODES.map((country) => (
                        <MenuItem key={country.key} value={country.label}>
                          {country.label}
                        </MenuItem>
                      ))}
                    </TextField>
                    <ReactInputMask
                      value={_owner.phoneNumber}
                      onChange={handleOwner(index, 'phoneNumber')}
                      mask="(999) 999-9999"
                    >
                      {(inputProps) => (
                        <StyledOutLinedInput
                          {...inputProps}
                          fullWidth
                          color="secondary"
                          focused
                          sx={{
                            '& .MuiOutlinedInput-notchedOutline': {
                              borderTopLeftRadius: 0,
                              borderBottomLeftRadius: 0,
                            },
                            '& .MuiOutlinedInput-input': {
                              borderTopLeftRadius: 0,
                              borderBottomLeftRadius: 0,
                            },
                          }}
                        />
                      )}
                    </ReactInputMask>
                  </Box>
                </Grid>

                {index !== 0 && (
                  <Grid
                    item
                    md={1}
                    xs={2}
                    sx={{
                      justifyContent: 'center',
                      alignItems: 'center',
                      display: 'flex',
                    }}
                  >
                    <DeleteOutlineIcon
                      fontSize={'large'}
                      role="button"
                      onClick={handleRemoveOwner(index)}
                    />
                  </Grid>
                )}

                {renderMobileComponent(() => (
                  <Grid item xs={12}>
                    <Spacer size={10} />
                  </Grid>
                ))}
              </Grid>
            ))}

            <Grid item md={6}>
              <Button
                color="primaryButton"
                onClick={handleAddOwner}
                sx={{ textTransform: 'none' }}
              >
                {STRINGS.ADD_ANOTHER_OWNER}
              </Button>
            </Grid>

            {renderDesktopComponent(() => (
              <Grid sx={{ mt: 2 }} item md={12}>
                <Typography variant="subtitle1">
                  {`${STRINGS.OWNER_COMPANY_NAME} (${STRINGS.IF_APPLICABLE})`}
                </Typography>
              </Grid>
            ))}

            <Grid item md={3} xs={12}>
              <StyledOutLinedInput
                value={companyName}
                onChange={(evt) => setCompanyName(evt.target.value)}
                fullWidth
                focused
              />
            </Grid>
          </Grid>

          <FormControlLabel
            sx={{ mt: 5 }}
            value={terms}
            onChange={(evt) => setTerms(evt.target.checked)}
            control={<Checkbox required color="primaryButton" />}
            label={
              <span>
                I acknowledge the Tree Goat Media{' '}
                <ButtonLink
                  onClick={() => {
                    record(TAP_PRIVACY_POLICY_LINK);
                    window.open('/policy', '_blank');
                  }}
                >
                  Privacy Policy
                </ButtonLink>{' '}
                and agree to{' '}
                <ButtonLink
                  onClick={() => {
                    record(TAP_TERMS_OF_USE_LINK);
                    window.open('/terms', '_blank');
                  }}
                >
                  Terms of Service
                </ButtonLink>
                .
              </span>
            }
          />
        </Box>

        <Box sx={{ mt: 2 }}>
          {renderBackBtn()}

          <PrimaryButton
            color="primaryButton"
            disabled={!terms}
            type="submit"
            variant="contained"
            sx={{
              width: 184,
            }}
          >
            {STRINGS.NEXT}
          </PrimaryButton>
        </Box>
      </form>
    </>
  );
};

export default PodcastInfo;
