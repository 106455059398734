import { Box } from '@mui/material';
import styled from '@mui/styled-engine';
import IconButton from '@mui/material/IconButton';

export const StyledContainer = styled(Box)(
  () => `
    display: flex;
`
);

export const StylecIconContainer = styled(IconButton)(
  ({ theme }) => `
    height: ${theme.spacing(4)};
    aspect-ratio: 1;
    border-radius: 2px;
    border: 1px solid #000;
    margin-left: ${theme.spacing(1.5)};
`
);
