export const getPodcastById = /* GraphQL */ `
  query getPodcastById($podcastId: String!) {
    getPodcastById: MPCGetShowByIdV2(showId: $podcastId) {
      PK
      title
      updatedAt
      summary
      subtitle
      subgenre
      rssFeedLink
      preferredSubscribeLinkType
      preferredSubscribeLink
      podcastType
      m11nIsEnabledUpdated
      podcastMarbylizationIsEnabled
      m11nAutoType
      lastIngestionResult
      lastIngestionEpisodes
      lastIngestionDate
      language
      itunesId
      isActivatedUpdated
      isActivated
      infoLink
      imageUrl
      host
      genre
      following
      followersCount
      feedIngestionState
      explicit
      email
      description
      createdAt
      copyright
      complete
      color
      cloudRssFeedLink
      cloudInfoLink
      cloudImageUrl
      categories
      availableInGeos
      author {
        name
        email
      }
      acknowledgment
      owner {
        email
        name
      }
      categoriesWithSubs {
        name
        subs {
          name
        }
      }
      showArt
      showArtLarge
      showArtMedium
      showArtSmall
    }
  }
`;

export const listEpisodesForPodcastTable = /* GraphQL */ `
  query listEpisodesByPodcastId($podcastId: String!, $limit: Int, $nextToken: String) {
    listEpisodesByPodcastId: MPCListEpisodesByShowIdV2(
      showId: $podcastId
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        PK
        title
        pubDate
        isActivated
        duration
        marbylCount
        m11nProcessingState
        episodeMarbylizationIsEnabled
        lastM11nDate
        lastM11nResult
        lastM11nMarbylsCreated
        isDeleted: deleted
      }
      nextToken
      totalResults
    }
  }
`;
